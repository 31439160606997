
/*
Application entry point.
*/

import _ from 'lodash';

import * as Redux from 'redux';
import thunk from 'redux-thunk';

import * as React from 'react';
import * as ReactRedux from 'react-redux';
import * as Router from 'react-router';
import ServiceProvider from '../src/util/di/ServiceProvider.js';

import * as AppModule from '../src/modules/App.js';
import services from './config/services.js';
import createState from './state.js';


// Application

const update = AppModule.update;

const view = (store, history) =>
    // Wrap the app in the necessary providers
    <ServiceProvider services={services}>
        <ReactRedux.Provider store={store}>
            <Router.Router history={history}>
                <AppModule.App/>
            </Router.Router>
        </ReactRedux.Provider>
    </ServiceProvider>
    ;


// Agent

// Redux middleware
const logger = store => next => action => {
    // console.info('[info] Action:',  JSON.stringify(action));
    
    // Format the action a bit, to make it more readable
    const formattedAction = _.cloneDeep(action);
    if (action.hasOwnProperty('path') && Array.isArray(action.path)) {
        formattedAction.path = action.path.join('/');
    }
    
    console.info('[info] Action:', action.type, formattedAction);
    
    const result = next(action);
    
    // console.info('[info] New state:', JSON.stringify(store.getState(), null, 4));
    
    return result;
};

const middleware = [];
if (process.env.NODE_ENV === 'development') {
    // Note: put `logger` after `thunk`, so that we don't log thunks (functions) before they are
    // processed by the thunk middleware.
    middleware.push(thunk, logger);
} else {
    middleware.push(thunk);
}

// Redux store

// Use the Redux DevTools version of `compose`, if available
const composeEnhancers = typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
    : Redux.compose;

const store = (initialState = createState()) => {
    return Redux.createStore(update, initialState, composeEnhancers(Redux.applyMiddleware(...middleware)));
};


// Export Helmet, so that can extract document metainfo from it (e.g. for SSR)
export { Helmet } from 'react-helmet';

export { store, view };
