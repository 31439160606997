
import Schema from '../util/models/schema.js';
import Entity from '../util/models/Entity.js';
import Collection from '../util/models/Collection.js';
import Event from './Event.js';
import Category from './Category';
import Image from './Image';

export default class Menu extends Entity {
    static key = { id: Schema.text };
    static schema = {
        id: Schema.integer,
        name: Schema.text,
        description: Schema.text,
        slug: Schema.text,
        disabled: Schema.text,
        link: Schema.text,
        events: new Collection(Event),
        children: new Collection(Menu),
        images: new Collection(Image),
    };
    
    constructor(...args) {
        super(Menu.schema, ...args);
    }
}
