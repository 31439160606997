
import _ from 'lodash';


const routes = {
    app: {
        events: {
            view: eventId => `/events/${eventId}`,
        },
    },
};

export default (routeSpec, ...args) => {
    let route = routeSpec;
    
    if (_.isArray(routeSpec)) {
        route = routeSpec;
    }
    if (_.isString(routeSpec)) {
        route = routeSpec.split('/');
    } else {
        throw new Error(`Unrecognized route format '${routeSpec}'`);
    }
    
    if (!_.has(routes, route)) {
        throw new Error(`No such route '${route.join('/')}'`);
    }
    
    const uri = _.get(routes, route);
    
    if (_.isFunction(uri)) {
        return uri(...args);
    } else {
        return uri;
    }
};
