
import api from '../CpgApi';
import SweetAlert from 'react-bootstrap-sweetalert';
import * as React from 'react';
import { Link } from 'react-router-dom';


export const toggleFavorite = ({ auth, dispatch, myList, event }) => {
    if (!auth) {
        dispatch({
            type: 'alert',
            alert: dispatch =>
                <SweetAlert
                    warning
                    title="Please login"
                    onConfirm={() => { dispatch({ type: 'alert', alert: undefined }); }}
                >
                    You have to be <Link to="/login">logged in</Link> to add an
                    event to your list. <Link to="/register">Registration</Link> is free!
                </SweetAlert>,
        });
        
        return Promise.resolve();
    }
    
    const alreadyAdded = myList.has(event.slug);
    
    const togglePromise = !alreadyAdded
        ? dispatch(api.eventFavorites.create(event))
        : dispatch(api.eventFavorites.delete(event.slug));
    
    togglePromise
        .then(request => {
            if (alreadyAdded) {
                // Do not show an alert if we're removing from the list
                return Promise.resolve();
            }
            
            return dispatch({
                type: 'alert',
                alert: dispatch => {
                    setTimeout(() => {
                        dispatch({ type: 'alert', alert: undefined });
                    }, 4000);
                    
                    return (
                        <SweetAlert success title="Great"
                            onConfirm={() => dispatch({ type: 'alert', alert: undefined })}
                        >
                            {event.name} was {!alreadyAdded ? 'added to' : 'removed from'} your list
                        </SweetAlert>
                    );
                },
            });
        })
        .catch(() => {
            return dispatch({
                type: 'alert',
                alert: dispatch => {
                    setTimeout(() => {
                        dispatch({ type: 'alert', alert: undefined });
                    }, 4000);
                    
                    return (
                        <SweetAlert warning title="Oops"
                            onConfirm={() => dispatch({ type: 'alert', alert: undefined })}
                        >
                            Something went wrong while updating your list
                        </SweetAlert>
                    );
                },
            });
        });    
};
