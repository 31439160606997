
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';


const createServiceProvider = services => class ServiceProvider extends React.Component {
    static propTypes = {
        // Require children to be a single element (prevents the need for an extra wrapper element)
        children: PropTypes.element.isRequired,
    };
    
    static childContextTypes = _.mapValues(services, service => PropTypes.any);
    
    getChildContext() {
        return services;
    }
    
    render() {
        return this.props.children;
    }
};

const ServiceProviderContainer = ({ services, children }) => {
    const ServiceProvider = createServiceProvider(services);
    return <ServiceProvider>{children}</ServiceProvider>;
};

ServiceProviderContainer.propTypes = {
    services: PropTypes.object.isRequired,
    children: PropTypes.node.isRequired,
};

export default ServiceProviderContainer;
