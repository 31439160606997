
import * as React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import inject from '../../util/di/inject.js';


@inject({
    sites: 'cpg.sites',
})
export default class AboutFriesland extends React.PureComponent {
    render () {
        return (
            <React.Fragment>
                <Helmet>
                    <body className="content-page page-about bg-image"/>
                    <title>About Friesland</title>
                    <meta name="description" content="About Friesland"/>
                </Helmet>
                
                <div className="container content-wrapper">
                    <div className="row">
                        <div className="well well-white col-xs-10 col-xs-offset-1 col-md-8 col-md-offset-2">
                            <div className="row">
                                <div className="col-sm-10 col-sm-offset-1">
                                        <h1>About Friesland</h1>
                                        <p>TODO</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
