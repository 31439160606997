
import * as React from 'react';

export default props =>
    <div className="container content-wrapper">
        <div className="row">
            <div className="col-md-4 col-md-offset-4 col-lg-8 col-lg-offset-2 margin40T">
                {props.children}
            </div>
        </div>
    </div>
    ;
