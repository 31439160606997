
import _ from 'lodash';
import Imm from 'immutable';

import Schema from '../util/models/schema.js';
import Entity from '../util/models/Entity.js';


export default class Image extends Entity {
    static key = { id: Schema.text };
    static schema = {
        name: Schema.text,
        path: Schema.text,
    };
    
    constructor(...args) {
        super(Image.schema, ...args);
    }
};
