import SweetAlert from 'react-bootstrap-sweetalert';
import * as React from 'react';
import {Link} from 'react-router-dom';

export const notAuthenticated = ({ dispatch, event, auth, history }) => {
    return dispatch({
        type: 'alert',
        alert: (dispatch) => {
            return (
                <SweetAlert
                    warning
                    title="Please login"
                    onConfirm={() => history.goBack()}>
                    You have to be <Link to={`/login`}>logged in</Link> to use this feature.
                    <Link to={`/register`}>Registration</Link> is free!
                </SweetAlert>
            )
        }
    })
}