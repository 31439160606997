
import * as _ from 'lodash';
import moment from 'moment';
import { formatDateTimeRange } from '../../util/formatting.js';

import React from 'react';

import inject from '../../util/di/inject.js';
import { convertPath } from '../../services/model/ImageService.js';
import * as ReactRedux from 'react-redux';
import Slider from 'react-slick';
import { getFallbackImage } from '../../services/model/ImageService.js';
import * as Router from 'react-router';
import * as FavoriteService from '../../services/model/FavoriteService.js';

import EventSchema from './EventSchema.js';


const sliderConfig = {
    autoplay: true,
    autoplaySpeed: 5000,
    dots: false,
    arrows: false,
    draggable: false,
    fade: true,
    speed: 1000,
};

@inject({
    lang: 'app.lang',
})
@Router.withRouter
@ReactRedux.connect(
    state => ({
        myList: state.app.authUser.eventFavorites,
        auth: state.getIn(['session', 'auth']),
    }),
    dispatch => ({
        dispatch,
    }),
    (stateProps, dispatchProps, otherProps) => ({
        ...stateProps,
        ...dispatchProps,
        ...otherProps,
        toggleFavorite: event => {
            return FavoriteService.toggleFavorite({
                auth: stateProps.auth,
                dispatch: dispatchProps.dispatch,
                myList: stateProps.myList,
                event,
            });
        },
    }),
)
export default class EventFeatured extends React.PureComponent {
    goto = (location) =>
        window.open(location, '_blank');
    
    render() {
        const { lang, event, myList, history, auth, dispatch, update } = this.props;
        // const images = _.times(3).map((time) => `https://lorempixel.com/400/200/city/?${time}`);
        const images = event.images.size !== 0
            ? event.images.map((image, imageId) => (
                <div key={imageId}
                    className="background-image-item lazyload"
                    data-bgset={
                        `${convertPath(image.get('path'))}&w=600&h=270&fit=crop-center [(max-width: 600px)] | ${convertPath(image.get('path'))}&w=768&h=416&fit=crop-center [(max-width: 992px)] | ${convertPath(image.get('path'))}&w=1220&h=536&fit=crop-center` 
                        }
                />
            )).toArray()
            : [
                <div className="background-image-item"
                   style={{ backgroundImage: `url(${getFallbackImage()}&w=1220&h=536&fit=crop&fit=crop-center)` }}
                />
            ];
        
        return (
            <header className="container-fluid jumbotron jumbotron-homepage">
                <div className="row vertical-align-sm">
                    <div className="jumbotron-homepage-slider-wrapper">
                        {images && images.length > 0 &&
                            <Slider {...sliderConfig} className="background-image-slider col-sm-offset-0 col-md-offset-2 col-lg-offset-4 col-xl-offset-4">
                                {images}
                            </Slider>
                        }
                    </div>
                    <div className="col-xs-12 col-sm-8 col-lg-7 col-xl-6 jumbotron-homepage-content-wrapper vertical-align-xs">
                        <div className="jumbotron-homepage-content">
                            <h1>
                                {event.get('name')}
                                <small>
                                    {formatDateTimeRange(
                                        moment(event.get('start_date')),
                                        event.get('end_date') && moment(event.get('end_date'))
                                    )}, {event.get('location').name} { event.get('location').city }
                                </small>
                            </h1>
                            <p className="lead hidden-xs hidden-ms hidden-sm">{event.get('summary')}</p>
                            
                            <div className="margin30T btn-group hidden-xs" role="group" aria-label="Action buttons">
                                <a
                                    onClick={evt => {
                                        this.props.toggleFavorite(event);
                                    }}
                                    className="btn btn-primary btn-lg"
                                    role="button"
                                >
                                    {myList.has(event.slug)
                                        ?
                                            <span>
                                                <span className="icon icon-minus" aria-hidden="true"
                                                    aria-label="Remove from list icon"
                                                />
                                                {' '}
                                                Remove from My List
                                            </span>
                                        :
                                            <span>
                                                <span className="icon icon-plus" aria-hidden="true"
                                                    aria-label="Add to list icon"
                                                />
                                                {' '}
                                                Add to My List
                                            </span>
                                    }
                                </a>
                                
                                <a onClick={() => history.push(`/event/${event.get('slug')}`)} className="btn btn-default btn-lg" role="button">
                                    <span className="icon icon-info" aria-hidden="true" aria-label="Add to list icon"/>
                                    More details
                                </a>
                            </div>
                            
                            <div className="margin10T visible-xs" role="group" aria-label="Action buttons">
                                <a
                                    role="button"
                                    className="btn btn-primary btn-block btn-lg"
                                    onClick={evt => {
                                        this.props.toggleFavorite(event);
                                    }}
                                >
                                    {myList.has(event.slug)
                                        ?
                                            <span>
                                                <span className="icon icon-minus" aria-hidden="true"
                                                    aria-label="Remove from list icon"
                                                />
                                                {' '}
                                                Remove from My List
                                            </span>
                                        :
                                            <span>
                                                <span className="icon icon-plus" aria-hidden="true"
                                                    aria-label="Add to list icon"
                                                />
                                                {' '}
                                                Add to My List
                                            </span>
                                    }
                                </a>
                                
                                <a onClick={() => history.push(`/event/${event.get('slug')}`)} className="btn btn-default btn-block btn-lg margin20T" role="button">
                                    <span className="icon icon-info" aria-hidden="true" aria-label="Add to list icon"/>
                                    More details
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                
                <EventSchema event={event}/>
            </header>
        );
    }
}
