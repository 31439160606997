
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import SitesService from '../../services/SitesService.js';

export default class NavigationSimple extends React.PureComponent {
    logos = {};
    
    constructor(props) {
        super(props);
        
        this.logos = {
            groningen: require('../../style/img/svg/logo-here-now-groningen.svg'),
            friesland: require('../../style/img/svg/logo-here-now-friesland.svg'),
            drenthe: require('../../style/img/svg/logo-here-now-drenthe.svg'),
        };
    }
    
    render() {
        const site = new SitesService().getSite(window.location);
        
        return (
            <nav className="navbar navbar-default navbar-static-top">
                <div className="container-fluid">
                    <div className="navbar-header">
                        <Link to="/" rel="index" className="navbar-brand">
                            {this.logos.hasOwnProperty(site.name) &&
                                <img className="svg img-responsive" src={this.logos[site.name]}/>
                            }
                        </Link>
                    </div>
                </div>
            </nav>
        );
    }
}
