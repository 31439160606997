
import * as React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import inject from '../../util/di/inject.js';


@inject({
    sites: 'cpg.sites',
})
export default class About extends React.PureComponent {
    render () {
        return (
            <React.Fragment>
                <Helmet>
                  <body className="content-page page-about bg-image"/>
                  <title>About</title>
                  <meta name="description" content="About Here & Now"/>
                </Helmet>
                
                <div className="container content-wrapper">
                    <div className="row">
                        <div className="well well-white col-xs-10 col-xs-offset-1 col-md-8 col-md-offset-2">
                            <div className="row">
                                <div className="col-sm-10 col-sm-offset-1">
                                        <h1>About Here &amp; Now</h1>
                                        
                                        <h2>What is Here & Now?</h2>
                                        <p>
                                            Here & Now in the North is an agenda where you can find ALL cultural and social events in the three Northern locations: Groningen, Leeuwarden-Friesland and Drenthe! In <em>English</em>! 
                                        </p>
                                        <p>
                                            First developed and launched in the city of Groningen, Here & Now has now been adapted to the whole of the Northern Netherlands. Connecting its locals, internationals and tourists to the diverse cultural life of the North!
                                        </p>
                                        
                                        <h2>What kind of events is Here & Now showing?</h2>
                                        <p>
                                            ALL! From music, movies, exhibitions, art, performance, theatre to cozy hobby meetups, vintage sales and informal gatherings.
                                        </p>
                                        
                                        <h2>How does it work?</h2>
                                        <p>
                                            First, pick your location: <a title="Here & Now in Groningen" onClick={() => { this.props.sites.switch({ name: 'groningen' }); }}>Groningen</a>,  <a title="Here & Now in Drenthe" onClick={() => { this.props.sites.switch({ name: 'drenthe' }); }}>Drenthe</a> or <a title="Here & Now in Leeuwarden-Friesland" onClick={() => { this.props.sites.switch({ name: 'friesland' }); }}>Leeuwarden-Friesland</a>
                                        </p>
                                        <p>
                                            Then you have 2 options: 
                                        </p>
                                        <ol>
                                            <li>
                                                <Link to="/register">Register</Link> (<em>it’s free</em>), tick off genres you like and get personalized recommendations! It’s especially handy if you’re new here (in Groningen, Leeuwarden-Friesland or Drenthe) and want to get to know the local venues & events better.
                                            </li>
                                            <li>
                                                Or simply go to <a href="https://hereandnow.nl">www.hereandnow.nl</a> and <em>enjoy</em>! However there are no personalized recommendations waiting for you here...
                                            </li>
                                        </ol>
                                        
                                        <h2>For whom?</h2>
                                        <p>
                                            FOR YOU! It doesn’t matter if you’re Dutch or international, just visiting or moving here. Alternative, hipster, urban traveler, student, young adult or father of five... We’ve got tips for all of you!
                                        </p>
                                        
                                        <h2>Who made this happen?</h2>
                                        <p>
                                            <strong>Groningen:</strong><br/>
                                            University of Groningen, Hanze University of Applied Sciences, UMCG, Provincie Groningen, Gemeente Groningen, City of Talent, cultural organizations and all the internationals in the city!
                                        </p>
                                        <p>
                                            <strong>Leeuwarden-Friesland:</strong><br/>
                                            Gemeente Leeuwarden, Leeuwarden Studiestad, Top Dutch Talent, NHL Stenden University of Applied Sciences, IWCN, University of Groningen - Campus Fryslan.
                                        </p>
                                        <p>
                                            <strong>Drenthe:</strong><br/>
                                            Top Dutch Talent, NHL Stenden University of Applied Sciences, Make it in Emmen, IWCN, Emmen. Maak het mee!
                                        </p>
                                        
                                        <h2>Can I submit my event?</h2>
                                        <p>
                                            YES! Small, medium, huge. All events are welcome, just use the form on <Link to="/submitevent">Submit Event</Link> or send a mail to <a href="mailto:info@hereandnow.nl">info@hereandnow.nl</a>
                                        </p>
                                        
                                        <h2>Honorable mentions</h2>
                                        <p>
                                            The project is also part of the International Groningen program which was recently awarded a <a href="http://urbact.eu/" rel="noreferer noopener">Good Practice City Label by URBACT</a>; the European fund for integrated urban development.
                                        </p>
                                        
                                        <h2>Contact</h2>
                                        <p>
                                            <strong>Email:</strong><br/>
                                        <a href="mailto:info@hereandnow.nl">info@hereandnow.nl</a>
                                            <br/>                                                 
                                            <strong>Facebook:</strong><br/>
                                            <span className="icon icon-social-facebook" aria-hidden="true" aria-label="Facebook icon"/>
                                            <a href="https://www.facebook.com/hereandnowingroningen/">
                                                Groningen
                                            </a>
                                            <br/>
                                            <span className="icon icon-social-facebook" aria-hidden="true" aria-label="Facebook icon"/>
                                            <a href="https://www.facebook.com/hereandnowinleeuwardenfriesland">
                                                    Leeuwarden-Friesland
                                            </a>
                                            <br/>
                                            <span className="icon icon-social-facebook" aria-hidden="true" aria-label="Facebook icon"/>
                                            <a href="https://www.facebook.com/hereandnowindrenthe">
                                                    Drenthe
                                            </a>
                                        </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
