
import moment from 'moment';
import { formatStartDate } from '../../util/formatting.js';

import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import HoverIntent from 'react-hoverintent';
import { Link } from 'react-router-dom';
import * as ReactRedux from 'react-redux';

import { convertPath } from '../../services/model/ImageService.js';
import { getFallbackImage } from '../../services/model/ImageService.js';
import api from '../../services/CpgApi.js';
import * as FavoriteService from '../../services/model/FavoriteService.js';
import * as _ from 'lodash';
import inject from '../../util/di/inject';
import SweetAlert from 'react-bootstrap-sweetalert';

import Collection from '../../util/models/Collection.js';
import Event from '../../models/Event.js';
import EventGroup from '../../models/EventGroup.js';


@ReactRedux.connect(
    state => ({
        myList: state.app.authUser.eventFavorites,
        auth: state.getIn(['session', 'auth']),
    }),
    dispatch => ({
        dispatch,
    }),
    (stateProps, dispatchProps, otherProps) => ({
        ...stateProps,
        ...dispatchProps,
        ...otherProps,
        toggleFavorite: event => {
            return FavoriteService.toggleFavorite({
                auth: stateProps.auth,
                dispatch: dispatchProps.dispatch,
                myList: stateProps.myList,
                event,
            });
        },
    }),
)
export default class EventsSliderElement extends React.Component {
    static propTypes = {
        className: PropTypes.string,
        event: PropTypes.instanceOf(Event).isRequired,
        auth: PropTypes.object,
        update: PropTypes.func.isRequired,
        onHover: PropTypes.func.isRequired,
        myList: PropTypes.instanceOf(Collection),
        toggleFavorite: PropTypes.func.isRequired,
    };
    
    static defaultProps = {
        className: '',
        auth: undefined,
    };
    
    mouseAction = hover => {
        this.props.onHover(hover);
    };
    
    render() {
        const { event, className, auth, update, myList } = this.props;
        
        // if (myList.meta.status !== 'ready') {
        //     return null;
        // }
        
        const image = event.images.size > 0
            ?
                <img className="item-img lazyload"
                    data-src={convertPath(event.images.first().get('path')) + '&w=350&h=190&fit=crop'}
                    src={getFallbackImage()}
                />
            : <img className='item-img' src={getFallbackImage()}/>;
        
        return (
            <HoverIntent
                onMouseOver={() => { this.mouseAction(true); }}
                onMouseOut={() => { this.mouseAction(false); }}
                sensitivity={3}
                interval={200}
                timeout={0}
            >
                <div>
                    <div className="item-preview" tabIndex="-1">
                        <Link className="item-img-wrapper"
                            to={`/event/${event.slug}`} // Provide URL fallback (e.g. for SEO)
                            onClick={evt => {
                                evt.preventDefault();
                            }}
                        >
                            {image}
                        </Link>
                        <div className="item-preview-content-wrapper">
                            <h3 className="item-title">
                                <span>
                                  {_.truncate(event.name, {
                                      'length': 55
                                  })}
                                </span>
                            </h3>
                            
                            <div className="item-date label label-primary">
                                {formatStartDate(moment(event.start_date), moment(event.end_date), event.recurring_label)}
                            </div>
                        </div>
                    </div>
                    <div className="item-details">
                        {event.location &&
                            <div className="item-location">
                                <strong>Location: <a tabIndex={0}>
                                  {_.truncate(event.get('location').name, {
                                      'length': 40
                                  })}
                                </a>
                                </strong>
                            </div>
                        }
                        <div className="item-description">
                            {_.truncate(event.summary, { length: 110 })}
                        </div>
                        <div className="item-actions">
                            <a className="btn btn-default btn-v1"
                                role="button"
                                tabIndex={0}
                                onClick={evt => {
                                    evt.stopPropagation();
                                    this.props.toggleFavorite(event);
                                }}
                            >
                                {myList && myList.has(event.slug)
                                    ?
                                        <span>
                                            <span className="icon icon-minus" aria-hidden="true"
                                                aria-label="Remove from my list"
                                            />
                                            {' '}
                                            My List
                                        </span>
                                    :
                                        <span>
                                            <span className="icon icon-plus" aria-hidden="true"
                                                aria-label="Add to my list"
                                            />
                                            {' '}
                                            My List
                                        </span>
                                }
                            </a>
                        </div>
                    </div>
                </div>
            </HoverIntent>
        );
    }
}
