
import _ from 'lodash';
import uri from '../../util/tag_uri.js';
import moment from 'moment';

import React from 'react';
import PropTypes from 'prop-types';
import * as ReactRedux from 'react-redux';
import * as ReactRouter from 'react-router';
import { Helmet } from 'react-helmet';
import inject from '../../util/di/inject.js';

import EventsSlider from './EventsSlider.js';


@ReactRouter.withRouter
@inject({
    responsive: 'cpg.responsive',
})
export default class EventsSliderList extends React.PureComponent {
    static propTypes = {
        onActivate: PropTypes.func.isRequired,
        enableDropdown: PropTypes.bool,
    };
    
    static defaultProps = {
        enableDropdown: true,
    };
    
    state = {
        hoverEventId: null,
        hoverSliderId: null,
        
        // Note: we separate the `active` flag from the active event reference itself. This is because there
        // is a transition period where `active` is false, but the animation should still show the event
        // while the slide-down is closing.
        active: false, // Whether there is currently an event active
        activeSliderId: null, // The slider containing the active event
        activeEventId: null, // The last active event
    };
    
    componentDidUpdate(prevProps, prevState) {
        this.props.onActivate(this.state.active);
        
        if (this.props.enableScroll) {
            // Whether an event has just been activated
            const hasActivated = this.state.active
                && (!prevState.active || this.state.activeSliderId !== prevState.activeSliderId);
            
            if (this.props.responsive && hasActivated) {
                setTimeout(() => {
                    const el = document.querySelector('.slider-item-detail-container.open').previousElementSibling;
                    if (el.scrollIntoView) {
                        el.scrollIntoView({ block: 'start', inline: 'nearest', behavior: 'smooth' });
                    }
                }, 500);
            }
        }
    }
    
    dropdownEnabled() {
        const { responsive, enableDropdown } = this.props;
        
        return enableDropdown && !responsive.isTouchEnabled();
    }
    
    render() {
        const { history } = this.props;
        
        const dropdownEnabled = this.dropdownEnabled();
        
        const elements = _.flatten(
            Array.isArray(this.props.children) ? this.props.children : [this.props.children]
        );
        return (
            <div>
                {elements.map((element, index) => {
                    if (React.isValidElement(element)) {
                        return React.cloneElement(element, {
                            ...element.props,
                            key: element.key,
                            hoverEventId: this.state.hoverSliderId === element.key
                                ? this.state.hoverEventId
                                : null,
                            active: this.state.activeSliderId === element.key
                                ? this.state.active
                                : false,
                            activeEventId: this.state.activeSliderId === element.key
                                ? this.state.activeEventId
                                : null,
                            onHover: ({ event }) => {
                                if (!dropdownEnabled) {
                                    // If we are on a touch device, disable hover
                                    return;
                                }
                                
                                if (event) {
                                    this.setState({
                                        hoverSliderId: element.key,
                                        hoverEventId: String(event.id),
                                    });
                                } else {
                                    this.setState({ hoverEventId: null });
                                }
                            },
                            onActivate: ({ event }) => {
                                if (event) {
                                    if (!dropdownEnabled) {
                                        // If we are on a touch device, go the full event page
                                        history.push(uri`/event/${event.slug}`);
                                        return;
                                    }
                                    
                                    this.setState(prevState => {
                                        const active = !(
                                            prevState.active
                                            && prevState.activeSliderId === element.key
                                            && prevState.activeEventId === String(event.id)
                                        );
                                        
                                        return {
                                            active,
                                            activeSliderId: element.key,
                                            activeEventId: String(event.id),
                                        };
                                    });
                                } else {
                                    // Note: only set the active flag to false, leave the rest for animation purposes
                                    this.setState({ active: false });
                                }
                            },
                        });
                    } else {
                        return element;
                    }
                })}
            </div>
        );
    }
}
