
import { matchType as match } from '@mkrause/match';

export default (state, action) => match(action, {
    [match.default]: state,
    
    'alert': ({alert}) => {
        return (alert !== undefined)
            ? state.setIn(['components', 'alert'], (dispatch) => alert(dispatch))
            : state.setIn(['components', 'alert'], undefined);
    }
});
