
import Collection from '../../util/models/Collection.js';

import PropTypes from 'prop-types';
import React from 'react';

import Event from '../../models/Event.js';

import { convertPath, getFallbackImage } from '../../services/model/ImageService.js';


// Schema.org metadata for an event
// See:
//   - http://schema.org/Event
//   - https://developers.google.com/search/docs/data-types/event
//   - https://search.google.com/structured-data/testing-tool
export default class EventSchema extends React.PureComponent {
    static propTypes = {
        event: PropTypes.instanceOf(Event).isRequired,
    };
    
    render() {
        const { event } = this.props;
        
        // Security note:
        // - Make sure all user inputs are JSON encoded (JSON.stringify)
        // - HTML escaping not necessary, handled by React (as long as we don't use dangerouslySetInnerHTML)
        
        const images = event.images instanceof Collection ? event.images.toArray() : event.images;
        
        return (
            <script type="application/ld+json">{`
                {
                    "@context": "http://schema.org",
                    "@type": "Event",
                    "description": ${JSON.stringify(event.description)},
                    "startDate": ${JSON.stringify(event.get('start_date'))},
                    "endDate": ${JSON.stringify(event.get('end_date'))},
                    "image":
                        ${JSON.stringify(images.map(image =>
                            `${convertPath(image.path)}`
                        ))},
                    "location": {
                        "@type": "Place",
                        "address": {
                            "@type": "PostalAddress",
                            "addressLocality":
                                ${JSON.stringify(event.get('location') ? event.get('location').city : '')},
                            "postalCode":
                                ${JSON.stringify(event.get('location') ? event.get('location').postcode : '')},
                            "streetAddress":
                                ${JSON.stringify(event.get('location') ? event.get('location').address : '')}
                        },
                        "name": ${JSON.stringify(event.get('location') ? event.get('location').name : '')}
                    },
                    "name": ${JSON.stringify(event.name)},
                    "offers": {
                        "@type": "Offer",
                        ${''/*Leave off price, since we cannot determine its exact structure (free text input)*/}
                        ${''/*"price": ${JSON.stringify(event.price ? event.get('price') : '')},*/}
                        ${''/*"priceCurrency": "EUR",*/}
                        "url": ${JSON.stringify(event.get('ticket_link') ? event.get('ticket_link') : '')}
                    }
                }
            `}
            </script>
        );
    }
}
