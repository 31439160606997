
// Generate a random int within the given range (min is inclusive, max is exclusive)
// Source: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Math/random
export const generateRandomInt = (min, max) => {
    return Math.floor(Math.random() * (max - min)) + min;
};

// A very simple seeded random generator.
// Source: https://stackoverflow.com/questions/521295/seeding-the-random-number-generator-in-javascript
// Note: `seed` needs to be integer, greater than zero.
export const createRandomGenerator = seed => {
    if (seed <= 0 || !Number.isInteger(seed) || !Number.isFinite(seed)) {
        throw new TypeError(`Seed must be an integer greater than zero`);
    }
    
    let seedCurrent = seed;
    return () => {
        const x = Math.sin(seedCurrent++) * 10000;
        return x - Math.floor(x);
    };
};
