
import * as React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import inject from '../../util/di/inject.js';


@inject({
    sites: 'cpg.sites',
})
export default class AboutGroningen extends React.PureComponent {
    render () {
        return (
            <React.Fragment>
                <Helmet>
                    <body className="content-page page-about bg-image"/>
                    <title>About Groningen</title>
                    <meta name="description" content="About Groningen"/>
                </Helmet>
                
                <div className="container content-wrapper">
                    <div className="row">
                        <div className="well well-white col-xs-10 col-xs-offset-1 col-md-8 col-md-offset-2">
                            <div className="row">
                                <div className="col-sm-10 col-sm-offset-1">
                                  <h1>About Groningen</h1>
                                  <p className="txt-bold">Groningen, also known as The City of Talent, is one of the youngest and most vibrant cities in the Netherlands (Or maybe even the world?!). That makes it the best place to enjoy unique cultural events, as well as experience the amazing atmosphere of the North - beautiful, compact and, hey, this city never sleeps!</p>
                                  <p>Groningen by day is abundant with pop-up events, exhibitions, markets, cafes, festivals, films and gatherings with the ultimate laid back atmosphere.</p>
                                  <p>While Groningen by night always keeps its promise to give you the best live music and parties; even boasting an extensive underground and 'upperground' scene. One thing we know for sure: there's a spot to everyone’s taste! Wanna find yours? Use our map below to discover the cultural scene of Groningen and keep an eye on Here & Now to know about all the events happening today!
                                  </p>
                                  <h2>Cultural map of Groningen</h2>
                                  <a target="_blank" href="downloads/groningen-cultural-map.pdf" title="Download the Cultural Map of Groningen (PDF)" download>
                                      <img className="img-responsive" alt="Logo University of Groningen"
                                          src={require('../../style/img/groningen-cultural-map.jpg')}
                                      />
                                  </a>
                                  <p className="text-right">
                                    <small>
                                      Artwork by <a target="_blank" href="https://www.instagram.com/loesfaberillustration/?hl=en" title="Instagram Loes Faber">Loes Faber</a>
                                    </small>
                                  </p>
                                  <p className="text-center">
                                    <a className="btn btn-primary btn-lg" target="_blank" href="downloads/groningen-cultural-map.pdf" title="Download the Cultural Map of Groningen (PDF)" download>
                                        Download <span className="hidden-xs">Cultural</span> Map (PDF)
                                    </a>
                                  </p>
                                  <h2>Find upcoming events in Groningen</h2>
                                  <p>
                                    <Link className="btn btn-default" to="/" title="Here &amp; Now in Groningen">
                                        <span className="icon icon-arrow-right-circle" aria-hidden="true" aria-label="Arrow icon"/>
                                        All events
                                    </Link>
                                  </p>
                                  <p>
                                    <Link className="btn btn-default" to="/category/special/this-week" title="This weeks events in Groningen">
                                        <span className="icon icon-arrow-right-circle" aria-hidden="true" aria-label="Arrow icon"/>
                                        Events this week
                                    </Link>
                                  </p>
                                  <p>
                                    <Link className="btn btn-default" to="/nearby" title="Map with events in Groningen">
                                        <span className="icon icon-arrow-right-circle" aria-hidden="true" aria-label="Arrow icon"/>
                                        Events near me
                                    </Link>
                                  </p>
                                  
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
