
import _ from 'lodash';
import $ from 'jquery';
import moment from 'moment';
import { mapToElements } from '../../util/functional.js';
import Imm from 'immutable';
import { Link } from 'react-router-dom';

import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import inject from '../../util/di/inject.js';
import * as ReactRedux from 'react-redux';

import Collection from '../../util/models/Collection.js';
import Event from '../../models/Event.js';
import EventsSliderElement from './EventsSliderElement.js';
import EventDetail from './EventDetail.js';
import Slider from 'react-slick';

import ErrorBoundary from '../../components/ErrorBoundary.js';


const sliderConfig = {
    // Note: lazy loading is broken in the current version of react-slick:
    // https://github.com/akiran/react-slick/issues/788
    //lazyLoad: 'progressive',
    
    // For responsive issues see also: https://github.com/akiran/react-slick/issues/656
    
    dots: false,
    infinite: false,
    speed: 300,
    slidesToShow: 5,
    //centerMode: false,
    variableWidth: true,
    responsive: [
        {
            breakpoint: 1920,
            settings: {
                centerMode: false,
                slidesToShow: 5,
                //slidesToScroll: 5,
            },
        },
        {
            breakpoint: 1440,
            settings: {
                centerMode: false,
                slidesToShow: 5,
                //slidesToScroll: 4,
            },
        },
        {
            breakpoint: 1200,
            settings: {
                centerMode: false,
                slidesToShow: 4,
                //slidesToScroll: 4,
            },
        },
        {
            breakpoint: 992,
            settings: {
                centerMode: false,
                slidesToShow: 3,
                //slidesToScroll: 3,
            },
        },
        {
            breakpoint: 768,
            settings: {
                centerMode: false,
                slidesToShow: 2,
                //slidesToScroll: 2,
            },
        },
        {
            breakpoint: 480,
            settings: {
                arrows: false,
                centerMode: false,
                slidesToShow: 1,
                //slidesToScroll: 1,
            },
        },
    ],
};

@inject({
    lang: 'app.lang',
    responsive: 'cpg.responsive',
})
export default class EventsSlider extends React.Component {
    static propTypes = {
        className: PropTypes.string,
        lang: PropTypes.func.isRequired,
        title: PropTypes.element.isRequired,
        events: PropTypes.instanceOf(Collection).isRequired,
        disabled: PropTypes.node,
        hoverEventId: PropTypes.string,
        active: PropTypes.bool.isRequired,
        activeEventId: PropTypes.string,
        onHover: PropTypes.func.isRequired,
        onActivate: PropTypes.func.isRequired,
        loadMore: PropTypes.func,
    };
    
    static defaultProps = {
        className: '',
    };
    
    // Refs
    sliderWrapperRef = null;
    sliderRef = null;
    
    getActiveRange() {
        if (this.sliderRef === null) {
            return null;
        }
        
        // Note: we need to grab this info from the slider component directly. This is unfortunate, but
        // currently there seems to be no other way.
        
        const currentSlide = this.sliderRef.innerSlider.state.currentSlide;
        
        // The number of currently active slides (depends on breakpoints)
        const activeSlideCount = this.sliderRef.innerSlider.props.slidesToShow;
        
        return [currentSlide, currentSlide + activeSlideCount];
    }
    
    buildSliderConfig = (customConfig = {}) => {
        return _.merge(sliderConfig, customConfig, {
            beforeChange: (currentSlide, targetSlide) => {
                const ref = this.sliderWrapperRef;
                
                const events = this.props.events;
                
                // Lazy loading
                if (this.props.loadMore && targetSlide > currentSlide) {
                    const activeSlideCount = this.sliderRef.innerSlider.props.slidesToShow;
                    
                    // Note: targetSlide refers to the first item in the slider
                    const lazyLoadMargin = 3; // Start loading new items with the given margin before the slider end
                    if (events && targetSlide >= (events.size - activeSlideCount - lazyLoadMargin)) {
                        const diff = events.size - (targetSlide + activeSlideCount);
                        this.props.loadMore(diff);
                    }
                }
                
                const isTouch = this.props.responsive.isTouchEnabled();
                if (!isTouch) {
                    // Close the current active event before moving left/right
                    this.props.onActivate({ event: null });
                }
                
                this.forceUpdate();
            },
            afterChange: (currentSlide) => {
                const ref = this.sliderWrapperRef;
                
                // Re-render this component, so that our class names get updated
                this.forceUpdate();
            },
        });
    };
    
    componentDidMount() {
        // Now that we have a `sliderRef`, re-render with screen-dependent class names
        this.forceUpdate();
    }
    
    getElementClassNames(eventId, event, slidePos, shouldMove) {
        const { hoverEventId } = this.props;
        const range = this.getActiveRange();
        
        if (range === null) {
            return {};
        }
        
        const [rangeStart, rangeEnd] = range;
        
        return {
            'move-left': shouldMove && slidePos < rangeEnd - 1,
            'scale-left': slidePos === rangeEnd - 1,
        };
    }
    
    renderElements(events) {
        const { active, hoverEventId, auth, update } = this.props;
        const range = this.getActiveRange();
        
        const interaction = {
            active: this.props.active,
            activeEventId: this.props.activeEventId,
            hoverEventId: this.props.hoverEventId,
        };
        
        const eventEntries = [...events.entrySeq()]; // Convert to entries array, so have a numerical index
        
        // Preprocess: check if the last active element has hover (if so, we need to move prior slides back)
        let shouldMove = false;
        if (range !== null && hoverEventId !== null) {
            const [rangeStart, rangeEnd] = range;
            
            eventEntries.forEach(([eventId, event], slidePos) => {
                // Only move if:
                // - the user is hovering over the last active element, and
                // - the dropdown is not currently open (because this disables the hover effect)
                if (eventId === hoverEventId && !active) {
                    if (slidePos === rangeEnd - 1) {
                        shouldMove = true;
                    }
                }
            });
        }
        
        return eventEntries.map(([eventId, event], slidePos) =>
            <div key={eventId}
                className={cx({
                    'event-item': true,
                    'focus': interaction.active && interaction.activeEventId === eventId,
                    'hover': !interaction.active && interaction.hoverEventId === eventId,
                    ...this.getElementClassNames(eventId, event, slidePos, shouldMove),
                })}
                onClick={evt => {
                    evt.preventDefault();
                    this.props.onActivate({ event });
                }}
            >
                <EventsSliderElement
                    key={eventId}
                    event={event}
                    auth={auth}
                    update={update}
                    onHover={hover => {
                        this.props.onHover({ event: hover ? event : null });
                    }}
                />
            </div>
        );
    }
    
    render() {
        const { lang, title, events, disabled, auth, update, anchorName } = this.props;
        
        const sliderConfig = this.buildSliderConfig();
        
        const interaction = {
            active: this.props.active,
            activeEventId: this.props.activeEventId,
            hoverEventId: this.props.hoverEventId,
        };
        
        // Don't draw the events when it is empty
        if (!events || events.size === 0) {
            return <div/>;
        }
        
        const range = this.getActiveRange();
        const sliderClassNames = {
            'has-next': true,
        };
        if (range) {
            const [rangeStart, rangeEnd] = range;
            const numEvents = events.size;
            
            // Determine whether we should show prev/next buttons
            const hasPrev = rangeStart > 0;
            const hasNext = rangeEnd === numEvents;
            
            if (hasPrev) {
                sliderClassNames['has-prev'] = true;
            }
            if (hasNext) {
                sliderClassNames['has-next'] = false;
            }
        }
        
        return (
            <section id={anchorName !== undefined ? anchorName.toLowerCase().replace(/\W/g, '') : ''} ref={ref => { this.sliderWrapperRef = ref; }}
                className={cx({
                    'event-slider-wrapper': true,
                    'slider-open': interaction.active,
                    ...sliderClassNames,
                    [this.props.className]: true,
                })}
            >
                <div className="container-fluid">
                    <div className="row vertical-align">
                        <header className="col-xs-12">
                            <h2>{title}</h2>
                        </header>
                    </div>
                    <div className="row vertical-align slider-inner-wrapper">
                        {disabled}
                        <div className="col-xs-12">
                            <Slider ref={ref => { this.sliderRef = ref; }}
                                {...sliderConfig}
                                className={cx({
                                    'event-slider': true,
                                    'focus': interaction.active,
                                    'hover': !interaction.active && interaction.hoverEventId !== null,
                                })}
                            >
                                {events.size !== 0
                                    ? this.renderElements(events)
                                    : <div/> // Placeholder, slider cannot have 0 elements
                                }
                            </Slider>
                        </div>
                    </div>
                    
                    <div
                        className={cx({
                            'slider-item-detail-container': true,
                            'open loaded': interaction.active,
                        })}
                    >
                        <div className="container-fluid">
                            <span className="icon icon-close slider-item-detail-close"
                                aria-hidden="true" aria-label="Close"
                                onClick={() => {
                                    this.props.onActivate({ event: null });
                                    this.props.onHover({ event: null });
                                }}
                            />
                            <div className="slider-item-details-slidedown">
                                <ErrorBoundary>
                                    {interaction.activeEventId !== null && events.get(interaction.activeEventId) &&
                                        <EventDetail
                                            update={update}
                                            auth={auth}
                                            event={events.get(interaction.activeEventId)}
                                        />
                                    }
                                </ErrorBoundary>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
