
import moment from 'moment';

import * as React from 'react';
import { Helmet } from 'react-helmet';
import * as ReactRedux from 'react-redux';
import * as Router from 'react-router';
import { Link } from 'react-router-dom';

import inject from '../../util/di/inject.js';
import { convertPath } from '../../services/model/ImageService.js';
import api from '../../services/CpgApi.js';
import EventsSliderList from '../events/EventsSliderList.js';
import EventsSlider from '../events/EventsSlider.js';

@Router.withRouter
@inject({
    sites: 'cpg.sites',
})
@ReactRedux.connect(
    state => ({
        auth: state.getIn(['session', 'auth']),
        contentPages: state.getIn(['app', 'contentPages']),
        eventGroups: state.getIn(['app', 'eventgroups']),
    }),
    dispatch => ({
        fetchContentPages: () => dispatch(api.contentPages.list()),
        fetchEventGroups: () => dispatch(api.eventGroups.list({ home: true })),
    }),
)
export default class Explore extends React.PureComponent {
    state = {
        sliderActive: false,
    };
    
    load(prevProps, prevState) {
        const { contentPages, eventGroups } = this.props;
        
        // Note: cannot use `status === invalid` check here, because if we go to an event detail page,
        // and then navigate back in the history, we end up with redux state that is incompatible with our
        // own required state.
        
        //if (contentPages.meta.status === 'invalid') { this.props.fetchContentPages(); }
        this.props.fetchContentPages();
        
        //if (eventGroups.meta.status === 'invalid') { this.props.fetchEventGroups(); }
        this.props.fetchEventGroups();
    }
    
    componentDidMount() {
        //if (this.props.contentPages.meta.status === 'invalid') { this.load(); }
        this.load();
    }
    
    getFeaturedEvents() {
        const { auth, eventGroups } = this.props;
        
        const featuredEventGroup = eventGroups.get('featured');
        
        // Note: do not show featured events if empty, or if we have less than 5 events
        if (!featuredEventGroup || featuredEventGroup.get('events').size < 5) {
            return null;
        }
        
        const featuredEvents = featuredEventGroup.get('events')
            .sortBy(event => moment(event.start_date).format('YYYY-MM-DD'));
        
        return featuredEvents;
    }
    
	render() {
        const { contentPages } = this.props;
        
        const site = this.props.sites.getSite(window.location);
        
        const featuredEvents = this.getFeaturedEvents();
        
		return (
			<React.Fragment>
                <Helmet>
                    <body className="content-page page-explore-alt explore bg-image"/>
                    <title>Explore</title>
                    <body className={`page-about explore bg-image ${this.state.sliderActive ? 'slider-open' : ''}`}/>
                    <meta name="description" content={`Explore ${site.title}`}/>
                    <style>{`
                        .slick-track { overflow: hidden; }
                    `}</style>
                </Helmet>
                
                <div className="content-wrapper" style={{ overflowY: 'visible' }}>
                    <div className="row">
                        <div className="well well-white col-xs-10 col-xs-offset-1 col-md-6 col-md-offset-3">
                            <div className="row">
                                <div className="title-banner">
                                    <h1>{`Explore ${site.title}`}</h1>
                                </div>
                                
                                <div className="title-wrap"><h2>Articles</h2></div>
                                {/* Masonry articles */}
                                <div className="masonry">
                                    {contentPages.mapToElements(page =>
                                        <div className="masonry-item" style={{ cursor: 'pointer' }}
                                            onClick={() => {
                                                this.props.history.push(`/explore/${page.content_key}`);
                                            }}
                                        >
                                            <img className="masonry-content" alt={page.image.name}
                                                src={convertPath(page.image.path)}
                                            />
                                            <div className="article-title">
                                                <p><span>{page.title}</span></p>
                                            </div>
                                        </div>
                                    )}
                                </div>
                                
                                <div className="title-wrap"><h3>All articles</h3></div>
                                {/* complete list of articles */}
                                <div className="article-list">
                                    <ul>
                                        {contentPages.mapToElements(page =>
                                            <>
                                                <li style={{ cursor: 'pointer' }}
                                                    onClick={() => {
                                                        this.props.history.push(`/explore/${page.content_key}`);
                                                    }}
                                                >
                                                    <h3>
                                                        <Link to={`/explore/${page.content_key}`}>{page.title}</Link>
                                                    </h3>
                                                    <p>
                                                        <span>{page.text_summary}</span>
                                                    </p>
                                                    <div className="article-date">
                                                        <p>
                                                            Published: {moment(page.created_at).format('ddd Do MMMM YYYY')}
                                                        </p>
                                                    </div>
                                                </li>
                                                <div className="divider"/>
                                            </>
                                        )}
                                    </ul>
                                </div>
                                
                                {featuredEvents &&
                                    <EventsSliderList
                                        onActivate={sliderActive => { this.setState({ sliderActive }); }}
                                        enableDropdown={false}
                                    >
                                        <EventsSlider
                                            disabled={null}
                                            auth={this.props.auth}
                                            update={() => { }}
                                            title={<span className="nolink">Featured events</span>}
                                            events={featuredEvents}
                                        />
                                    </EventsSliderList>
                                }
                            </div>                       
                        </div>
                    </div>
                </div>
                
            </React.Fragment>
		);
	}
}
