
import _ from 'lodash';
import Imm from 'immutable';

import uri from '../../util/tag_uri.js';
import superagent from 'superagent';
import superagentWithUse from 'superagent-use';
import session from '../../modules/session/Logout.js';


const configure = ({ uri: apiUri, getAuthToken }) => request => {
    // todo does not work for urls with additional suburl with localhost:8000/api-v1/ the last part is omitted
    // if (request.url[0] === '/' && _.isString(apiUri)) {
    //     const apiUriFormatted = apiUri.replace(/\/+$/, '');
    //     const requestUriFormatted = request.url.replace(/^\/|\/+$/g, '');
    //
    //     request.url = `${apiUriFormatted}/${requestUriFormatted}`;
    // }
    request.url = apiUri + request.url;
    request.set('Content-Type', 'application/json');
    request.set('Accept', 'application/json');
    
    if (getAuthToken) {
        const token = getAuthToken();
        
        if (typeof token === 'string') {
            request.set('Authorization', `Bearer ${token}`);
        }
    }
    
    // Send browser cookies along with the request
    request.withCredentials();
    
    return request;
};

const unauthorizedRedirect = req => {
    if (typeof window !== 'undefined') {
        req.on('response', res => {
            const auth = window.localStorage.getItem('auth');
            if (res.status === 401 && auth) {
                // TODO: We should change this, and instead just show the unauthorized version of the app.
                // Make sure we change this in a way that doesn't break the consumer due to it expecting a response.
                
                //const store = require('../../../app/app.js').store();
                //store.dispatch({ type: 'session.log_out' });
                // window.location.assign('/login');
                
                if (window.localStorage.hasOwnProperty('auth')) {
                    window.localStorage.removeItem('auth');
                    window.location.reload();
                } else {
                    // No auth token in localStorage, so ignore
                }
            }
        });
    }
};


const agent = (config = {}) =>
    superagentWithUse(superagent)
        .use(configure(config))
        .use(unauthorizedRedirect);


export default agent;
