
/*
Top-level application interface, for use in a browser.
*/

import ReactDOM from 'react-dom';
import { createBrowserHistory } from 'history';
import ReactGA from 'react-ga';

import * as Cpg from './app.js';


// Load styling
// Note: all LESS files are parsed through webpack (less-loader). The resulting CSS files
// are then extracted to a .css file in `/dist`.
import '../src/style/main.less';


// Expose the store (and related utilities) for debugging purposes
export const store = Cpg.store();
export { default as Imm } from 'immutable';

export { default as agent } from '../src/services/CpgAgent.js';
export { default as api } from '../src/services/CpgApi.js'; 

// Scripts loader
export { default as init } from './scripts.js';

// Preloading
import Collection from '../src/util/models/Collection.js';
import { makeEntry } from '../src/util/api/collection_util.js';
import Event from '../src/models/Event.js';
import ContentPage from '../src/models/ContentPage.js';
import Image from '../src/models/Image.js';
import Location from '../src/models/Location.js';
import SitesService from '../src/services/SitesService.js';
import { parseCookies } from '../src/util/user_agent.js';


// Mount the application on the given DOM element
export const mount = (mountElement, preloadedState) => {
    const site = new SitesService().getSite(window.location);
    window.site = site;
    store.dispatch({ type: 'site.switch', site });
    
    // Add a "site-<site>" class to the <html> element
    document.documentElement.classList.add(`site-${site.name}`);

    const history = createBrowserHistory({
        basename: site.basename,
    });
    
    // Redirect user to last visited site. We accept that we won't show the portal very often. We are using a cookie
    // over localStorage so it can be shared across different subdomains. Note we are encoding the cookie to 
    // circumvent the semicolon problem: https://www.sitepoint.com/community/t/cookie-semicolon-problem/3593
    try {
        const cookies = parseCookies(document.cookie);
        if (cookies.last_visited_site !== undefined) {
            const lastVisitedSite = JSON.parse(decodeURIComponent(cookies.last_visited_site));
            if (site.name === 'portal' && history.location.pathname !== '/portal') {
                const sitesService = new SitesService();
                sitesService.switch(lastVisitedSite);
                return;
            }
        }
    } catch (e) {
        // Ignore
    }
    if (site.name !== 'portal') {
        const domain = window.location.hostname.replace(`${site.name}.`, '');
        const expireDate = new Date();
        expireDate.setDate(expireDate.getDate() + 365);
        document.cookie = 'last_visited_site=' + encodeURIComponent(JSON.stringify(site)) + ';path=/;domain=' + domain + ';expires=' + expireDate.toUTCString();
    }

    const shouldPublish = process.env.SITE_PUBLISH === 'true';
    
    if (shouldPublish) {
        // Initialize Google Analytics
        ReactGA.initialize(site.google_analytics_id);

        // GDPR compliance: anonymize IP addresses
        ReactGA.set({ anonymizeIp: true });
        
        // Track initial page view
        ReactGA.pageview(history.location.pathname);
        
        // Track subsequent page views
        history.listen((location, action) => {
            ReactGA.set({ page: location.pathname });
            ReactGA.pageview(location.pathname);
        });
    }
    
    // Preload state
    if (typeof preloadedState === 'object' && preloadedState) {
        try {
            const events = preloadedState.app.events;
            
            Object.entries(events).forEach(([eventId, eventEncoded]) => {
                const event = new Event({
                    ...eventEncoded,
                    id: eventId,
                    images: new Collection(Image, { status: 'ready' },
                        eventEncoded.images.map(imageEncoded => makeEntry(Image, imageEncoded))
                    ),
                }, { status: 'ready' });
                
                store.dispatch({
                    type: 'api.load',
                    requestId: null,
                    path: ['app', 'events', eventId],
                    status: 'ready',
                    value: event,
                });
            });
            
            
            const contentPages = preloadedState.app.contentPages;
            
            Object.entries(contentPages).forEach(([pageId, pageEncoded]) => {
                const page = new ContentPage({
                    ...pageEncoded,
                    id: pageId,
                }, { status: 'ready' });
                
                store.dispatch({
                    type: 'api.load',
                    requestId: null,
                    path: ['app', 'contentPages', pageId],
                    status: 'ready',
                    value: page,
                });
            });
        } catch (e) {
            // Ignore
        }
    }
    
    const appElement = Cpg.view(store, history);
    
    ReactDOM.render(appElement, mountElement);
    // TODO: disabled until we can fix this problem: https://3.basecamp.com/3800337/buckets/4334809/todos/2587760942
    // if (mountElement.hasChildNodes()) {
    //     ReactDOM.hydrate(appElement, mountElement);
    // } else {
    //     ReactDOM.render(appElement, mountElement);
    // }
};
