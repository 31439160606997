
import moment from 'moment';

import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import HoverIntent from 'react-hoverintent';
import {getFallbackImage, convertPath} from '../../services/model/ImageService';
import * as Router from 'react-router';
import { Link } from 'react-router-dom';


@Router.withRouter
export default class CategorySliderElement extends React.Component {
    
    state = {
        hover: false,
    };
    
    mouseAction = hover => {
        this.setState({ hover });
    };
    
    render() {
        const { mainEventGroup, eventGroup } = this.props;
        const { hover } = this.state;
        const image = (eventGroup.images && eventGroup.images.size) > 0
            ?
                <img
                    className="item-img lazyload"
                    data-src={convertPath(eventGroup.images.first().get('path')) + '&w=350&h=190&fit=crop'}
                    src={getFallbackImage()}
                />
            : <img className='item-img' src={getFallbackImage()}/>;
        
        return (
            <HoverIntent
                onMouseOver={() => { this.mouseAction(true); }}
                onMouseOut={() => { this.mouseAction(false); }}
                sensitivity={3}
                interval={200}
                timeout={0}
            >
                <div className={cx({'focus': hover})}>
                    <div className="item-preview">
                        <Link className="item-img-wrapper"
                            to={`/category/${mainEventGroup.slug}/${eventGroup.slug}`} // Provide URL fallback (e.g. for SEO)
                            onClick={evt => {
                                evt.preventDefault();
                            }}
                        >
                            {image}
                        </Link>
                        <div className="item-preview-content-wrapper">
                            <h3 className="item-title">
                                <span>{eventGroup.get('name')}</span>
                            </h3>
                            {/*
                            <div className="item-date label label-primary hidden-xs">
                                {mainEventGroup.name}
                            </div>
                            */}
                        </div>
                    </div>
                </div>
            </HoverIntent>
        )
    }
}
