
import _ from 'lodash';
import Imm from 'immutable';
import React from 'react';

import Schema from './schema.js';
import Collection from './Collection.js';
import {collectionFromResponse} from '../api/collection_util';


const getDefaults = schema => _.mapValues(schema, prop => {
    if (prop instanceof Collection) {
        return prop;
    } else {
        return null;
    }
});


export default class Entity {
    meta = {
        status: 'invalid',
    };
    
    _record = undefined;
    _value = undefined;
    
    constructor(schema, value = {}, meta = {}) {
        const record = Imm.Record(getDefaults(schema));
        this._record = record;
        this._value = record(value);
        
        Object.assign(this.meta, meta);
        
        // Define getters for all properties (to allow using `entity.foo` instead of `entity.get('foo')`)
        
        for (let keyName of this._value.keySeq()) {
            Object.defineProperty(this, keyName, {
                get: () => this._value.get(keyName),
            });
        }
    }
    
    withStatus(status) {
        return new this.constructor(this._value, { status });
    }
    
    
    _apply(fnName, ...args) { return new this.constructor(this._value[fnName](...args), this.meta); }
    
    get(...args) { return this._value.get(...args); }
    getIn(...args) { return this._value.getIn(...args); }
    keySeq(...args) { return this._value.keySeq(...args); }
    valueSeq(...args) { return this._value.valueSeq(...args); }
    entrySeq(...args) { return this._value.entrySeq(...args); }
    sort(...args) { return this._value.sort(...args); }
    
    toObject(...args) { return this._value.toObject(...args); }
    toJS(...args) { return this._value.toJS(...args); }
    *[Symbol.iterator]() {
        for (let entry of this._value) {
            yield entry;
        }
    }
    
    set(...args) { return this._apply('set', ...args); }
    setIn(...args) { return this._apply('setIn', ...args); }
    update(...args) { return this._apply('update', ...args); }
    updateIn(...args) { return this._apply('updateIn', ...args); }
    map(...args) { return this._apply('map', ...args); }
    mergeDeepWith(...args) { return this._apply('mergeDeepWith', ...args); }
    mergeWith(...args) { return this._apply('mergeWith', ...args); }
    
    toMap(...args) { return this._apply('toMap', ...args); }
    toKeyedSeq(...args) { return this._apply('toKeyedSeq', ...args); }
    
    toJSON() {
        return this._value.toJSON();
    }
    
    toString() {
        return this._value.toString();
    }
};
