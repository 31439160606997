
import $uri from 'uri-tag';
import moment from 'moment';


export default class SocialMediaService {
    _formatGoogleCalendarDateTime(datetime) {
        // Example: 20200226T205500Z
        // Note: this is slightly different from standard ISO format, so we cannot just use `toISOString()`
        
        return datetime.clone().utc().format('YMMDD[T]HHmmss[Z]');
    }
    
    genGoogleCalendarLink(event) {
        // Example URL:
        // https://www.google.com/calendar/render?action=TEMPLATE&text=foo&details=bar&location=baz&dates=20200226T205500Z%2F20200227T205500Z
        
        const nameFormatted = event.get('name') || '';
        
        const location = event.get('location')
        let locationFormatted = '';
        if (location) {
            locationFormatted = `${location.name} ${location.address} ${location.postcode} ${location.city}`;
        }
        
        const datesFormatted = `${this._formatGoogleCalendarDateTime(moment(event.get('start_date')))}/${this._formatGoogleCalendarDateTime(moment(event.get('end_date')))}`;
        
        const detailsFormatted = `${event.get('description')}\n\nAdded via Here & Now (hereandnow.nl)`;
        
        return $uri`http://www.google.com/calendar/render?action=TEMPLATE&text=${nameFormatted}&dates=${datesFormatted}&details=${detailsFormatted}&location=${locationFormatted}&trp=false&sprop=&sprop=name:`;
    }
}
