
import { makeEntry } from '../../util/api/collection_util.js';
import Collection from '../../util/models/Collection.js';
import Category from '../../models/Category.js';
import agent from '../CpgAgent.js';
import Image from '../../models/Image.js';

export const getFavoriteCategories = (uri, spec) => {
    return agent.get(`/favorites${spec.uri}`)
        .then(response => {
            const body = response.body.map(category => ({ ...category, id: category.slug }));
            return { ...response, body };
        });
};

export const getCategories = (uri, spec) => {
    return agent.get(spec.uri)
        .then(response => {
            const body = new Collection(Category, { status: 'ready' }, response.body.map(category => {
                const imageEntries = category.images.map(item => {
                    return makeEntry(Image, item);
                });
                const childEntries = category.children.map(item => {
                    const childImageEntries = item.images.map(childImage => {
                        return makeEntry(Image, childImage);
                    });
                    const childImages = new Collection(Image, { status: 'ready' }, childImageEntries);
                    return makeEntry(Category, { ...item, id: item.id, images: childImages});
                });
                
                const children = new Collection(Category, { status: 'ready' }, childEntries);
                const images = new Collection(Image, { status: 'ready' }, imageEntries);
                
                return makeEntry(Category, { ...category, children: children, images: images });
            }));
            
            return { ...response, body };
        });
};
