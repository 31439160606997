

import PropTypes from 'prop-types';
import React from 'react';

import { Helmet } from 'react-helmet';
import * as ReactRedux from 'react-redux';
import * as Router from 'react-router';
import { Link } from 'react-router-dom';

import api from '../../services/CpgApi.js';
import EventAdvancedDetail from './EventAdvancedDetail';
import EventsSliderList from './EventsSliderList';
import EventsSlider from './EventsSlider';


@Router.withRouter
@ReactRedux.connect(
    state => ({
        events: state.getIn(['app', 'events']),
        eventGroups: state.getIn(['app', 'eventgroups']),
        auth: state.getIn(['session', 'auth']),
    }),
)
export default class EventOverview extends React.PureComponent {
    state = {
        sliderActive: false,
    };
    
    update() {
        const { events, match } = this.props;
        const id = match.params.id;
        
        if (!events.has(id) || events.get(id).meta.status !== 'ready') {
            this.props.dispatch(api.events.get(id));
        }
        
        // Always reload event groups
        this.props.dispatch(api.eventGroups.list({ eventId: id }));
    }
    
    componentDidMount() {
        this.update();
    }
    
    componentDidUpdate(prevProps) {
        if (this.props.match.params.id !== prevProps.match.params.id) {
            this.update();
        }
    }
    
    render() {
        const { events, match, history, eventGroups, auth } = this.props;
        
        if (!events.has(match.params.id) || events.get(match.params.id).meta.status !== 'ready') {
            return (
                <div className="content-wrapper">
                    <header className="container-fluid jumbotron jumbotron-event loading">
                        <div className="item-detail-wrapper row">    
                            <div className="item-more-details">    
                                <div className="row item-details-inner-wrapper">
                                    <div className="col-xs-12 col-sm-8 col-lg-6 col-xl-5 item-details-content-wrapper vertical-align-xs">
                                        {/* Content */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </header>
                </div>
            );
        }
        
        const event = events.get(match.params.id);
        return (
            <div className="content-wrapper">        
                <Helmet>
                    <body className={`page-event-detail ${this.state.sliderActive ? 'slider-open' : ''}`}/>
                </Helmet>
                
                <header className="container-fluid jumbotron jumbotron-event margin60B">   
                    <div className="item-detail-wrapper row">                
                        {event && event.name &&
                            <EventAdvancedDetail
                                auth={auth}
                                event={event}
                                update={() => this.update()}
                            />
                        }
                    </div>
                </header>
                
                {eventGroups.meta.status === 'ready' &&
                    <EventsSliderList onActivate={sliderActive => { this.setState({ sliderActive }); }}>
                        {eventGroups.mapToElements((eventGroup, eventGroupId) => (
                            <EventsSlider
                                key={eventGroupId}
                                disabled={eventGroup.get('disabled')}
                                auth={auth}
                                update={() => this.update()}
                                title={
                                    eventGroup.get('link') && eventGroup.get('link') !== ''
                                        ?
                                        <Link to={`/category/${eventGroup.get('link')}`}>
                                            {eventGroup.name}
                            
                                            <span className="icon icon-arrow-right"
                                                  aria-hidden="true" aria-label={`More ${eventGroup.name}`}
                                            />
                                        </Link>
                                        :
                                        <span>{eventGroup.name}</span>
                                }
                                events={eventGroup.events}
                            />
                        ))}
                    </EventsSliderList>
                }
            </div>
        );
    }
}
