
import PropTypes from 'prop-types';
import React from 'react';
import * as Router from 'react-router';
import { Helmet } from 'react-helmet';

import * as ReactRedux from 'react-redux';


@Router.withRouter
@ReactRedux.connect(
    state => ({
        auth: state.getIn(['session', 'auth']),
    }),
    dispatch => ({
        logOut: () => dispatch({ type: 'session.log_out' }),
    }),
)
export default class Logout extends React.PureComponent {
    static propTypes = {
        history: PropTypes.object.isRequired,
        logOut: PropTypes.func.isRequired,
    };
    
    componentWillMount() {
        this.props.logOut();
        this.props.history.push('/login');
    }
    
    render() {
        return (
            <div>
                <Helmet>
                    <title>Log out</title>
                    <meta name="description" content="Log out"/>
                </Helmet>
                
                <h1>Logout</h1>
            </div>
        );
    }
}
