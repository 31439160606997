
import React from 'react';


const style = {
    boxSizing: 'border-box',
    height: '100%',
    maxHeight: '100vh',
    width: '100%',
    maxWidth: '100vw',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'left',
};

const svgStyle = {
    fill: 'currentColor',
    flex: '1 1 auto',
};

const ErrorDisplay = ({ error, componentStack }) =>
    <div style={style}>
        <div className="alert alert-danger margin50T padding50T">
            <h1>Something went wrong</h1>
            <p className="error-message">
                The event information could not be loaded. Please refresh the page, or try again later.
            </p>
            <p>
                We are aware of this problem, and we will look into it shortly.
            </p>
            <p>
                <button
                    className="btn btn-black margin10T"
                    onClick={() => { window.location.refresh(); }}
                >
                    Refresh page
                </button>
            </p>
            {/*<pre className="error-info">{componentStack}</pre>*/}
        </div>
    </div>;

export default class ErrorBoundary extends React.PureComponent {
    state = {
        hasError: false,
        error: null,
        info: null,
    };
    
    componentDidCatch(error, info) {
        this.setState({
            hasError: true,
            error,
            info,
        });
    }
  
    render() {
        if (this.state.hasError) {
            return (
                <ErrorDisplay
                    error={this.state.error}
                    componentStack={this.state.info ? this.state.info.componentStack : ''}
                />
            );
        }
        return this.props.children || null;
    }
}
