
import moment from 'moment';
import match from '@mkrause/match';

import cx from 'classnames';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import * as ReactRedux from 'react-redux';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import inject from '../../util/di/inject.js';

import api from '../../services/CpgApi.js';
import apiAgent from '../../services/CpgAgent.js';
import Event from '../../models/Event.js';

import * as Leaflet from 'leaflet';
import { OpenStreetMapProvider } from 'leaflet-geosearch';
import { withLeaflet, Map as LeafletMap, MapControl, Marker, Popup, TileLayer } from 'react-leaflet';

//import 'leaflet/dist/leaflet.css';

import EventsSlider from './EventsSlider.js';
import EventsSliderList from './EventsSliderList.js';


const geolocationElement = Leaflet.DomUtil.create('div', 'leaflet-bar my-control');

@withLeaflet
class GeolocationControl extends MapControl {
    createLeafletElement(props) {
        const Control = Leaflet.Control.extend({
            onAdd(map) {
                return geolocationElement;
            },
            onRemove(map) {},
        });
        return new Control(props);
    }
    
    componentDidMount() {
        super.componentDidMount();
        ReactDOM.render(
            <a className="geolocation-control" onClick={this.props.onActivate}>
                <i className="icon-compass"/>
            </a>,
            geolocationElement
        );
    }
    
    render() {
        return null;
    }
}

@inject({
    sites: 'cpg.sites',
})
@ReactRedux.connect(
    state => ({
        site: state.getIn(['app', 'site']),
        locations: state.getIn(['app', 'locations']),
        eventGroups: state.getIn(['app', 'eventgroups']),
        myList: state.app.authUser.eventFavorites,
        auth: state.getIn(['session', 'auth']),
    }),
    dispatch => ({
        dispatch,
    }),
    (stateProps, dispatchProps, otherProps) => ({
        ...stateProps,
        ...dispatchProps,
        ...otherProps,
        fetchMyList: () => {
            if (stateProps.auth) {
                return dispatchProps.dispatch(api.eventFavorites.list());
            } else {
                return Promise.resolve();
            }
        },
        toggleFavorite: event => {
            return FavoriteService.toggleFavorite({
                auth: stateProps.auth,
                dispatch: dispatchProps.dispatch,
                myList: stateProps.myList,
                event,
            });
        },
    }),
)
export default class NearbyEvents extends React.PureComponent {
    state = {
        mapFixed: false,
        
        activeLocationId: null,
        sliderActive: false,
        
        viewport: {
            center: [53.21, 6.55],
            zoom: 13,
        },
    };
    
    mapRef = React.createRef();
    
    activateLocation = location => {
        this.setState({
            activeLocationId: location.slug,
            sliderActive: false, // Reset active state
        });
    };
    
    checkMap = () => {
        const map = this.mapRef.current;
        
        if (map) {
            // Only perform the invalidation once to prevent infinite loops
            if (!this.state.mapFixed) {
                // Fix the tile positioning
                window.setTimeout(() => {
                    map.leafletElement.invalidateSize();
                }, 400);
                
                this.setState({ mapFixed: true });
            }
        }
    };
    
    componentDidMount() {
        const viewport = this.props.sites.getViewport(this.props.site.name);
        
        this.setState({ viewport });
        
        // TODO: need to make this an optional button in the UI
        // if (typeof window === 'object' && window.navigator && 'geolocation' in window.navigator) {
        //     navigator.geolocation.getCurrentPosition(position => {
        //         const coords = [position.coords.latitude, position.coords.longitude];
        //         this.setState(st => ({ viewport: { ...st.viewport, center: coords, zoom: 15 } }));
        //     });
        // }
        
        if (window.mapState) {
            this.setState(window.mapState);
        } else {
            window.mapState = {};
        }
        
        this.checkMap();
        
        this.props.dispatch(api.eventGroups.list({ home: true }));
        
        // TODO: use `show_active=true` to filter out locations without events
        // https://gitlab.rimotecloud.com/gemeente/cpg-backend/issues/82
        this.props.dispatch(api.locations.list());
    }
    
    componentDidUpdate(prevProps, prevState) {
        // TODO: need to be able to manually activate the event dropdown that's currently active
        window.mapState = { ...this.state, sliderActive: false };
        
        this.checkMap();
    }
    
    render() {
        if (this.props.eventGroups.size === 0) {
            return <div className="content-wrapper"/>;
        }
        
        const locations = this.props.locations
            .filter(location => location.events.size > 0)
            .filter(location => location.coords !== null);
        
        const activeLocation = this.state.activeLocationId === null
            ? null
            : locations.get(this.state.activeLocationId);
        
        return (
            <div className="content-wrapper">
                <Helmet>
                    <title>Events near me</title>
                    <body className={`page-events page-maps ${this.state.sliderActive ? 'slider-open' : ''}`}/>
                </Helmet>
                
                <div
                    className={cx({
                        'leaflet-loading': this.props.locations.meta.status !== 'ready',
                    })}
                >
                    <LeafletMap ref={this.mapRef}
                        className="leaflet-container"
                        center={this.state.position}
                        zoom={this.state.zoom}
                        onViewportChanged={viewport => {
                            // XXX this causes a bug during animations where the map gets in an infinite loop
                            //this.setState({ viewport });
                            
                            window.mapState.viewport = viewport;
                        }}
                        viewport={this.state.viewport}
                    >
                        {/* TODO: search UI */}
                        {/* TODO: clustering */}
                        
                        <GeolocationControl
                            position="topleft"
                            onActivate={() => {
                                navigator.geolocation.getCurrentPosition(position => {
                                    const coords = [position.coords.latitude, position.coords.longitude];
                                    this.setState(st => ({ viewport: { ...st.viewport, center: coords, zoom: 15 } }));
                                });
                            }}
                        />
                        
                        <TileLayer
                            // url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            url="https://api.mapbox.com/styles/v1/mapbox/light-v9/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoibWFpa2Vsa3JhdXNlIiwiYSI6ImNqbGduZDFleTBjdWczcW8wZzM2MXBwazkifQ.CfgowWHfkpjW6QKzI4PRoQ"
                        />
                        
                        {locations.map((location, locationId) =>
                            <Marker key={locationId}
                                position={location.coords}
                                icon={Leaflet.divIcon({
                                    html: `
                                        <img src="${require('../../style/img/svg/marker.svg')}"
                                            width="30" height="30"
                                        />
                                        <div class="event-count">${location.events.size}</div>
                                    `,
                                    iconAnchor: [15, 0], // Positioning relative to the geocoordinate
                                })}
                                onClick={() => { this.activateLocation(location); }}
                            >
                                <Popup>
                                    <strong className="title">{location.name}</strong>
                                    {location.address && location.postcode && location.city &&
                                        <small className="address">
                                            {location.address}, {location.postcode}, {location.city}
                                        </small>
                                    }
                                </Popup>
                            </Marker>
                        ).toArray()}
                    </LeafletMap>
                </div>
                
                {this.props.locations.meta.status === 'ready' && !activeLocation &&
                    <div className="container-fluid" style={{ zIndex: '1003', position: 'relative' }}>
                        <div className="maps-slider-empty-state">
                            <div className="slider-inner-disabled vertical-align">
                                <div className="well well-white">
                                    <span className="h3">Select a venue</span>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                
                {activeLocation &&
                    <EventsSliderList
                        key={activeLocation.slug} // Key by the location to force reload after venue switch
                        onActivate={sliderActive => { this.setState({ sliderActive }); }}
                    >
                        <EventsSlider
                            className="margin30T"
                            disabled={false}
                            auth={this.props.auth}
                            update={() => {}}
                            title={
                                <Link to={`/venues/${activeLocation.slug}`}>{activeLocation.name}</Link>
                            }
                            events={activeLocation.events}
                            myList={this.props.myList}
                            toggleFavorite={() => {}}
                        />
                    </EventsSliderList>
                }
            </div>
        );
    }
}
