
import React from 'react';
import * as ReactRedux from 'react-redux';
import { Helmet } from 'react-helmet';

import Navigation from './Navigation.js';
import NavigationSimple from './NavigationSimple.js';
import Footer from './Footer.js';
import * as Router from 'react-router';

import SitesService, { getLocation } from '../../services/SitesService.js';
import PopUp from './PopUp.js';


const site = new SitesService().getSite(getLocation());

const helmet =
    <Helmet
        titleTemplate={`%s - Here & Now in ${site.title}`}
        defaultTitle="Here & Now"
    >
        <link rel="canonical" href={typeof window !== 'undefined' ? window.location.href : ''}/>
        <link rel="sitemap" type="application/xml"
            title="Sitemap Here & Now" href={`/backend/sitemap.xml?site_id=${site.id}`}
        />
    </Helmet>;

// Layout for unauthenticated users
@Router.withRouter
@ReactRedux.connect(
    state => ({
        requests: state.getIn(['requests']),
        alert: state.getIn(['components', 'alert']),
    }),
)
export class LayoutPublic extends React.Component {
    isLoading() {
        const pendingRequests = this.props.requests.filter(req => req.status === 'pending');
        return pendingRequests.size > 0;
    }
    
    hasError() {
        const failedRequests = this.props.requests.filter(req => req.status === 'error');
        return failedRequests.size > 0;
    }
    
    componentDidMount() {
        const { dispatch, history } = this.props;
        history.listen((location) => {
            dispatch({ type: 'alert', alert: undefined });
        });
    }
    
    render() {
        const { alert, dispatch, history } = this.props;
        
        return (
            <div className={`${this.isLoading() ? 'loading' : ''} ${this.hasError() ? 'error' : ''}`}>
                {helmet}
                
                {alert && alert(dispatch)}
                <Navigation/>
                
                    {this.props.children}
                <PopUp/>
                <Footer/>
            </div>
        );
    }
}

// Layout for onboarding pages
@Router.withRouter
@ReactRedux.connect(
    state => ({
        requests: state.getIn(['requests']),
    }),
)
export class LayoutOnboarding extends React.Component {
    isLoading() {
        const pendingRequests = this.props.requests.filter(req => req.status === 'pending');
        return pendingRequests.size > 0;
    }
    
    hasError() {
        const failedRequests = this.props.requests.filter(req => req.status === 'error');
        return failedRequests.size > 0;
    }
    
    render() {
        return (
            <div className={`${this.isLoading() ? 'loading' : ''} ${this.hasError() ? 'error' : ''}`}>
                {helmet}
                
                <NavigationSimple/>
                {this.props.children}
            </div>
        );
    }
}
