
import { matchType as match } from '@mkrause/match';
import { update as updateApi } from '../util/api/Api.js';
import updateSession from './session/session.js';
import { updateOnboarding } from './user/Onboarding.js';
import updateNotification from '../services/reducer/NotificationReducer.js';


// General, application-wide reducer
const reduceApp = (state, action) => match(action, {
    [match.default]: state,
    
    'site.switch': ({ site }) => {
        return state.setIn(['app', 'site'], site);
    },
});


export default [
    reduceApp,
    updateApi,
    updateSession,
    updateOnboarding,
    updateNotification,
];
