
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';


/*
Relevant:
http://blog.mgechev.com/2017/01/30/implementing-dependency-injection-react-angular-element-injectors
*/


// Decorator: inject the given services as props

export default services => Component => {
    const serviceTypes = _.reduce(services, (acc, serviceId, propName) =>
        ({ ...acc, [serviceId]: PropTypes.any }), {}
    );
    
    return class Inject extends React.Component {
        static contextTypes = serviceTypes;
        
        render() {
            const serviceProps = _.mapValues(services, serviceId => {
                const service = this.context[serviceId];
                
                if (_.isUndefined(service) || _.isNull(service)) {
                    throw new Error(`No such service '${serviceId}'`);
                }
                
                return service;
            });
            return <Component {...serviceProps} {...this.props}/>;
        }
    };
};
