
import * as _ from 'lodash';
import uuid from 'uuid';
import { Record } from 'immutable';

import React from 'react';
import PropTypes from 'prop-types';
import * as ReactRedux from 'react-redux';
import { Helmet } from 'react-helmet';

import api from '../../services/CpgApi.js';
import { matchType as match } from '@mkrause/match';
import cx from 'classnames';
import { updateRequest } from '../../util/api/request_util.js';
import agent from '../../services/CpgAgent.js';
import * as Router from 'react-router';
import { initializeAuth } from '../session/session.js';


export const favorite = categories => dispatch => {
    const requestId = uuid();
    dispatch({ type: 'onboarding.favorite', requestId, status: 'pending'});
    
    return agent.post(`/user/onboarding`)
        .send({'categories': JSON.stringify(categories)})
        .then(request => {
            dispatch({ type: 'onboarding.favorite', requestId, status: 'ready', value: request.body })
        })
        .catch(reason => {
            dispatch({ type: 'onboarding.favorite', requestId, status: 'error', reason: (reason.hasOwnProperty('response')) ? reason.response.body.msg : reason });
            throw reason;
        });
};

export const updateOnboarding = (state, action) => match(action, {
    [match.default]: state,
    
    'onboarding.toggleFavoriteCategory': ({ categoryId }) =>
        state.updateIn(['app', 'categories', categoryId, 'favorite',], (favorite) => favorite ? !favorite : true),
    
    'onboarding.toggleFavoriteSubCategory': ({ categoryId, subCategoryId }) =>
        state.updateIn(['app', 'categories', categoryId, 'children', subCategoryId, 'favorite',], (favorite) => favorite ? !favorite : true),
    
    'onboarding.changePhase' : ({ phase }) =>
        state.setIn(['components', 'onboarding', 'phase'], phase),
    
    'onboarding.favorite': ({ status, requestId, value, reason }) => {
        const updatedState = (status === "ready")
            ? state.setIn(
                ['session', 'auth'],
                initializeAuth({ user: value, token: state.getIn(['session', 'auth', 'token'])}, status))
            : state;
        return updateRequest({ state: updatedState, requestId, status, reason });
    },
});


@ReactRedux.connect(
    state => ({
        categories: state.getIn(['app', 'categories']),
        phase: state.getIn(['components', 'onboarding', 'phase'])
    })
)
export default class Onboarding extends React.PureComponent {
    componentDidMount = () =>
        this.props.dispatch(api.categories.list({}));        
    
    changePhase = (phase) =>
        this.props.dispatch({type: 'onboarding.changePhase', phase});
    
    render() {
        const {phase, categories, dispatch} = this.props;
        let page;
        if (phase === 0)
            page = (
                <OnboardingCategory
                    categories={categories}
                    dispatch={dispatch}
                    changePhase={(phase) => this.changePhase(phase)}
                />
            );
        else {
            page = (
                <OnboardingSub
                    categories={categories}
                    dispatch={dispatch}
                    changePhase={(phase) => this.changePhase(phase)}
                />
            );
        }
        return (
            <div className="container onboarding-form-container vertical-align">
                {page}
            </div>
        )
    }
}

@Router.withRouter
export class OnboardingCategory extends React.PureComponent {
    favorite = (categoryId, amount, favorite) =>
        (amount < 3 || favorite) ? this.props.dispatch({type: 'onboarding.toggleFavoriteCategory', categoryId}) : undefined;
    
    render() {
        const { categories, changePhase } = this.props;
        const mainCategories = categories.filter((category) => category.get('children').size !== 0);
        const amount = categories ? categories.filter((category) => category.get('favorite')).size : 0;
        return (
            <div className="row">
                <Helmet>
                    <body className="page-onboarding"/>
                    <meta name="description" content="Your interests"/>
                    <title>Your interests</title>
                </Helmet>
                
                <div className="well well-white col-sm-10 col-sm-offset-1 col-md-8 col-md-offset-2">
                    <h1>Please choose <span className="txt-bold text-primary">3</span> categories</h1>
                    
                    <div className='row margin40T'>
                        { categories.status === 'pending' &&
                            <h3> Loading... </h3>
                        }
                        { mainCategories && mainCategories.map((category, categoryId) =>
                            <div className='col-xs-6 col-sm-4' key={categoryId}>
                                <button
                                   className={cx({
                                       'btn btn-default btn-block btn-lg btn-category': true,
                                       'btn-primary selected': category.favorite,
                                       'disabled': (amount === 3 && !category.favorite)
                                   })}                                   
                                   onClick={() => this.favorite(categoryId, amount, category.favorite)}>
                                   <span>{ category.name }</span>
                                </button>
                            </div>
                        ).toArray()}
                    </div>
                    <div className="clearfix">
                        <button className='btn btn-black pull-right' onClick={() => {if (amount === 3) changePhase(1)}} disabled={amount !== 3}>
                            {amount !== 3
                                ? `Choose ${3 - amount} more`
                                :
                                    <span>
                                        Next
                                        <span className="icon icon-arrow-right" aria-hidden="true" aria-label="Arrow right icon"/>
                                    </span>
                            }
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}
@Router.withRouter
export class OnboardingSub extends React.PureComponent {
            
    componentDidMount() {
        document.body.classList.add('page-onboarding');
    }
    
    componentWillUnmount() {
        document.body.classList.remove('page-onboarding');
    }
    
    done(categories) {
        const { dispatch, history } = this.props;
        const favorites = _.values(categories.map((category) =>
            category.get('children').entries.filter((child) => child.get('favorite')).map((child) => child.get('id'))
        ).flatten().toJS());
        
        const result = dispatch(favorite(favorites));
        result.then((result) => {
            history.push('/events')
        });
    }
    render() {
        const { categories, dispatch, changePhase } = this.props;
        
        const favoriteCategories = categories ? categories.filter((category) => category.get('favorite')) : undefined;
        const amount = favoriteCategories ? favoriteCategories.flatMap((category) => category.get('children').entries).filter((subcategory) => {
            return subcategory.favorite
        }).size : 0;
        return (
            
            <div className="row">
                <Helmet>
                    <body className="page-onboarding"/>
                    <meta name="description" content="Your interests"/>
                    <title>Your interests</title>
                </Helmet>
                
                <div className="well well-white col-sm-10 col-sm-offset-1 col-md-8 col-md-offset-2">
                    <h1>What are you interested in?</h1>
                    <div className='row margin40T'>
                        { favoriteCategories && favoriteCategories.map((genre, genreId) =>
                            <OnboardingRow
                                key={genreId}
                                genreId={genreId}
                                genre={genre}
                                dispatch={dispatch}
                                disabled={false}
                            />
                        ).toArray()}
                    </div>
                    <div className="clearfix">
                        <button className='btn btn-link pull-left' onClick={() => changePhase(0)}>
                            <span className="icon icon-arrow-left" aria-hidden="true" aria-label="Arrow left icon"></span>
                            <span>Back</span>
                        </button>
                        <button className='btn btn-black pull-right'
                             onClick={() => {if (amount !== 0) this.done(categories)}} 
                             disabled={amount === 0}
                         >
                            <span className="icon icon-check" aria-hidden="true" aria-label="Arrow right icon"></span>
                            <span>Done</span>
                        </button>
                    </div>
                </div>
            </div>
        
        )
    }
}


export class OnboardingRow extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            amount: 6,
        }
    }
    
    favorite = (categoryId, subCategoryId) =>
        this.props.dispatch({type: 'onboarding.toggleFavoriteSubCategory', categoryId, subCategoryId});
    
    toggle = () => this.setState((prev) => {
        return {show: !prev.show}
    });
    
    render() {
        const {genre, genreId, disabled, changePhase} = this.props;
        const {show, amount} = this.state;
        const children = genre.get('children').entries;
        const shownChildren = show ? children : children.take(amount);
        return (
            <div className="col-xs-12">
                <h4 className={cx({'text-muted': disabled})}><strong>{ genre.get('name') }</strong></h4>
                <div className='row'>
                    { shownChildren && shownChildren.map((category, categoryId) =>
                        <div className='col-xs-6 col-sm-4' key={categoryId}>
                            <button
                                className={cx({
                                    'btn btn-default btn-block btn-md btn-category': true,
                                    'btn-primary selected': category.favorite
                                })}
                                disabled={!genre.favorite || disabled}
                                onClick={() => this.favorite(genreId, categoryId)}
                            >
                                <span>{ category.get('name') }</span>
                            </button>
                        </div>
                    ).toArray()}
                    { children && children.size > amount &&
                    <div className="col-xs-12 btn-show-more-wrapper text-center">
                        <button className='btn btn-sm btn-link btn-show-more' onClick={() => this.toggle()}>
                            <i
                                className={cx({
                                    "icon": true,
                                    "icon-arrow-down": !show,
                                    "icon-arrow-up": show
                                })}
                                onClick={() => changePhase(1)}
                            /> Show more
                        </button>
                    </div>
                    }
                </div>
            </div>
        )
    }
}
