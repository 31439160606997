
import _ from 'lodash';
import moment from 'moment';

export const currencyFromCents = cents => {
    return (cents / 100).toLocaleString('en-EN', { minimumFractionDigits: 2 });
};

// Param: `date` : `moment`
export const formatDate = date => {
  const isToday = moment().isSame(date, 'day');
  if (isToday) {
    return 'Today';
  }
  
  const isSameYear = moment().isSame(date, 'year');
  if (isSameYear) {
    return date.format('ddd Do MMMM');
  } else {
    return date.format('ddd Do MMMM YYYY');
  }
};

// Format the start date of a date range (allows us to provide slightly more intelligent formatting based on range)
// Param: `startDate` : `moment`
// Param: `endDate` : `moment`
export const formatStartDate = (startDate, endDate, recurringLabel) => {
  if (recurringLabel !== undefined && recurringLabel !== null && recurringLabel.length > 0) {
    return recurringLabel;
  }
  if (!endDate) {
    return formatDate(startDate);
  }
  
  // Are we in the midst of the range?
  if (startDate.isSameOrBefore(moment(), 'day') && endDate.isSameOrAfter(moment(), 'day')) {
    const isOneDayEvent = startDate.isSame(endDate, 'day');
    
    // Special case: if this is a two-day event, then we want to show "Today"
    // instead of "Ongoing", e.g. to handle cases where the end time is after midnight
    const isTwoDayEvent = startDate.add(1, 'day').isSame(endDate, 'day');
    
    if (isOneDayEvent || isTwoDayEvent) {
      return 'Today';
    } else {
      return 'Ongoing';
    }
  } else {
    // We are not in the range, and past events are filtered out, so this must be a date in the future
    return formatDate(startDate);
  }
};

// Param: `date` : `moment`
export const formatDateTime = date => {
  const isToday = moment().isSame(date, 'day');
  if (isToday) {
    return 'Today at ' + date.format('HH:mm');
  }
  
  const isSameYear = moment().isSame(date, 'year');
  if (isSameYear) {
    return date.format('ddd Do MMMM HH:mm');
  } else {
    return date.format('ddd Do MMMM YYYY HH:mm');
  }
};

// FIXME: move implementation of recurring until date to static function of event, like Event.getEndTime(),
// Event.getLabel(), Event.getStartTime(), so this code can stay generic.
//
// Param: `startTime` : `moment`
// Param: `endTime` : `moment`
// Param: `recurring` : `object`, optional, properties: recurring_until, recurring_label, recurring_interval
export const formatDateTimeRange = (startTime, endTime, recurring) => {
  let range = '';
  
  // For recurring events, we override our end time with what's basically the end time of our parent event.
  if (_.isObject(recurring) && _.hasIn(recurring, 'recurring_until') && _.isObject(recurring.recurring_until)) {
    if (recurring.recurring_until.constructor.name === 'Moment') {
      if (recurring.recurring_until.isValid()) {
        endTime = recurring.recurring_until;
      }
    }
  }
  
  // For recurring events, prepend our output with the recurring interval in human readable format
  if (_.isObject(recurring) && _.hasIn(recurring, 'recurring_interval')) {
    if (_.isObject(recurring.recurring_interval) && _.hasIn(recurring.recurring_interval, 'label')) {
      let recurringLabel = recurring.recurring_interval.label;
      range = recurringLabel.charAt(0).toUpperCase() + recurringLabel.slice(1) + ' from ';
    }
  }
  
  const isCurrentDay = moment().isSame(startTime, 'day');
  const isCurrentYear = moment().isSame(startTime, 'year');
  const isSameDay = startTime.isSame(endTime, 'day');
  const isSameYear = startTime.isSame(endTime, 'year');
  
  if (isSameDay && isCurrentDay) {
    if (!endTime) {
      range += 'Today at ' + startTime.format('HH:mm');
    } else {
      range += 'Today at ' + startTime.format('HH:mm') + ' – ' + endTime.format('HH:mm');
    }
  } else if (isSameDay) {
    if (!endTime) {
      range += startTime.format('ddd Do MMMM HH:mm');
    } else {
      range += startTime.format('ddd Do MMMM HH:mm') + ' – ' + endTime.format('HH:mm');
    }
  } else if (isSameYear && isCurrentYear) {
    if (!endTime) {
      range += startTime.format('ddd Do MMMM HH:mm');
    } else {
      range += startTime.format('ddd Do MMMM HH:mm') + ' – ' + endTime.format('ddd Do MMMM HH:mm');
    }
  } else {
    // Fallback: show full date range
    if (!endTime) {
      range += startTime.format('ddd Do MMMM YYYY HH:mm');
    } else {
      range += startTime.format('ddd Do MMMM YYYY HH:mm') + ' – ' + endTime.format('ddd Do MMMM YYYY HH:mm');
    }
  }
  
  return range;
};
