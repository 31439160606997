import React from 'react';

// TODO: inject EventGroups and hide buttons if no events are found in the corresponding event group
export default class SimpleCitiesFilterDrenthe extends React.PureComponent {
    goToAnchor(anchorName) {
        if (typeof document !== 'undefined') {
            try {
                document.getElementById(anchorName).scrollIntoView(true, { behavior: 'smooth'});
            } catch(err) {
                // This usually occurs because there are no upcoming events for the given city
                console.log(`No events found for the slider with ID "${anchorName}"`);
                // console.log(err);
            }
        }
    }

    render() {
        return (
            <div className="container-fluid margin20B">
                <div className="row cities-filter drenthe">
                    <div className="col-xs-12 hidden-xs">
                        <div className="buttons btn-group">
                            <a onClick={() => { this.goToAnchor('assen'); }} className="btn btn-primary">Assen</a>
                            <a onClick={() => { this.goToAnchor('emmen'); }} className="btn btn-primary">Emmen</a>
                            <a onClick={() => { this.goToAnchor('hoogeveen'); }} className="btn btn-primary">Hoogeveen</a>
                            <a onClick={() => { this.goToAnchor('meppel'); }} className="btn btn-primary">Meppel</a>
                        </div>
                    </div>
                    <div className="col-xs-6 visible-xs padding20B">
                        <a onClick={() => { this.goToAnchor('assen'); }} className="btn btn-primary btn-block btn-md">Assen</a>
                    </div>
                    <div className="col-xs-6 visible-xs padding20B">
                        <a onClick={() => { this.goToAnchor('emmen'); }} className="btn btn-primary btn-block btn-md">Emmen</a>
                    </div>
                    <div className="col-xs-6 visible-xs padding20B">
                        <a onClick={() => { this.goToAnchor('hoogeveen'); }} className="btn btn-primary btn-block btn-md">Hoogeveen</a>
                    </div>
                    <div className="col-xs-6 visible-xs padding20B">
                        <a onClick={() => { this.goToAnchor('meppel'); }} className="btn btn-primary btn-block btn-md">Meppel</a>
                    </div>
                </div>
            </div>
        )
    }
}
