
import PropTypes from 'prop-types';
import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import * as Router from 'react-router';
import * as ReactRedux from 'react-redux';

import { convertPath } from '../../services/model/ImageService.js';
import * as session from './session.js';
import { backendUrl } from '../../services/CpgAgent.js';
import * as _ from 'lodash';
import { getURLParameter } from '../../services/model/UrlService.js';
import SitesService from '../../services/SitesService.js';

@Router.withRouter
@ReactRedux.connect(
    state => ({
        requests: state.getIn(['requests']),
        requestId: state.getIn(['actions', 'login', 'requestId']),
    }),
    dispatch => ({
        logIn: credentials => dispatch(session.logIn(credentials)),
        authenticate: auth => dispatch(session.authenticate(auth))
    }),
)
export default class LoginForm extends React.PureComponent {
    static propTypes = {
        history: PropTypes.object.isRequired,
        logIn: PropTypes.func.isRequired,
    };
    
    componentDidMount() {
        const{authenticate, history} = this.props;
        const params = getURLParameter(window.location.search);
        if(!_.isEmpty(params)) {
            // Change the String into a boolean
            params.on_board = (params.on_board === "1");
            authenticate({auth: {user: params, token: params.token}}).then(() => history.push('/'))
        }
    }
    
    state = {
        username: "",
        password: "",
        errorMessage: null,
    };
    
    validate = ({ username, password }) => {
        if (!username || !password) {
            const message = "Please fill in a username and password";
            this.setState({ errorMessage: message });
            return false;
        } else {
            this.setState({ errorMessage: null });
            return true;
        }
    };
    
    handleLogin = ({ type }) => {
        const { requests, requestId, history } = this.props;
        const request = requests.get(requestId);
        
        switch (type) {
            case 'login':
                if (request && request.status === 'pending') {
                    // Don't submit again
                    return Promise.resolve();
                }
                
                const loginRequest = { username: this.state.username, password: this.state.password };
                if (!this.validate(loginRequest)) {
                    return;
                }
                return this.props.logIn(loginRequest)
                    .then(() => { this.props.history.push('/'); })
                    .catch(() => {
                        this.setState({ errorMessage: "The combination of username/password is invalid" });
                    });
            case 'facebook':
                window.location.href = `${backendUrl}/oauth/facebook?return_url=${window.location.href}`;
                return Promise.resolve();
            case 'google':
                throw new Error('TODO');
        }
    };
    
    render() {
        const { requests, requestId } = this.props;
        const request = requests.get(requestId);
        const status = request ? request.status : 'open';
        const site = new SitesService().getSite(window.location);

        return (
            <div className="container vertical-align login-form-container">
                <Helmet>
                    <body className="page-login"/>
                    <title>Log in</title>
                    <meta name="description" content="Log in"/>
                </Helmet>
                
                <div className="row">
                    <div className="well well-white col-sm-10 col-sm-offset-1 col-md-8 col-md-offset-2 col-xl-6 col-xl-offset-3">
                        <div className="row">
                            <div className="col-sm-6">
                                <h1>Login</h1>
                                {site.name === 'groningen' &&
                                    <p className="h4">Start exploring the great city of Groningen now!</p>
                                }
                                {site.name !== 'groningen' &&
                                    <p className="h4">Start exploring {site.title} now!</p>
                                }
                                <p>Don't have an account? Registration is free! <Link to={`/register/`} className="visible-xs-inline visible-ms-inline">Register</Link></p>
                                <Link to={`/register/`} className="btn btn-black hidden-xs hidden-ms">Register</Link>
                            </div>
                            <div className="col-sm-6">
                                <button className="btn btn-primary btn-lg btn-block" disabled={status === 'pending'}
                                    onClick={() => { this.handleLogin({ type: 'facebook' }); }}
                                >                                    
                                    <span className="icon icon-social-facebook" aria-hidden="true" aria-label="Facebook logo"></span>
                                    Sign in with Facebook
                                </button>
                                <p className="margin10T margin10B text-muted txt-sm">
                                    or login with your credentials:
                                </p>
                                <form onSubmit={evt => { evt.preventDefault(); this.handleLogin({ type: 'login' }); }}>
                                    {this.state.errorMessage !== null &&
                                        <div className="alert alert-danger" role="alert">
                                            <strong>Error:</strong>
                                            {' '}
                                            {this.state.errorMessage}
                                        </div>
                                    }
                                    <div className="form-group">
                                        <label className="sr-only">Email</label>
                                        <div className="input-group">
                                            <div className="input-group-addon">
                                                <span className="icon icon-user" aria-hidden="true" aria-label="User icon"></span>
                                            </div>                                        
                                            <input id="email" type="email" placeholder="Email" required
                                                className="form-control"
                                                value={this.state.username}
                                                onChange={evt => { this.setState({ username: evt.target.value }); }}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label className="sr-only">Password</label>
                                        <div className="input-group">
                                            <div className="input-group-addon">
                                                <span className="icon icon-lock" aria-hidden="true" aria-label="Lock icon"></span>
                                            </div>    
                                            <input id="password" type="password" placeholder="Password" required
                                                className="form-control"
                                                value={this.state.password}
                                                onChange={evt => { this.setState({ password: evt.target.value }); }}
                                            />
                                        </div>
                                    </div>
                                    
                                    <button type="submit" className="btn btn-default btn-block" disabled={status === 'pending'}
                                        onClick={() => { this.handleLogin({ type: 'login' }); }}
                                    >
                                        Log in
                                    </button>
                                    <p className="txt-right margin0B margin10T txt-sm">
                                        <a href='/backend/resetting/request'>Forgot password?</a>
                                    </p>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
    
                {site.name === 'groningen' &&            
                    <div className="bg-slideshow">
                      <div
                        className="slideshow-image lazyload" 
                        data-bgset={
                            `${convertPath('uploads/background-5638897642-80f2849aa1-o-resize.jpg')}&w=1000 [(max-width: 992px)] | ${convertPath('uploads/background-5638897642-80f2849aa1-o-resize.jpg')}&w=1920` 
                            }
                        data-sizes="auto"
                        ></div>
                      <div
                        className="slideshow-image lazyload" 
                        data-bgset={
                            `${convertPath('uploads/background-8447480071-c2f45ca864-o-resize.jpg')}&w=1000 [(max-width: 992px)] | ${convertPath('uploads/background-8447480071-c2f45ca864-o-resize.jpg')}&w=1920` 
                            }
                        data-sizes="auto"
                        ></div>
                      <div
                        className="slideshow-image lazyload" 
                        data-bgset={
                            `${convertPath('uploads/background-14602982626-bac4827656-o-resize.jpg')}&w=1000 [(max-width: 992px)] |  ${convertPath('uploads/background-14602982626-bac4827656-o-resize.jpg')}&w=1920` 
                            }
                        data-sizes="auto"
                        ></div>
                      <div
                        className="slideshow-image lazyload" 
                        data-bgset={
                            `${convertPath('uploads/background-6825288947-bf8cf32c9d-o-resize.jpg')}&w=1000 [(max-width: 992px)] | ${convertPath('uploads/background-6825288947-bf8cf32c9d-o-resize.jpg')}&w=1920` 
                            }
                        data-sizes="auto"
                        ></div>
                    </div>
                }
                
                {site.name === 'friesland' &&            
                    <div className="bg-slideshow">
                      <div
                        className="slideshow-image lazyload" 
                        data-bgset={
                            `${convertPath('uploads/background-friesland-2919685656.jpg')}&w=1000 [(max-width: 992px)] | ${convertPath('uploads/background-friesland-2919685656.jpg')}&w=1920` 
                            }
                        data-sizes="auto"
                        ></div>
                      <div
                        className="slideshow-image lazyload" 
                        data-bgset={
                            `${convertPath('uploads/background-friesland-3064709434.jpg')}&w=1000 [(max-width: 992px)] | ${convertPath('uploads/background-friesland-3064709434.jpg')}&w=1920` 
                            }
                        data-sizes="auto"
                        ></div>
                      <div
                        className="slideshow-image lazyload" 
                        data-bgset={
                            `${convertPath('uploads/background-friesland-2742803650.jpg')}&w=1000 [(max-width: 992px)] |  ${convertPath('uploads/background-friesland-2742803650.jpg')}&w=1920` 
                            }
                        data-sizes="auto"
                        ></div>
                    </div>
                }

                {site.name === 'drenthe' &&            
                    <div className="bg-slideshow">
                      <div
                        className="slideshow-image lazyload" 
                        data-bgset={
                            `${convertPath('uploads/background-assen.jpg')}&w=1000 [(max-width: 992px)] | ${convertPath('uploads/background-assen.jpg')}&w=1920` 
                            }
                        data-sizes="auto"
                        ></div>
                      <div
                        className="slideshow-image lazyload" 
                        data-bgset={
                            `${convertPath('uploads/background-emmen.jpg')}&w=1000 [(max-width: 992px)] | ${convertPath('uploads/background-emmen.jpg')}&w=1920` 
                            }
                        data-sizes="auto"
                        ></div>
                      <div
                        className="slideshow-image lazyload" 
                        data-bgset={
                            `${convertPath('uploads/background-emmen-2.jpg')}&w=1000 [(max-width: 992px)] |  ${convertPath('uploads/background-emmen-2.jpg')}&w=1920` 
                            }
                        data-sizes="auto"
                        ></div>                        
                    </div>
                }
            </div>
        );
    }
}
