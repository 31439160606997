
import _ from 'lodash';
import Imm from 'immutable';

import React from 'react';


// Wrapper around Immutable.OrderedMap(), with additional functionality. Represents a collection of
// entities, indexed by their ID. Includes API meta information, like whether the collection has
// been correctly loaded or not.
export default class Collection {
    type = null;
    entries = Imm.OrderedMap();
    meta = {
        status: 'invalid',
    };
    
    constructor(type, meta = {}, entries = {}) {
        this.type = type;
        
        this.entries = Imm.OrderedMap(entries);
        Object.assign(this.meta, meta);
    }
    
    hasStatus = (status) => this.meta.status === status;
    
    withEntries(entries) {
        return new Collection(this.type, this.meta, entries);
    }
    withStatus(status) {
        return new Collection(this.type, { ...this.meta, status }, this.entries);
    }
    
    query(criteria) {
        //TODO
        // items = items.sort((a, b) => {
        //     return a.getIn(['user', 'name']).localeCompare(b.getIn(['user', 'name']));
        // });
        
        return this;
    }
    
    _apply(fnName, ...args) { return new Collection(this.type, this.meta, this.entries[fnName](...args)); }
    map(...args) { return this.entries.map(...args); }
    flatMap(...args) { return this.entries.flatMap(...args); }
    filter(...args) { return this.entries.filter(...args); }
    get size() { return this.entries.size; }
    has(...args) { return this.entries.has(...args); }
    get(...args) { return this.entries.get(...args); }
    
    getIn(...args) { return this.entries.getIn(...args); }
    keySeq(...args) { return this.entries.keySeq(...args); }
    valueSeq(...args) { return this.entries.valueSeq(...args); }
    entrySeq(...args) { return this.entries.entrySeq(...args); }
    first(...args) { return this.entries.first(...args); }
    
    isEmpty() { return this.entries.isEmpty(); }
    
    sort(...args) { return this._apply('sort', ...args); }
    sortBy(...args) { return this._apply('sortBy', ...args); }
    take(...args) { return this._apply('take', ...args); }
    rest(...args) { return this._apply('rest', ...args); }
    
    toArray(...args) { return this.entries.toArray(...args); }
    toObject(...args) { return this.entries.toObject(...args); }
    toJS(...args) { return this.entries.toJS(...args); }
    
    *[Symbol.iterator]() {
        for (let entry of this.entries) {
            yield entry;
        }
    };
    
    set(...args) { return this._apply('set', ...args); }
    setIn(...args) { return this._apply('setIn', ...args); }
    update(...args) { return this._apply('update', ...args); }
    updateIn(...args) { return this._apply('updateIn', ...args); }
    
    remove(...args) { return this._apply('remove', ...args); }
    removeIn(...args) { return this._apply('removeIn', ...args); }
    
    mergeDeepWith(...args) { return this._apply('mergeDeepWith', ...args); }
    mergeWith(...args) { return this._apply('mergeWith', ...args); }
    merge(...args) { return this._apply('merge', ...args); }
    // Map this collection to an array of React elements
    mapToElements(fn) {
        return this
            .map((item, index) => {
                const reactElement = fn(item, index);
                if (!reactElement.props.hasOwnProperty('key')) {
                    return React.cloneElement(reactElement, { key: index });
                } else {
                    return reactElement;
                }
            })
            .toArray();
    }
    
    toJSON() {
        return this.entries.toJSON();
    }
    
    toString() {
        const stringRepr = this.entries.toString();
        
        const prefix = `Collection(${this.type.name})`;
        return stringRepr.replace(/^[\w\.]*/, prefix);
    }
}
