
import _ from 'lodash';

const localizationApi = (localizations, locale = 'en-en', selection = []) => {
    if (!localizations.hasOwnProperty(locale)) {
        throw new Error(`No localization found for locale "${locale}"`);
    }
    
    const localization = localizations[locale];
    
    const parsePath = path => {
        if (_.isString(path)) {
            return path.split('.');
        }
        return path;
    };
    
    const accessor = (pathRelative, defaultString) => {
        const path = [...selection, ...parsePath(pathRelative)];
        
        let langString = null;
        if (_.has(localization, path)) {
            langString = _.get(localization, path);
        } else if (!_.isUndefined(defaultString)) {
            langString = defaultString;
        } else {
            throw new Error(`No such language string at "${path.join('.')}"`);
        }
        
        return langString;
    };
    
    Object.assign(accessor, {
        has: pathRelative => {
            const path = [...selection, ...parsePath(pathRelative)];
            return _.has(localization, path);
        },
        get: () => {
            return _.get(localization, selection);
        },
        select: pathRelative => {
            const path = [...selection, ...parsePath(pathRelative)];
            return localizationApi(localizations, locale, path)
        },
    });
    
    return accessor;
};

export default localizationApi;
