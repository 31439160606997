
import PropTypes from 'prop-types';
import React from 'react';
import * as Router from 'react-router';

import * as ReactRedux from 'react-redux';


@Router.withRouter
@ReactRedux.connect(
    state => ({
        auth: state.getIn(['session', 'auth']),
    }),
)
export default class OnboardedRoute extends React.PureComponent {
    render() {
        const { auth, history, ...routeProps } = this.props;
        
        // Redirect the user to the onboarding page as long as onboarding has not been completed
        if (auth && !auth.get('user').on_board) {
            if (history.location.pathname !== '/user/onboarding') {
                return <Router.Redirect to="/user/onboarding"/>;
            }
        }
        
        return <Router.Route {...routeProps}/>;
    }
}
