
import agent from '../CpgAgent.js';
import Collection from '../../util/models/Collection.js';
import Event from '../../models/Event.js';
import { makeEntry } from '../../util/api/collection_util.js';
import EventGroup from '../../models/EventGroup.js';
import Image from '../../models/Image.js';
import uri from 'uri-tag';

export const getHome = (eventId) => {
    
    const endpoint = (eventId) ?  uri`/home/event/${eventId}` : uri`/home`;
    return agent.get(endpoint)
        //.query({ limit: 50 })
        .then(response => {
            const eventGroupsAsArray = response.body;
            
            const eventGroups = new Collection(EventGroup, { status: 'ready' }, eventGroupsAsArray.map(eventGroup => {
                const events = new Collection(Event, { status: 'ready' }, eventGroup.events.map(event => {
                    const images = new Collection(Image, { status: 'ready' }, event.images.map(image => {
                        return makeEntry(Image, image);
                    }));
                    return makeEntry(Event, { ...event, id: event.slug, images });
                }));
                
                return makeEntry(EventGroup, { ...eventGroup, events, id: eventGroup.slug });
            }));
            
            return { ...response, body: eventGroups };
        });
};

export const getEventGroups = (options) => {
    const endpoint = uri`/event-groups/`;
    return agent.get(endpoint, { limit: options.limit })
        .then(response => {
            const eventGroupsAsArray = response.body;
            
            const eventGroups = new Collection(EventGroup, { status: 'ready' }, eventGroupsAsArray.map(eventGroup => {
                const events = new Collection(Event, { status: 'ready' }, (eventGroup.events) ? eventGroup.events.map(event => {
                    const images = new Collection(Image, { status: 'ready' }, event.images.map(image => {
                        return makeEntry(Image, image);
                    }));
                    return makeEntry(Event, { ...event, images });
                }) : []);
                
                const children = new Collection(EventGroup, { status: 'ready' }, ((eventGroup.children) ? eventGroup.children.map(item => {
                    const childImageEntries = item.images.map(childImage => {
                        return makeEntry(Image, childImage);
                    });
                    const childImages = new Collection(Image, { status: 'ready' }, childImageEntries);
                    return makeEntry(EventGroup, { ...item, id: item.slug, images: childImages});
                }) : []));
                
                return makeEntry(EventGroup, { ...eventGroup, events, children, id: eventGroup.slug });
            }));
            
            return { ...response, body: eventGroups };
        });
};
