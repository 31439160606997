
import React from 'react';

import Schema from '../util/models/schema.js';
import Entity from '../util/models/Entity.js';
import Collection from '../util/models/Collection.js';
import Event from './Event.js';
import Category from './Category';
import Image from './Image';


export default class EventGroup extends Entity {
    static key = { id: Schema.text };
    static schema = {
        id: Schema.integer,
        name: Schema.text,
        description: Schema.text,
        slug: Schema.text,
        disabled: Schema.text,
        link: Schema.text,
        total: Schema.integer,
        events: new Collection(Event),
        children: new Collection(EventGroup),
        images: new Collection(Image),
    };
    
    static iconsCache = {};
    
    constructor(...args) {
        super(EventGroup.schema, ...args);
        
        if (Object.keys(EventGroup.iconsCache).length === 0) {
            // Note: we need to this using a dynamic require, otherwise we break server-sider rendering
            EventGroup.iconsCache = {
                'featured': require('../style/img/svg/icons/category-featured.svg'),
                'my-list': require('../style/img/svg/icons/category-my-list.svg'),
                'my-recommendations': require('../style/img/svg/icons/category-my-recommendations.svg'),
                'this-week': require('../style/img/svg/icons/category-this-week.svg'),
                'music': require('../style/img/svg/icons/category-music.svg'),
                'art-performance': require('../style/img/svg/icons/category-art-performance.svg'),
                'learn-connect': require('../style/img/svg/icons/category-learn-connect.svg'),
                // TEMP: compatibility with older database
                'networking': require('../style/img/svg/icons/category-learn-connect.svg'),
                'community-organized': require('../style/img/svg/icons/category-community-organized.svg'),
                'film': require('../style/img/svg/icons/category-film.svg'),
                'other': require('../style/img/svg/icons/category-other.svg'),
            };
        }
    }
    
    getIcon() {
        if (EventGroup.iconsCache.hasOwnProperty(this.get('slug'))) {
            return (
                <img  className="svg img-responsive category-icon"
                    src={EventGroup.iconsCache[this.get('slug')]}
                />
            );
        }
        
        return null;
    }
}
