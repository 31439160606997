
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import inject from '../../util/di/inject.js';
import SitesService from '../../services/SitesService.js';


@inject({
    sites: 'cpg.sites',
})
export default class Footer extends React.PureComponent {
    
    switchToSiteUnlessAlreadyThere(siteName) {
        const site = new SitesService().getSite(window.location);
        if (site.name !== siteName) {
            this.props.sites.switch({ name: siteName });
        }
    }

    render() {
        const site = new SitesService().getSite(window.location);

        return (
            <footer className="page-footer navbar navbar-static-bottom">
                <div className="page-footer-top">
                    <div className="container-fluid">
                        {site.name === 'groningen' && 
                            <div className="row vertical-align-sm">
                                <div className="col-xs-6 col-sm-4 col-md-2">
                                    <a target="_blank" href="http://www.rug.nl/" className="footer-link">
                                        <img className="img-responsive" alt="Logo University of Groningen"
                                            src={require('../../style/img/footer-rug.png')}
                                        />
                                    </a>
                                </div>
                                <div className="col-xs-6 col-sm-4 col-md-2">
                                    <a target="_blank" href="https://www.hanze.nl/eng" className="footer-link">
                                        <img className="img-responsive" alt="Logo Hanze University Groningen"
                                            src={require('../../style/img/footer-hanze.png')}
                                        />
                                    </a>
                                </div>
                                <div className="col-xs-6 col-sm-4 col-md-2">
                                    <a target="_blank" href="https://northsearegion.eu/" className="footer-link footer-link-lg">
                                        <img className="img-responsive" alt="Logo Interreg North Sea Region"
                                            src={require('../../style/img/footer-interreg.png')}
                                        />
                                    </a>
                                </div>
                                <div className="col-xs-6 col-sm-4 col-md-2">
                                    <a target="_blank" href="https://www.umcg.nl/EN/corporate/paginas/default.aspx" className="footer-link">
                                        <img className="img-responsive" alt="Logo UMCG Groningen"
                                            src={require('../../style/img/footer-umcg.png')}
                                        />
                                    </a>
                                </div>
                                <div className="col-xs-6 col-sm-4 col-md-2">
                                    <a target="_blank" href="https://gemeente.groningen.nl/" className="footer-link">
                                        <img className="img-responsive" alt="Logo City of Groningen"
                                            src={require('../../style/img/footer-gemeente.png')}
                                        />
                                    </a>
                                </div>
                                <div className="col-xs-6 col-sm-4 col-md-2">
                                    <a href="https://www.provinciegroningen.nl/servicelinks-provincie-groningen/english/" target="_blank" className="footer-link">
                                        <img className="img-responsive" alt="Logo Province Groningen"
                                            src={require('../../style/img/footer-provincie.png')}
                                        />
                                    </a>
                                </div>
                            </div>
                        }
                        
                        {site.name === 'friesland' &&
                            <div className="row vertical-align-sm">
                                <div className="col-xs-6 col-sm-4 col-md-2">
                                    <a target="_blank" href="https://iwcn.nl/" className="footer-link">
                                        <img className="img-responsive" alt="Logo International Welcome Center North"
                                            src={require('../../style/img/footer-iwcn.png')}
                                        />
                                    </a>
                                </div>
                                <div className="col-xs-6 col-sm-4 col-md-2">
                                    <a target="_blank" href="https://www.leeuwarden.nl/" className="footer-link">
                                        <img className="img-responsive" alt="Logo Leeuwarden"
                                            src={require('../../style/img/footer-leeuwarden.png')}
                                        />
                                    </a>
                                </div>
                                <div className="col-xs-6 col-sm-4 col-md-2">
                                    <a target="_blank" href="https://www.leeuwardenstudentcity.com/" className="footer-link">
                                        <img className="img-responsive" alt="Logo Leeuwarden Student City"
                                            src={require('../../style/img/footer-lsc.png')}
                                        />
                                    </a>
                                </div>
                                <div className="col-xs-6 col-sm-4 col-md-2">
                                    <a target="_blank" href="https://www.nhlstenden.com/en" className="footer-link">
                                        <img className="img-responsive" alt="Logo NHL Stenden"
                                            src={require('../../style/img/footer-nhl-stenden.png')}
                                        />
                                    </a>
                                </div>
                                <div className="col-xs-6 col-sm-4 col-md-2">
                                    <a href="https://www.rug.nl/cf/" target="_blank" className="footer-link">
                                        <img className="img-responsive" alt="Logo University of Groningen/Campus Fryslan"
                                            src={require('../../style/img/footer-rug-campus-fryslan.png')}
                                        />
                                    </a>
                                </div>
                                <div className="col-xs-6 col-sm-4 col-md-2">
                                    <a target="_blank" href="http://www.topdutchtalent.com/" className="footer-link">
                                        <img className="img-responsive" alt="Logo Top Dutch Talent"
                                            src={require('../../style/img/footer-top-dutch-talent.png')}
                                        />
                                    </a>
                                </div>
                            </div>
                        }

                        {site.name === 'drenthe' &&
                            <div className="row vertical-align-sm">
                                <div className="col-xs-6 col-sm-4 col-md-2">
                                <a target="_blank" href="https://www.zero-to.one/" className="footer-link">
                                        <img className="img-responsive" alt="Zero to One"
                                            src={require('../../style/img/footer-zero-one.png')}
                                        />
                                    </a>
                                </div>
                                <div className="col-xs-6 col-sm-4 col-md-2">
                                    <a target="_blank" href="https://www.nhlstenden.com/en" className="footer-link">
                                        <img className="img-responsive" alt="Logo NHL Stenden"
                                            src={require('../../style/img/footer-nhl-stenden.png')}
                                        />
                                    </a>
                                </div>
                                <div className="col-xs-6 col-sm-4 col-md-2">
                                    <a target="_blank" href="https://www.vpb-emmen.nl/" className="footer-link">
                                        <img className="img-responsive" alt="Parkmanagement VPB"
                                            src={require('../../style/img/footer-parkmanagement.png')}
                                        />
                                    </a>
                                </div>
                                <div className="col-xs-6 col-sm-4 col-md-2">
                                    <a target="_blank" href="https://gemeente.emmen.nl/" className="footer-link">
                                        <img className="img-responsive" alt="Gemeente Emmen"
                                            src={require('../../style/img/footer-gemeente-emmen.png')}
                                        />
                                    </a>
                                </div>
                                <div className="col-xs-6 col-sm-4 col-md-2">
                                    <a target="_blank" href="https://www.growingemmen.nl/" className="footer-link">
                                        <img className="img-responsive" alt="Growing Emmen"
                                            src={require('../../style/img/footer-growing-emmen.png')}
                                        />
                                    </a>
                                </div>
                                <div className="col-xs-6 col-sm-4 col-md-2">
                                    <a target="_blank" href="https://iwcn.nl/" className="footer-link">
                                        <img className="img-responsive" alt="International Welcome Center North"
                                            src={require('../../style/img/footer-iwcn.png')}
                                        />
                                    </a>
                                </div>
                                <div className="col-xs-6 col-sm-4 col-md-2">
                                    <a target="_blank" href="https://www.provincie.drenthe.nl/" className="footer-link">
                                        <img className="img-responsive" alt="Provincie Drenthe"
                                            src={require('../../style/img/footer-provincie-drenthe.png')}
                                        />
                                    </a>
                                </div>
                                <div className="col-xs-6 col-sm-4 col-md-2">
                                    <a target="_blank" href="https://www.assen.nl/" className="footer-link">
                                        <img className="img-responsive" alt="Gemeente Assen"
                                            src={require('../../style/img/footer-gemeente-assen.png')}
                                        />
                                    </a>
                                </div>
                            </div>
                        }
                    </div>
                </div>
                <div className="container-fluid page-footer-bottom">
                    <div className="row no-gutters" style={{display: 'flex'}}>
                        <div className="col-xs-4 col-sm-4 col-sm-offset-0 col-md-2 col-md-offset-3">
                            <a className="navbar-brand groningen" title="Here & Now in Groningen"
                                onClick={() => this.switchToSiteUnlessAlreadyThere('groningen')}
                            >
                                <img className="svg img-responsive"
                                    src={require('../../style/img/svg/logo-here-now-groningen.svg')}
                                />
                            </a>
                        </div>
                        <div className="col-xs-4 col-sm-4 col-md-2">
                            <a className="navbar-brand friesland" title="Here & Now in Friesland"
                                onClick={() => this.switchToSiteUnlessAlreadyThere('friesland')}
                            >
                                <img className="svg img-responsive"
                                    src={require('../../style/img/svg/logo-here-now-friesland.svg')}
                                />
                            </a>
                        </div>
                        <div className="col-xs-4 col-sm-4 col-md-2">
                            <span className="navbar-brand drenthe" title="Here & Now in Drenthe">
                                <img className="svg img-responsive"
                                    src={require('../../style/img/svg/logo-here-now-drenthe.svg')}
                                />
                            </span>
                        </div>
                    </div>
                    <div className="footer-container">
                        <Link to="/about" className="footer-nav">About HereAndNow</Link>
                    
                        {site.name === 'groningen' &&
                            <Link to="/about-groningen" className="footer-nav">About Groningen</Link>
                        }
                    
                        <Link to="/submitevent" className="footer-nav">Submit an Event</Link>
                    
                        <Link to="/explore" className="footer-nav">Explore</Link>
                    </div>
                </div>
            </footer>
        );
    }
}
