
import * as _ from 'lodash';
import cx from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { Helmet } from 'react-helmet';

import * as ReactRedux from 'react-redux';
import * as Router from 'react-router';
import inject from '../../util/di/inject.js';
import Collection from '../../util/models/Collection.js';

import EventsSlider from '../events/EventsSlider.js';
import EventsSliderList from '../events/EventsSliderList.js';
import { getHome } from '../../services/api/EventGroupService.js';

@inject({
    responsive: 'cpg.responsive',
    sites: 'cpg.sites'
})
@Router.withRouter
@ReactRedux.connect(
    state => ({
        auth: state.getIn(['session', 'auth']),
        myList: state.app.authUser.eventFavorites,
    }),
    dispatch => ({
        dispatch,
    }),
)
class SubsiteSlider extends React.PureComponent {
    static propTypes = {
        subsiteName: PropTypes.string.isRequired,
        title: PropTypes.node.isRequired,
        subsite: PropTypes.string.isRequired,
        events: PropTypes.instanceOf(Collection).isRequired,
        disabled: PropTypes.node,
        myList: PropTypes.instanceOf(Collection).isRequired,
        auth: PropTypes.object, // Note: may be null (if no session)
    };

    render() {
        const isTouch = this.props.responsive.isTouchEnabled();

        return (
            <EventsSliderList onActivate={sliderActive => {}}>
                <EventsSlider
                    className="margin30T"
                    disabled={
                        !this.props.disabled
                            ?
                                <div
                                    className={cx({
                                        'hover': !isTouch,
                                        'slider-inner-disabled vertical-align': true,
                                    })}
                                >
                                    <div className="well well-white">
                                        <span className="h3">
                                            Visit <a onClick={() => { this.props.sites.switch({ name: this.props.subsite }); }}>Here & Now in {this.props.subsiteName}</a> to see all events
                                        </span>
                                    </div>
                                </div>
                            :
                                this.props.disabled
                    }
                    auth={this.props.auth}
                    update={() => {}}
                    title={this.props.title}
                    events={this.props.events}
                    myList={this.props.myList}
                    toggleFavorite={() => {}}
                />
            </EventsSliderList>
        );
    }
}

@inject({
    sites: 'cpg.sites'
})
@Router.withRouter
@ReactRedux.connect(
    state => ({
        site: state.getIn(['app', 'site']),
        eventGroups: state.getIn(['app', 'eventgroups'])
    }),
    dispatch => ({
        dispatch,
    }),
)
export default class Portal extends React.PureComponent {
    state = {
        eventGroupsGroningen: null,
        eventGroupsFriesland: null,
        eventGroupsDrenthe: null,
        errorMessage: null
    }

    loadEventGroups() {
        window.site = { id: 1, name: 'groningen', basename: '/site-groningen' };
        getHome()
            .then(response => {
                this.setState({ eventGroupsGroningen: response.body });
            })
            .catch(reason => {
                this.setState({ errorMessage: "Something went wrong while loading events." });
            });

        window.site = { id: 2, name: 'friesland', basename: '/site-friesland' };
        getHome()
            .then(response => {
                this.setState({ eventGroupsFriesland: response.body });
            })
            .catch(reason => {
                this.setState({ errorMessage: "Something went wrong while loading events." });
            });

        window.site = { id: 3, name: 'drenthe', basename: '/site-drenthe' };
        getHome()
            .then(response => {
                this.setState({ eventGroupsDrenthe: response.body });
            })
            .catch(reason => {
                this.setState({ errorMessage: "Something went wrong while loading events." });
            });
    }

    componentDidMount() {
        console.info('loading event Groups...');
        this.loadEventGroups();
    }

    isLoading() {
        return this.state.eventGroupsGroningen === null;
    }
    
    render() {
        return (
            <div className="page-portal">
                <Helmet>
                    <body className={`page-portal`}/>
                    <title>Here & Now</title>
                </Helmet>

                <header className="container-fluid jumbotron jumbotron-portal" style={{ backgroundColor: 'black' }}>
                    <div className="row vertical-align">
                        <div className="col-xs-12 col-md-6 col-md-offset-3 text-center jumbotron-portal-content">
                            <img className="img img-responsive logo" alt="Logo Here & Now In The North"
                                src={require('../../style/img/logo_here_and_now_in_the_north.png')}
                            />
                            <img className="img img-responsive map" alt="Map Here & Now In The North"
                                src={require('../../style/img/here_and_now_in_the_north_map.png')}
                            />
                            <h1>
                              All events of Groningen, Leeuwarden–Friesland, and Drenthe. In English!
                            </h1>
                        </div>
                    </div>
                </header>

                {this.state.eventGroupsGroningen && !this.isLoading() &&
                    <SubsiteSlider
                        subsite="groningen"
                        subsiteName="Groningen"
                        title={<a onClick={() => { this.props.sites.switch({ name: 'groningen' }); }}>
                                Groningen

                                <span className="icon icon-arrow-right"
                                    aria-hidden="true" aria-label={`Visit Here & Now in Groningen to see all events`}
                                />
                            </a>
                        }
                        events={this.state.eventGroupsGroningen.get('this-week').events}
                    />
                }
                
                {this.state.eventGroupsFriesland && !this.isLoading() &&
                    <SubsiteSlider
                        subsite="friesland"
                        subsiteName="Leeuwarden–Friesland"
                        title={<a onClick={() => { this.props.sites.switch({ name: 'friesland' }); }}>
                                Leeuwarden-Friesland

                                <span className="icon icon-arrow-right"
                                    aria-hidden="true" aria-label={`Visit Here & Now in Leeuwarden–Friesland to see all events`}
                                />
                            </a>
                        }
                        events={this.state.eventGroupsFriesland.get('this-week').events}
                    />
                }

                {this.state.eventGroupsDrenthe && !this.isLoading() &&
                    <SubsiteSlider
                        subsite="drenthe"
                        subsiteName="Drenthe"
                        title={<a onClick={() => { this.props.sites.switch({ name: 'drenthe' }); }}>
                                Drenthe

                                <span className="icon icon-arrow-right"
                                    aria-hidden="true" aria-label={`Visit Here & Now in Drenthe to see all events`}
                                />
                            </a>
                        }
                        events={this.state.eventGroupsDrenthe.get('this-week').events}
                    />
                }
            </div>
        );
    }
}
