import { url } from '../CpgAgent.js';

//TODO later can we use the width, height and fit attributes to crop the image for faster page loads based on the size of the screen
export const convertPath = (path) => {
    return `${url}/image?src=${path}`
};

export const getFallbackImage = () => {
    return `${url}/image?src=uploads/placeholder-yellow.jpg&w=350&h=190&fit=crop`
};
