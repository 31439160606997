
import * as _ from 'lodash';
import cx from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import inject from '../../util/di/inject.js';
import { Link } from 'react-router-dom';
import * as ReactRedux from 'react-redux';
import * as Router from 'react-router';
import api from '../../services/CpgApi.js';
import { fromJS } from 'immutable';
import SitesService from '../../services/SitesService.js';

@Router.withRouter
class Search extends React.PureComponent {
    state = {
        query: "",
    };
    
    historyUnlisten = null;
    
    constructor(props) {
        super(props);
        let currentLocation = props.history.location;
        this.historyUnlisten = props.history.listen(updatedLocation => {
            if (currentLocation.pathname.substring(0, 7) === '/search' && updatedLocation.pathname.substring(0, 7) !== '/search') {
                this.setState({ query: '' });
            }
            currentLocation = updatedLocation;
        });
    }
    
    
    componentWillUnmount() {
        if (this.historyUnlisten) {
            this.historyUnlisten();
        }
    }

    submit = evt => {
        evt.preventDefault();
        if (this.state.query !== '') {
            this.props.history.push('/search/' + this.state.query);
        }
    };
    
    render() {
        return (        
            <form className="navbar-form navbar-search navbar-right" role="search" onSubmit={this.submit}>
                <div className="form-group">
                    <div className="input-group">
                        <input 
                            type="text" 
                            className="form-control" 
                            placeholder="Search events"
                            value={this.state.query}
                            onChange={evt => { this.setState({ query: evt.target.value }); }}
                        />
                        <span className="input-group-btn">
                            <button className="btn btn-default" type="submit" title="Submit search query">
                                <span className="icon icon-magnifier" aria-hidden="true" aria-label="Search submit icon"/>
                            </button>
                        </span>
                    </div>
                </div>
            </form>
        );
    }
}

@inject({
    sites: 'cpg.sites'
})
@Router.withRouter
@ReactRedux.connect(
    state => ({
        session: state.getIn(['session']),
    }),
)
class AuthWidget extends React.PureComponent {
    static propTypes = {
        session: PropTypes.object.isRequired,
    };
    
    getUsername() {
        // TODO: move this to the User entity
        const user = this.props.session.auth.user;
        if (user.firstname || user.lastname) {
            return `${user.firstname} ${user.lastname}`.trim();
        }
        
        if (user.email && user.email.includes('@')) {
            return user.email.split('@')[0];
        }
        
        return '(unknown)'; // Should not happen
    }
    
    isFacebookUser() {
        const user = this.props.session.auth.user;
        
        if (!user) {
            return false;
        }
        
        return user.facebook_id !== null;
    }
    
    goto = (location) => this.props.history.push(location);
    
    render() {
        // Do not render during server-side rendering
        if (typeof global === 'object' && global && global.ssr) {
            return null;
        }
        
        const site = new SitesService().getSite(window.location);
        
        const facebookItem =
            <li>
                <a target="_blank" rel="noopener noreferrer" className="link-icon text-left" href={site.facebook} title="Visit us on Facebook">
                    <svg className="svg img-responsive" width="30" height="30" enableBackground="new 0 0 32 32" version="1.1" viewBox="0 0 32 32">
                        <title>Follow us on Facebook</title>
                        <g id="icon-facebook">
                            <g>
                                <path className="circle" d="M16,0C7.163,0,0,7.163,0,16c0,8.836,7.163,16,16,16s16-7.164,16-16C32,7.163,24.837,0,16,0z" fill="#000"/>
                            </g>
                            <path className="logo" d="M13.69,24.903h3.679v-8.904h2.454l0.325-3.068h-2.779l0.004-1.536c0-0.8,0.076-1.229,1.224-1.229h1.534   V7.097h-2.455c-2.949,0-3.986,1.489-3.986,3.992v1.842h-1.838v3.069h1.838V24.903z" fill="transparent"/>
                        </g>
                    </svg>
                    <span className="visible-xs-inline visible-ms-inline">&nbsp; Visit us on Facebook!</span> 
                </a>
            </li>;

        const instagramItem =
            <li>
                <a target="_blank" rel="noopener noreferrer" className="link-icon " href={site.instagram} title="Follow us on Instagram">
                    <svg className="svg img-responsive" width="30" height="30" enableBackground="new 0 0 32 32" version="1.1" viewBox="0 0 32 32">
                        <title>Follow us on Instagram</title>
                        <g id="icon-instagram">
                            <g>
                                <path className="circle" d="M16,0C7.163,0,0,7.163,0,16c0,8.836,7.163,16,16,16s16-7.164,16-16C32,7.163,24.837,0,16,0z" fill="#000" />
                            </g>
                            <path className="logo" d="M15.0001 13.3965C13.5665 13.3965 12.3966 14.5664 12.3966 16C12.3966 17.4336 13.5665 18.6035 15.0001 18.6035C16.4337 18.6035 17.6036 17.4336 17.6036 16C17.6036 14.5664 16.4337 13.3965 15.0001 13.3965ZM22.8087 
                            16C22.8087 14.9218 22.8185 13.8535 22.7579 12.7773C22.6974 11.5273 22.4122 10.4179 21.4981 9.50388C20.5821 8.58786 19.4747 8.30466 18.2247 8.24411C17.1466 8.18356 16.0782 8.19333 15.002 8.19333C13.9239 8.19333 12.8556 8.18356 11.7794 
                            8.24411C10.5294 8.30466 9.42001 8.58981 8.50595 9.50388C7.58994 10.4199 7.30673 11.5273 7.24619 12.7773C7.18564 13.8554 7.19541 14.9238 7.19541 16C7.19541 17.0761 7.18564 18.1465 7.24619 19.2226C7.30673 20.4726 7.59189 21.582 8.50595 
                            22.4961C9.42197 23.4121 10.5294 23.6953 11.7794 23.7558C12.8575 23.8164 13.9259 23.8066 15.002 23.8066C16.0802 23.8066 17.1485 23.8164 18.2247 23.7558C19.4747 23.6953 20.5841 23.4101 21.4981 22.4961C22.4142 21.58 22.6974 20.4726 22.7579 
                            19.2226C22.8204 18.1465 22.8087 17.0781 22.8087 16V16ZM15.0001 20.0058C12.7833 20.0058 10.9942 18.2168 10.9942 16C10.9942 13.7832 12.7833 11.9941 15.0001 11.9941C17.2169 11.9941 19.006 13.7832 19.006 16C19.006 18.2168 17.2169 20.0058 
                            15.0001 20.0058ZM19.17 12.7656C18.6524 12.7656 18.2345 12.3476 18.2345 11.83C18.2345 11.3125 18.6524 10.8945 19.17 10.8945C19.6876 10.8945 20.1056 11.3125 20.1056 11.83C20.1057 11.9529 20.0816 12.0747 20.0347 12.1882C19.9877 12.3018 
                            19.9188 12.405 19.8319 12.4919C19.745 12.5788 19.6418 12.6477 19.5282 12.6947C19.4146 12.7417 19.2929 12.7657 19.17 12.7656V12.7656Z" fill="transparent"/>
                        </g>
                    </svg>
                    <span className="visible-xs-inline visible-ms-inline">&nbsp; Follow us on Instagram!</span>
                </a>
            </li>;
            
        const siteSwitchItem =
          <React.Fragment>
            <li role="separator" className="divider"></li>
            {site.name !== 'groningen' && 
              <li className="visible-xs visible-ms">
                <a className="link-icon text-left" title="Here &amp; Now in Groningen" onClick={() => { this.props.sites.switch({ name: 'groningen' }); }}>     
                    <svg className="svg img-responsive" width="30" height="30" enableBackground="new 8.372 12.118 52.692 75.992" viewBox="8.372 12.118 52.692 75.992">
                        <title>Here and now marker</title>
                        <g id="icon-hn-marker">
                          <path d="M40.757,12.118c-2.084,0-4.168,0.321-6.038,0.908c-1.924-0.587-3.955-0.908-6.039-0.908
                            c-11.223,0-20.308,8.871-20.308,19.827c0,11.007,8.764,23.245,12.505,27.948c0.963,1.229,2.458,1.924,4.062,1.924h5.291
                            l4.274,6.52l0.215,0.32l0.214-0.32l4.275-6.52h5.29c1.603,0,3.047-0.695,4.062-1.977c3.74-4.756,12.505-16.995,12.505-27.949
                            C61.064,20.989,51.926,12.118,40.757,12.118z M24.887,59.68c-0.909,0-1.818-0.43-2.353-1.123
                            c-2.992-3.74-12.077-16.032-12.077-26.612c0-9.781,8.122-17.69,18.169-17.69c0.749,0,1.442,0.055,2.19,0.16
                            c-8.337,1.764-14.59,8.926-14.59,17.529c0,9.297,7.001,19.934,10.688,24.957l0,0l1.816,2.777h-3.846L24.887,59.68L24.887,59.68z
                             M34.666,37.983c-4.489,0-8.122-3.635-8.122-8.124c0-4.487,3.634-8.122,8.122-8.122c4.487,0,8.123,3.635,8.123,8.122
                            C42.789,34.348,39.154,37.983,34.666,37.983z M46.85,58.557c-0.589,0.746-1.442,1.123-2.353,1.123h-3.9l1.816-2.779l0,0
                            c3.688-4.969,10.688-15.712,10.688-24.956c0-8.604-6.251-15.819-14.589-17.529c0.695-0.106,1.443-0.16,2.191-0.16
                            c10.047,0,18.169,7.909,18.169,17.689C58.928,42.418,49.842,54.762,46.85,58.557z"/>
                          <g>
                            <path d="M39.101,78.864c0.48,0,0.908,0.373,0.908,0.908v5.291c0,0.48-0.374,0.908-0.908,0.908h-8.816
                              c-0.481,0-0.908-0.373-0.908-0.908v-5.291c0-0.48,0.373-0.908,0.908-0.908H39.101 M39.101,76.727h-8.816
                              c-1.657,0-3.047,1.336-3.047,3.045v5.293c0,1.654,1.336,3.045,3.047,3.045h8.816c1.656,0,3.046-1.336,3.046-3.045v-5.293
                              C42.093,78.115,40.757,76.727,39.101,76.727L39.101,76.727z"/>
                          </g>
                        </g>
                    </svg>
                    &nbsp; Here &amp; Now in Groningen
                </a>
              </li>
            }
            {site.name !== 'friesland' && 
              <li className="visible-xs visible-ms">
                <a className="link-icon text-left" title="Here &amp; Now in Leeuwarden-Friesland" onClick={() => { this.props.sites.switch({ name: 'friesland' }); }}>
                    <svg className="svg img-responsive" width="30" height="30" enableBackground="new 8.372 12.118 52.692 75.992" viewBox="8.372 12.118 52.692 75.992">
                        <title>Here and now marker</title>
                        <g id="icon-hn-marker">
                          <path d="M40.757,12.118c-2.084,0-4.168,0.321-6.038,0.908c-1.924-0.587-3.955-0.908-6.039-0.908
                            c-11.223,0-20.308,8.871-20.308,19.827c0,11.007,8.764,23.245,12.505,27.948c0.963,1.229,2.458,1.924,4.062,1.924h5.291
                            l4.274,6.52l0.215,0.32l0.214-0.32l4.275-6.52h5.29c1.603,0,3.047-0.695,4.062-1.977c3.74-4.756,12.505-16.995,12.505-27.949
                            C61.064,20.989,51.926,12.118,40.757,12.118z M24.887,59.68c-0.909,0-1.818-0.43-2.353-1.123
                            c-2.992-3.74-12.077-16.032-12.077-26.612c0-9.781,8.122-17.69,18.169-17.69c0.749,0,1.442,0.055,2.19,0.16
                            c-8.337,1.764-14.59,8.926-14.59,17.529c0,9.297,7.001,19.934,10.688,24.957l0,0l1.816,2.777h-3.846L24.887,59.68L24.887,59.68z
                             M34.666,37.983c-4.489,0-8.122-3.635-8.122-8.124c0-4.487,3.634-8.122,8.122-8.122c4.487,0,8.123,3.635,8.123,8.122
                            C42.789,34.348,39.154,37.983,34.666,37.983z M46.85,58.557c-0.589,0.746-1.442,1.123-2.353,1.123h-3.9l1.816-2.779l0,0
                            c3.688-4.969,10.688-15.712,10.688-24.956c0-8.604-6.251-15.819-14.589-17.529c0.695-0.106,1.443-0.16,2.191-0.16
                            c10.047,0,18.169,7.909,18.169,17.689C58.928,42.418,49.842,54.762,46.85,58.557z"/>
                          <g>
                            <path d="M39.101,78.864c0.48,0,0.908,0.373,0.908,0.908v5.291c0,0.48-0.374,0.908-0.908,0.908h-8.816
                              c-0.481,0-0.908-0.373-0.908-0.908v-5.291c0-0.48,0.373-0.908,0.908-0.908H39.101 M39.101,76.727h-8.816
                              c-1.657,0-3.047,1.336-3.047,3.045v5.293c0,1.654,1.336,3.045,3.047,3.045h8.816c1.656,0,3.046-1.336,3.046-3.045v-5.293
                              C42.093,78.115,40.757,76.727,39.101,76.727L39.101,76.727z"/>
                          </g>
                        </g>
                    </svg>
                    &nbsp; Here &amp; Now in Leeuwarden-Friesland
                </a>
              </li>
            }
            {site.name !== 'drenthe' && 
              <li className="visible-xs visible-ms">
                <a className="link-icon text-left" title="Here &amp; Now in Drenthe" onClick={() => { this.props.sites.switch({ name: 'drenthe' }); }}>
                    <svg className="svg img-responsive" width="30" height="30" enableBackground="new 8.372 12.118 52.692 75.992" viewBox="8.372 12.118 52.692 75.992">
                        <title>Here and now marker</title>
                        <g id="icon-hn-marker">
                            <path d="M40.757,12.118c-2.084,0-4.168,0.321-6.038,0.908c-1.924-0.587-3.955-0.908-6.039-0.908
                            c-11.223,0-20.308,8.871-20.308,19.827c0,11.007,8.764,23.245,12.505,27.948c0.963,1.229,2.458,1.924,4.062,1.924h5.291
                            l4.274,6.52l0.215,0.32l0.214-0.32l4.275-6.52h5.29c1.603,0,3.047-0.695,4.062-1.977c3.74-4.756,12.505-16.995,12.505-27.949
                            C61.064,20.989,51.926,12.118,40.757,12.118z M24.887,59.68c-0.909,0-1.818-0.43-2.353-1.123
                            c-2.992-3.74-12.077-16.032-12.077-26.612c0-9.781,8.122-17.69,18.169-17.69c0.749,0,1.442,0.055,2.19,0.16
                            c-8.337,1.764-14.59,8.926-14.59,17.529c0,9.297,7.001,19.934,10.688,24.957l0,0l1.816,2.777h-3.846L24.887,59.68L24.887,59.68z
                            M34.666,37.983c-4.489,0-8.122-3.635-8.122-8.124c0-4.487,3.634-8.122,8.122-8.122c4.487,0,8.123,3.635,8.123,8.122
                            C42.789,34.348,39.154,37.983,34.666,37.983z M46.85,58.557c-0.589,0.746-1.442,1.123-2.353,1.123h-3.9l1.816-2.779l0,0
                            c3.688-4.969,10.688-15.712,10.688-24.956c0-8.604-6.251-15.819-14.589-17.529c0.695-0.106,1.443-0.16,2.191-0.16
                            c10.047,0,18.169,7.909,18.169,17.689C58.928,42.418,49.842,54.762,46.85,58.557z"/>
                            <g>
                            <path d="M39.101,78.864c0.48,0,0.908,0.373,0.908,0.908v5.291c0,0.48-0.374,0.908-0.908,0.908h-8.816
                                c-0.481,0-0.908-0.373-0.908-0.908v-5.291c0-0.48,0.373-0.908,0.908-0.908H39.101 M39.101,76.727h-8.816
                                c-1.657,0-3.047,1.336-3.047,3.045v5.293c0,1.654,1.336,3.045,3.047,3.045h8.816c1.656,0,3.046-1.336,3.046-3.045v-5.293
                                C42.093,78.115,40.757,76.727,39.101,76.727L39.101,76.727z"/>
                            </g>
                        </g>
                    </svg>
                    &nbsp; Here &amp; Now in Drenthe
                </a>
              </li>
            }
        </React.Fragment>;
        
        const auth = this.props.session.auth;
        if (auth === null) {
            return (
                <ul className="nav navbar-nav navbar-right">
                    <li className={cx({ 'active': (location.pathname === '/login') })}>
                        <Link to="/login">Log in</Link>
                    </li>
                    <li className={cx({ 'active': (location.pathname === '/register') })}>
                        <Link to="/register">Register</Link>
                    </li>
                    {siteSwitchItem}
                    {site.name === 'groningen' && 
                        <React.Fragment>{instagramItem}</React.Fragment>
                    }
                    {facebookItem}
                </ul>
            );
        }
        
        return (
            <ul className="nav navbar-nav navbar-right">
                <li className="dropdown">
                    <Link to="/" className="dropdown-toggle" data-toggle="dropdown"
                        role="button" aria-haspopup="true" aria-expanded="false"
                    >
                        <span className="icon icon-user margin10R" aria-hidden="true" aria-label="User icon"></span>
                        {this.getUsername()}
                        {' '}
                        <span className="caret"></span>
                    </Link>
                    <ul className="dropdown-menu">
                        <li className={cx({'active': (location.pathname === '/category/special/my-list')})}>
                            <Link to="/category/special/my-list">
                                <span className="icon icon-list margin10R"
                                    aria-hidden="true" aria-label="My List icon"
                                />
                                My List
                            </Link>
                        </li>
                        <li className={cx({'active': (location.pathname === '/category/special/my-recommendations')})}>
                            <Link to="/category/special/my-recommendations">
                                <span className="icon icon-like margin10R"
                                    aria-hidden="true" aria-label="My Recommendations icon"
                                />
                                My Recommendations
                            </Link>
                        </li>
                        {!this.isFacebookUser() &&
                            <li className={cx({'active': (location.pathname === '/user/preferences')})}>
                                <Link to="/user/preferences">
                                    <span className="icon icon-settings margin10R" aria-hidden="true" aria-label="Preferences icon"></span>
                                    Preferences
                                </Link>
                            </li>
                        }
                        <li className={cx({'active': (location.pathname === '/user/onboarding')})}>
                            <Link to="/user/onboarding">
                                <span className="icon icon-heart margin10R"
                                    aria-hidden="true" aria-label="Interests icon"
                                />
                                Interests
                            </Link>
                        </li>
                        <li className={cx({'active': (location.pathname === '/logout')})}>
                            <Link to="/logout">
                                <span className="icon icon-logout margin10R" aria-hidden="true" aria-label="Logout icon"></span>
                                Logout
                            </Link>
                        </li>
                    </ul>
                </li>
                {siteSwitchItem}
                {facebookItem}
            </ul>
        );
    }
}

@Router.withRouter
@ReactRedux.connect(
    state => ({
        eventGroups: state.getIn(['app', 'menu']),
        auth: state.getIn(['session', 'auth']),
    }),
)
@inject({
    sites: 'cpg.sites',
})
export default class Navigation extends React.PureComponent {
    categoryIcons = {};
    logos = {};
    
    constructor(props) {
        super(props);
        
        // Category icons
        
        // Note: we need to this using a dynamic require, otherwise we break server-sider rendering
        this.categoryIcons = {
            'featured': require('../../style/img/svg/icons/category-featured.svg'),
            'my-list': require('../../style/img/svg/icons/category-my-list.svg'),
            'my-recommendations': require('../../style/img/svg/icons/category-my-recommendations.svg'),
            'this-week': require('../../style/img/svg/icons/category-this-week.svg'),
            'music': require('../../style/img/svg/icons/category-music.svg'),
            'art-performance': require('../../style/img/svg/icons/category-art-performance.svg'),
            'learn-connect': require('../../style/img/svg/icons/category-learn-connect.svg'),
            // TEMP: compatibility with older database
            'networking': require('../../style/img/svg/icons/category-learn-connect.svg'),
            'community-organized': require('../../style/img/svg/icons/category-community-organized.svg'),
            'film': require('../../style/img/svg/icons/category-film.svg'),
            'other': require('../../style/img/svg/icons/category-other.svg'),
        };
        
        this.logos = {
            groningen: require('../../style/img/svg/logo-here-now-groningen-black.svg'),
            friesland: require('../../style/img/svg/logo-here-now-friesland-black.svg'),
            drenthe: require('../../style/img/svg/logo-here-now-drenthe-black.svg'),
        };
    }
    
    componentDidMount() {
        this.props.dispatch(api.menu.list());
    }
    
    getIcon(slug) {
        return this.categoryIcons.hasOwnProperty(slug) ? this.categoryIcons[slug] : null;
    }
    
    render() {
        const {eventGroups, history, auth} = this.props;
        
        const location = history.location;
        const specialMenuItems = fromJS(eventGroups.getIn(['special', 'children'], {}))
            .filter((entry, key) => {
                // Hide a couple of menu items, we want to show these in the user menu instead
                return !['my-list', 'my-recommendations'].includes(key);
            });
        
        const EventGroupMenuItems = (!eventGroups && eventGroups.size !== 0) ? undefined: eventGroups.filter((eventGroup) => eventGroup.slug !== 'special');

        const site = new SitesService().getSite(window.location);
        
        // const mainCategories = categories.filter((category) => category.get('children').size !== 0).reverse();
        return (
          <React.Fragment>
            <nav className="navbar navbar-inverse navbar-static-top navbar-browse">
                <div className="container-fluid">
                    <div className="navbar-header">
                        <button type="button" className="navbar-toggle collapsed"
                            data-toggle="collapse" data-target="#navbar" aria-expanded="false" aria-controls="navbar"
                        >
                            <span className="sr-only">Toggle navigation</span>
                            <span className="icon-bar"/>
                            <span className="icon-bar"/>
                            <span className="icon-bar"/>
                        </button>
                        <Link to="/" rel="index" className="navbar-brand">
                            {this.logos.hasOwnProperty(site.name) &&
                                <img className="svg img-responsive" src={this.logos[site.name]}/>
                            }
                        </Link>
                    </div>
                    <div id="navbar" className="navbar-collapse collapse">
                        <ul className="nav navbar-nav">
                            <li
                                className={cx({
                                    'dropdown': true,
                                    'active': location.pathname.split('/')[1] === 'categories',
                                })}
                            >
                                <Link to="/" className="dropdown-toggle" data-toggle="dropdown">
                                    Categories <b className="caret"/>
                                </Link>
                                <ul className="dropdown-menu">
                                    {specialMenuItems && specialMenuItems.map((eventGroup, eventGroupId) =>
                                        <li
                                            key={eventGroupId}
                                            className={cx({
                                                'active':
                                                    location.pathname === `/category/special/${eventGroup.get('slug')}`
                                            })}
                                        >
                                            <Link to={`/category/special/${eventGroup.get('slug')}`}>
                                                <img  className="svg img-responsive category-icon"
                                                    src={this.getIcon(eventGroup.get('slug'))}
                                                />
                                                {eventGroup.get('name')}
                                            </Link>
                                        </li>
                                    ).toArray()}
                                    
                                    {EventGroupMenuItems && EventGroupMenuItems.map((eventGroup, eventGroupId) =>
                                        <li
                                            key={eventGroupId}
                                            className={cx({
                                                'active': location.pathname === `/category/${eventGroup.get('slug')}`
                                            })}
                                        >
                                            <Link to={`/category/${eventGroup.get('slug')}`}>
                                                {this.getIcon(eventGroup.get('slug')) !== null
                                                    ? 
                                                        <img className="svg img-responsive category-icon"
                                                            src={this.getIcon(eventGroup.get('slug'))}
                                                        />
                                                    : <span className="category-first-letter">{eventGroup.get('name').charAt(0)}</span>
                                                }
                                                {eventGroup.get('name')}
                                            </Link>
                                        </li>
                                    ).toArray()}
                                </ul>
                            </li>
                            <li className={cx({ active: (location.pathname === '/nearby') })}>
                                <Link to="/nearby">Near Me</Link>
                            </li>
                            <li className={"hidden-sm " + cx({ active: (location.pathname === '/submitevent') })}>
                                <Link to="/submitevent">Submit event</Link>
                            </li>
                            <li className={cx({ 'active': location.pathname === '/explore' })}>
                                <Link to="/explore">
                                    Explore
                                </Link>
                            </li>
                            <li
                                className={"hidden-md hidden-sm " + cx({
                                    'dropdown': true,
                                    'active': location.pathname === '/about',
                                })}
                            >
                                <Link to="/" className="dropdown-toggle" data-toggle="dropdown">
                                    About <b className="caret"/>
                                </Link>
                                <ul className="dropdown-menu">
                                    
                                    <li
                                        className={cx({
                                            'active': location.pathname === '/about',
                                        })}
                                    >
                                        <Link to="/about">
                                            About Here &amp; Now
                                        </Link>
                                    </li>
                                    {(site.name !== 'friesland' && site.name !== 'drenthe') &&
                                    <li
                                        className={cx({
                                            'active': location.pathname === '/about-' + site.name,
                                        })}
                                    >
                                        <Link to={`/about-${site.name}`}>
                                            About {site.title}
                                        </Link>
                                    </li>
                                    }
                                </ul>
                            </li>
                        </ul>
                        <AuthWidget/>
                        <Search />
                    </div>
                </div>
            </nav>
            <nav className="navbar navbar-inverse navbar-static-top navbar-site-switch hidden-xs hidden-ms">
              <div className="container-fluid">
                <div className="navbar-collapse collapse">
                  <ul className="nav navbar-nav" style={{display: 'flex'}}>
                    <li style={{ order: site.name === 'groningen' ? 0 : 1 }}
                      className={cx({
                          'active': site.name === 'groningen',
                      })}
                    >
                      {site.name !== 'groningen'
                          ? 
                              <a title="Here & Now in Groningen" onClick={() => { this.props.sites.switch({ name: 'groningen' }); }}>
                                Groningen
                              </a>
                          :
                              <a className="nolink">Groningen</a>
                      }
                    </li>
                    <li style={{ order: site.name === 'friesland' ? 0 : 1 }}
                      className={cx({
                          'active': site.name === 'friesland',
                      })}
                    >
                      {site.name !== 'friesland'
                        ? 
                          <a title="Here & Now in Friesland" onClick={() => { this.props.sites.switch({ name: 'friesland' }); }}>
                              Leeuwarden–Friesland
                          </a>
                        :
                          <a className="nolink">Leeuwarden–Friesland</a>
                      }
                    </li>
                    <li style={{ order: site.name === 'drenthe' ? 0 : 1 }}
                      className={cx({
                          'active': site.name === 'drenthe',
                      })}
                    >
                      {site.name !== 'drenthe'
                        ? 
                          <a title="Here & Now in Drenthe" onClick={() => { this.props.sites.switch({ name: 'drenthe' }); }}>
                              Drenthe
                          </a>
                        :
                          <a className="nolink">Drenthe</a>
                      }
                    </li>
                  </ul>
                  <Search />
                </div>
              </div>
            </nav>
          </React.Fragment>
        );
    }
}

@Router.withRouter
export class MainCategoryList extends React.PureComponent {
    state = {
        amount: 4,
    };
    
    render() {
        const { mainCategory, history, location } = this.props;
        const { amount } = this.state;
        const children = mainCategory.children;
        const topChildren = children.take(amount);
        
        return (
            <li key={mainCategory.id} className="col-sm-3">
                <ul>
                    <li className="dropdown-header">
                        <Link to={`/category/${mainCategory.slug}/`}>{ mainCategory.name }</Link>
                    </li>
                    {topChildren && topChildren.map((childCategory) => (
                        <li key={childCategory.id} className={cx({'active': location.pathname === `/category/${childCategory.id}`})}>
                            <Link to={`/category/${mainCategory.slug}/${childCategory.slug}`}>{childCategory.name}</Link>
                        </li>
                    ))}
                    {_.times(amount - children.size + 1).map(amount =>
                        <li key={amount} className="hidden-xs hidden-ms">
                            <a>&#x200B;</a>
                        </li>
                    )}
                    {children.size >= amount + 1 &&
                        <li>
                            <Link className="more-link" to={`/category/${mainCategory.slug}`}>And more</Link>
                        </li>
                    }
                </ul>
            </li>
        );
    }
}
