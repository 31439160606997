import Collection from '../models/Collection.js';
import * as _ from 'lodash';
import Entity from '../models/Entity';
import Schema from '../models/schema';

export const collectionFromResponse = EntityType => response => {
    let collection = null;
    if (response instanceof Collection) {
        collection = response;
    } else if (_.isArray(response)) {
        const entries = response.map(item => {
            return makeEntry(EntityType, item);
        });
        collection = new Collection(EntityType, { status: 'ready' }, entries);
    } else {
        throw new Error("Unrecognized collection response format");
    }
    return collection;
};

// Utility: take an entity type, and a plain value, and instantiate the entity type with that value.
export const makeEntry = (entitySchema, entityValue) => {
    let key = null;
    if ((entitySchema.prototype instanceof Entity) && entitySchema.hasOwnProperty('key')) {
        key = entitySchema.key;
    } else {
        key = { id: Schema.text };
    }
    
    const index = makeIndex(key, entityValue);
    return [index, new entitySchema(entityValue, { status: 'ready' })];
};

// Utility: take a key (schema), and a value, and return an instance of the key
// Example:
// - key: `{ id : Schema.text }`
// - value: `42`
// - result: `{ id: "42" }`
export const makeIndex = (key, value) => {
    let index = null;
    
    if (key === Schema.text) {
        // Note: we expect an index to be a string. The API might return (e.g.) a numerical ID, but since
        // we treat all IDs as opaque (no numerical calculations), it's easier to ensure it is a string
        // right away. This also spares us from having to parse router params (which are always strings).
        index = String(value);
    } else if (_.isPlainObject(key)) {
        index = _.mapValues(key, (keyProp, keyPropName) => {
            if (!value.hasOwnProperty(keyPropName)) {
                throw new Error(`Missing property '${keyPropName}'`);
            }
            return makeIndex(keyProp, value[keyPropName]);
        });
        
        // Special case: ID with one property
        if (Object.keys(key).length === 1) {
            index = index[Object.keys(key)[0]];
        }
    } else {
        throw new Error('Unsupported ID format');
    }
    
    return index;
};
