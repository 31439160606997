
import * as Api from '../util/api/Api.js';

// Other services/components depend on these values
export const url = process.env.API_URL;
export const backendUrl = process.env.BACKEND_URL;

const agent =  Api.createAgent({
    // When we're in SSR (running in node.js) set the API URI with hostname attached.
    // When not in CORS we need to leave out the hostname otherwise our proxy 
    // in app/app_server_(dev|prod).js designed to circumvent CORS will not work.
    // Ref: https://stackoverflow.com/questions/17575790/environment-detection-node-js-or-browser#comment106067031_17575971
    uri: (typeof(window) !== 'undefined') ? '/api-v1' : `${url}/api-v1`,
    getAuthToken: () => {
        if (localStorage && localStorage.getItem('auth')) {
            const auth = JSON.parse(localStorage.getItem('auth'));
            return auth.token;
        } else {
            return null;
        }
    },
});

agent.use(request => {
    if (window.site) {
        request.query({ site_id: window.site.id });
    }
    
    return request;
});

export default agent;
