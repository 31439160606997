
import _ from 'lodash';
import Imm from 'immutable';

import Schema from '../util/models/schema.js';
import Entity from '../util/models/Entity.js';


export default class User extends Entity {
    static key = {
        id: Schema.text,
    };
    
    static schema = {
        name: Schema.text,
        email: Schema.text,
        on_board: Schema.text,
        facebook_id: Schema.text,
        firstname: Schema.text,
        lastname: Schema.text,
        country_of_origin: Schema.text,
        birth_year: Schema.integer
    };
    
    constructor(...args) {
        super(User.schema, ...args);
    }
};
