
import $uri from 'uri-tag';

import * as PropTypes from 'prop-types';
import * as React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import inject from '../../util/di/inject.js';
import agent from '../../services/CpgAgent.js';

@inject({
    sites: 'cpg.sites',
})
export default class PrivacyStatement extends React.PureComponent {
    state = {
        title: null,
        dangerousHtml: null,
        connectionError: false
    };

    async componentDidMount() {
        const site = this.props.sites.getSite(window.location).name;
        
        try {
            const content = await agent.get($uri`/content/privacy-statement-${site}`);
            this.setState({ title: content.body.title, dangerousHtml: content.body.html });
        } catch (reason) {
            this.setState({ connectionError: true });
        }
    }
    
    render() {
        const { title, dangerousHtml, connectionError } = this.state;
        const description = 'Read up on our privacy policy.';
        const safeHtml = { __html: dangerousHtml };
        const errorHtml = { __html: '<h1>This page can not be shown</h1><p>Please try again at a later time.</p>' };
        
        return (
            <React.Fragment>
                <Helmet>
                  <body className="page-privacy-statement"/>
                  <title>{(title !== null) ? title : 'Privacy Statement'}</title>
                  <meta name="description" content={description}/>
                </Helmet>
                
                <div className="container content-wrapper">
                    <div className="row">
                        <div className="well well-white col-xs-10 col-xs-offset-1 col-md-8 col-md-offset-2">
                            <div className="row">
                                <div className="col-sm-10 col-sm-offset-1"
                                    dangerouslySetInnerHTML={(dangerousHtml === null && connectionError === true) ? errorHtml : safeHtml}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
