
import _ from 'lodash';
import Imm from 'immutable';

import Schema from '../util/models/schema.js';
import Entity from '../util/models/Entity.js';
import Collection from '../util/models/Collection.js';

import Event from './Event.js';


export default class Location extends Entity {
  static key = { id: Schema.text };
  static schema = {
    name: Schema.text,
    slug: Schema.text,
    
    address: Schema.text,
    postcode: Schema.text,
    city: Schema.text,
    
    coords: [Schema.number, Schema.number],
    events: new Collection(Event),
  };

  constructor(...args) {
    super(Location.schema, ...args);
  }
};
