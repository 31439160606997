
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import { convertPath } from '../../services/model/ImageService.js';
import * as Router from 'react-router';
import * as ReactRedux from 'react-redux';
import * as session from './session.js';

@Router.withRouter
@ReactRedux.connect()
export default class EmailConfirmation extends React.PureComponent {
    render() {
        return (
            <div className="container vertical-align email-confirmation-container">
                <Helmet>
                    <body className="page-email"/>
                    <title>Email confirmation</title>
                    <meta name="description" content="Email confirmation"/>
                    
                    <style>{`
                        body {
                            background-image:
                                url("${convertPath('uploads/background-6156328340-14a034c488-o-resize.jpg')}");
                        }
                    `}</style>
                </Helmet>
                
                <div className="row">
                    <div className="well well-white col-sm-8 col-sm-offset-2 col-md-6 col-md-offset-3 col-lg-4 col-lg-offset-4">
                        <h1>Almost done...</h1>
                        <p>
                            <strong>Congratulations! Your registration completed successfully.</strong>
                        </p>
                        <div className="alert alert-info" role="alert">
                            To be able to log in your account, <strong>please check your email</strong> to confirm your account.
                        </div>
                        <Link to={`/`} className="btn btn-black"> 
                            <span className="icon icon-arrow-left" aria-hidden="true" aria-label="Arrow left icon"></span>
                            Back to homepage
                        </Link>
                        {/*
                        <span className="margin10L">or</span> 
                        <Link to={`/login`} className="btn btn-link hidden-xs"> 
                            Login
                        </Link>*/}
                    </div>
                </div>
            </div>
        );
    }
}
