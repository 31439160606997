
import _ from 'lodash';
import Imm from 'immutable';

import Schema from '../util/models/schema.js';
import Entity from '../util/models/Entity.js';
import Collection from '../util/models/Collection.js';
import Image from './Image.js';


export default class Category extends Entity {
  static key = { slug: Schema.text };
  static schema = {
    id: Schema.integer,
    description: Schema.text,
    name: Schema.text,
    slug: Schema.text,
    created_at: Schema.text,
    favorite: Schema.text,
    children: new Collection(Category),
    images: new Collection(Image),
  };

  constructor(...args) {
    super(Category.schema, ...args);
  }
}
