
import moment from 'moment';

import * as React from 'react';
import { Helmet } from 'react-helmet';
import * as ReactRedux from 'react-redux';
import { Link } from 'react-router-dom';

import inject from '../../util/di/inject.js';
import { convertPath } from '../../services/model/ImageService.js';
import api from '../../services/CpgApi.js';
import { parseCookies } from '../../util/user_agent.js';

@inject({
    sites: 'cpg.sites',
})
@ReactRedux.connect(
    state => ({
        auth: state.getIn(['session', 'auth']),
        contentPages: state.getIn(['app', 'contentPages']),
    }),
    dispatch => ({
        fetchContentPages: () => dispatch(api.contentPages.list()),
    }),
)
export default class ContentPage extends React.PureComponent {
    componentDidMount() {
        const { slug, contentPages } = this.props;
        
        if (contentPages.meta.status === 'invalid') {
            this.props.fetchContentPages();
        }
    }
    
    componentDidUpdate() {
        if (typeof window === 'object' && typeof window.instgrm === 'object') {
            window.instgrm.Embeds.process();
        }
    }

    // Filters out <iframe>, <blockquote> and <embed>
    filterMediaEmbeds(htmlRaw) {
        let html = document.createElement('section');
        html.innerHTML = htmlRaw;
        var contentDisabled = document.createElement('div');
        contentDisabled.className = 'content-disabled';
        contentDisabled.innerHTML = '<p>This content is disabled because of your cookie settings. You can change them at any time via the "Cookie Policy"-button in the topmenu.</p>';

        let contentDisabledClone = null;
        const blockquotes = html.getElementsByTagName('blockquote');
        for (let blockquote of blockquotes) {
            contentDisabledClone = contentDisabled.cloneNode(true);
            try {
                blockquote.parentNode.replaceChild(contentDisabledClone, blockquote);
            } catch (error) {
                console.error(error);
            }
        }

        const iframes = html.getElementsByTagName('iframe');
        for (let iframe of iframes) {
            contentDisabledClone = contentDisabled.cloneNode(true);
            try {
                iframe.parentNode.replaceChild(contentDisabledClone, iframe);
            } catch (error) {
                console.error(error);
            }
        }
        
        const embeds = html.getElementsByTagName('embed');
        for (let embed of embeds) {
            contentDisabledClone = contentDisabled.cloneNode(true);
            try {
                embed.parentNode.replaceChild(contentDisabledClone, embed);
            } catch (error) {
                console.error(error);
            }
        }
        
        return html.innerHTML;
    }
    
    render() {
        const cookies = (document !== undefined) ? parseCookies(document.cookie) : null;
        const { slug, contentPages } = this.props;
        
        if (contentPages.meta.status !== 'ready') {
            return null;
        }
        
        const page = contentPages.filter(page => page.content_key === slug).first();

        // If user has not consented to cookies, filter out social media embeds 
        // because of third party cookies.
        let html = page.html;
        if (cookies.cookieconsent_status === undefined || (cookies.cookieconsent_status !== undefined && cookies.cookieconsent_status === 'deny')) {
            html = this.filterMediaEmbeds(html);
        }
        return (
            <React.Fragment>
                <Helmet>
                    <body className="content-page explore page-explore-alt bg-image"/>
                    <title>Explore</title>
                    <meta name="description" content={page.title}/>
                </Helmet>
                
                <div className="container content-wrapper">
                    <div className="row">
                        <div className="well well-white col-xs-10 col-xs-offset-1 col-md-8 col-md-offset-2">
                            <div className="row">
                                <div className="col-sm-10 col-sm-offset-1">
                                    <Link to="/explore" className="back-btn">Back to Explore</Link>
                                    
                                    <img className="masonry-content" alt={page.image.name}
                                        style={{ width: '100%', margin: '2rem 0' }}
                                        src={convertPath(page.image.path)}
                                    />
                                    <div className="article-date">
                                        Published: {moment(page.created_at).format('ddd Do MMMM YYYY')}
                                    </div>
                                    <h1 style={{ fontSize: '2em', marginTop: '2rem' }}>{page.title}</h1>
                                    
                                    <div className="article-text" dangerouslySetInnerHTML={{ __html: html }}/>
                                    
                                    <Link to="/explore" className="back-btn">Back to Explore</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
