
import * as _ from 'lodash';
import moment from 'moment';

import PropTypes from 'prop-types';
import * as React from 'react';
import * as Router from 'react-router';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import * as ReactRedux from 'react-redux';

import { convertPath } from '../../services/model/ImageService.js';

import * as session from './session.js';
import { backendUrl } from '../../services/CpgAgent.js';
import { getURLParameter } from '../../services/model/UrlService.js';
import { authenticate } from './session';
import countries from './helper/countries.js';
import cx from 'classnames';
import SitesService from '../../services/SitesService.js';


@Router.withRouter
@ReactRedux.connect(
    state => ({
        requests: state.getIn(['requests']),
        requestId: state.getIn(['actions', 'register', 'requestId'])
    }),
    dispatch => ({
        register: (credentials) => dispatch(session.register(credentials)),
        authenticate: auth => dispatch(session.authenticate(auth))
    })
)
export default class RegisterForm extends React.PureComponent {
    static propTypes = {
        history: PropTypes.object.isRequired,
        register: PropTypes.func.isRequired,
    };
    
    state = {
        loading: false,
        email: '',
        plain_password: '',
        repeat_password: '',
        country_of_origin: '',
        birth_year: '',
        countries: countries,
        birth_years: _.times(100, (x) => moment().year() - x),
        privacy_statement: false,
        privacy_statement_validation_message: false
    };
    
    handleChange(event) {
        this.setState({ [event.target.name]: event.target.value });
    }
    
    handleKeyPress(event) {
        if (event.key === 'Enter') {
            this.handleRegister();
        }
    };
    
    togglePrivacyStatement = evt => {
        this.setState({ privacy_statement: !this.state.privacy_statement });
        if (!this.state.privacy_statement) {
            this.setState({ privacy_statement_validation_message: false });
        }
    }
    
    handleRegister = evt => {
        evt.preventDefault();
        
        if (!this.state.privacy_statement) {
            this.setState({ privacy_statement_validation_message: true });
            return;
        }
        
        this.setState({ loading: true });
        this.props.register(this.state)
            .then(() => {
                this.props.history.push('/email');
                this.setState({ loading: false });
            })
            .catch(err => {
                console.error(err);
                this.setState({ loading: false });
            });
    }
    
    componentWillMount() {
        const site = new SitesService().getSite(window.location);
        
        if (site.name === 'groningen' && typeof window !== 'undefined') {
            if (window.innerWidth < 992) {
                const registerBackgroundImage = `${convertPath('uploads/background-5638897642-80f2849aa1-o-resize.jpg')}&w=1000`;
                document.body.style.backgroundImage = 'url(' + registerBackgroundImage + ')';
            } else {
                const registerBackgroundImage = `${convertPath('uploads/background-14758092225-bc3b8220b1-o-resize.jpg')}&w=1000`;    
                document.body.style.backgroundImage = 'url(' + registerBackgroundImage + ')';
            }
        }
        
        if (site.name === 'friesland' && typeof window !== 'undefined') {
            const registerBackgroundImage = `${convertPath('uploads/background-friesland-3064560177.jpg')}&w=1000`;
            document.body.style.backgroundImage = 'url(' + registerBackgroundImage + ')';
        }
        
        if (site.name === 'drenthe' && typeof window !== 'undefined') {
            const registerBackgroundImage = `${convertPath('uploads/background-assen.jpg')}&w=1000`;
            document.body.style.backgroundImage = 'url(' + registerBackgroundImage + ')';
        }
    }
    
    handleFacebook() {
        window.location.href = `${backendUrl}/oauth/facebook?return_url=${window.location.href}`;
        return Promise.resolve();
    }
    
    componentDidMount() {
        const { history, authenticate } = this.props;
        
        if (typeof window !== 'undefined') {
            const params = getURLParameter(window.location.search);
            if (!_.isEmpty(params)) {
                // Change the string into a boolean
                params.on_board = params.on_board === '1';
                authenticate({ auth: { user: params, token: params.token }})
                    .then(() => history.push('/'));
            }
            
            document.body.classList.add('page-register');
        }
    }
    
    componentWillUnmount() {
        if (typeof window !== 'undefined') {
            document.body.classList.remove('page-register');
            document.body.style.backgroundImage = '';
        }
    }
    
    render () {
        const { email, plain_password, repeat_password, countries, birth_years, birth_year, country_of_origin } = this.state;
        const { requests, requestId } = this.props;
        const request = requests.get(requestId);
        const status = request ? request.status : 'open';
        const site = new SitesService().getSite(window.location);
        
        return (
            <div className="container vertical-align register-form-container">
                <Helmet>
                    <body className="page-register"/>
                    <title>Register</title>
                    <meta name="description" content="Register"/>
                    
                    {site.name === 'groningen' && typeof window !== 'undefined' && window.innerWidth < 992 && <style>{`
                        body {
                            background-image:
                                url("${convertPath('uploads/background-5638897642-80f2849aa1-o-resize.jpg')}&w=1000");
                        }
                    `}</style>}
                    
                    {site.name === 'groningen' && typeof window !== 'undefined' && window.innerWidth >= 992 && <style>{`
                        body {
                            background-image:
                                url("${convertPath('uploads/background-14758092225-bc3b8220b1-o-resize.jpg')}&w=1000");
                        }
                    `}</style>}
                    
                    {site.name === 'friesland' && typeof window !== 'undefined' && window.innerWidth < 992 && <style>{`
                        body {
                            background-image:
                                url("${convertPath('uploads/background-5638897642-80f2849aa1-o-resize.jpg')}&w=1000");
                        }
                    `}</style>}
                    
                    {site.name === 'friesland' && typeof window !== 'undefined' && window.innerWidth >= 992 && <style>{`
                        body {
                            background-image:
                                url("${convertPath('uploads/background-14758092225-bc3b8220b1-o-resize.jpg')}&w=1000");
                        }
                    `}</style>}
                </Helmet>
                
                <div className="row">
                    <div
                        className={`
                            well well-white
                            col-sm-10 col-sm-offset-1 col-md-8 col-md-offset-2 col-lg-6 col-lg-offset-3
                            ${this.state.loading ? 'well-loading' : ''}
                        `}
                    >
                        <div className="row">
                            <div className="col-sm-6">
                                <h1>Register</h1>
                                <p className="h4">Create an account with Here &amp; Now in {site.title}. It's free!</p>
                                <p>Use your Facebook account to login<span className="hidden-xs"> or signup with your emailaddres</span>.</p>
                                <button className="btn btn-primary btn-large btn-block" disabled={status === 'pending'}
                                    onClick={() => { this.handleFacebook(); }}
                                >
                                    <span className="icon icon-social-facebook" aria-hidden="true" aria-label="Facebook logo"></span>
                                    Sign in with Facebook
                                </button>    
                                <p className="visible-xs margin10T margin10B">...or signup with your emailaddres.</p>
                                <p className="text-muted txt-sm margin10T hidden-xs">
                                    Already have an account?  <Link to={`/login/`}>Login</Link>
                                </p>
                            </div>
                            <div className="col-sm-6">                         
                                <form>
                                    {(status === 'error') &&
                                        <div className="alert alert-danger" role="alert">
                                            <strong>Error: </strong>
                                            {request.reason}
                                        </div>
                                    }
                                    <div className="form-group">
                                        <label className="sr-only">Email</label>
                                        <input type="text" placeholder="Email" className="form-control" name="email" id="email" value={email}
                                            onKeyPress={(e) => this.handleKeyPress(e)}
                                            onChange={(e) => this.handleChange(e)}
                                            required
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label className="sr-only">Password</label>
                                        <input type="password" placeholder="Password" className="form-control" name="plain_password" id="plain_password"
                                               value={plain_password} onKeyPress={(e) => this.handleKeyPress(e)}
                                            onChange={(e) => this.handleChange(e)}
                                            required
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label className="sr-only">Repeat password</label>
                                        <input type="password" placeholder="Repeat password" className="form-control" name="repeat_password" id="repeat_password"
                                            value={repeat_password}
                                            onKeyPress={(e) => this.handleKeyPress(e)}
                                            onChange={(e) => this.handleChange(e)}
                                            required
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label className="sr-only">Countries</label>
                                        <select className={cx({"form-control": true, 'placeholder': _.isEmpty(country_of_origin)})} name="country_of_origin" id="country_of_origin"
                                            placeholder='Country of origin'
                                            onChange={(e) => this.handleChange(e)}
                                            required
                                        >
                                            <option value="">-- Select country --</option>
                                            <option value="Belgium (België)">Belgium (België)</option>
                                            <option value="China (中国)">China (中国)</option>
                                            <option value="France">France</option>
                                            <option value="Germany (Deutschland)">Germany (Deutschland)</option>
                                            <option value="Italy (Italia)">Italy (Italia)</option>
                                            <option value="Netherlands (Nederland)">Netherlands (Nederland)</option>
                                            <option value="Spain (España)">Spain (España)</option>
                                            <option value="United Kingdom">United Kingdom</option>
                                            <option value="United States">United States</option>
                                            <option value="">--------------------</option>
                                            {countries.map((country, countryKey) => (
                                                <option key={countryKey} value={country.n}>
                                                    {country.n}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="form-group">
                                        <label className="sr-only">Birth year</label>
                                        <select className={cx({'form-control': true, 'placeholder': _.isEmpty(birth_year)})} name="birth_year" id="birth_year"
                                                placeholder='Birth year'
                                                onChange={(e) => this.handleChange(e)}
                                                required>
                                            <option value="">-- Birth Year --</option>
                                            {birth_years.map((birth_year) => (
                                                <option key={birth_year} value={birth_year}>
                                                    { birth_year }
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="checkbox">
                                        <label>
                                            <input type="checkbox" name="privacy_statement" checked={this.state.privacy_statement}
                                                onChange={this.togglePrivacyStatement}
                                                required
                                            />
                                            I agree with and have read the <Link to={`/privacy-statement/`} target="_blank">privacy statement</Link>.
                                        </label>
                                        {this.state.privacy_statement_validation_message 
                                            ?
                                                <p className="text-danger"><small>Please agree with our privacy policy first.</small></p>    
                                            : ''
                                         }
                                    </div>
                                    <div className="clearfix">
                                        <button className="btn btn-black pull-right loading" disabled={status === 'pending'} onClick={this.handleRegister}>
                                            {status === 'pending' &&
                                                <span className='icon icon-settings'/>
                                            }
                                            Register
                                        </button>
                                    </div>
                                </form>
                                <p className="text-muted visible-xs margin10T">
                                    Already have an account? <Link to={`/login/`}>Login</Link>
                                </p>
                            </div>
                            <div className="col-xs-12">
                                <p className='txt-xs text-muted margin20T margin0B opacity80'>
                                    We are a non-profit organization and will only use this information to be able to create personal recommendations and make this site work properly. We will never share personal information with third parties.
                                </p>
                            </div>                
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
