
export default () => {
    // --------------------------------------------------------------------------------
    // SVG <use> polyfill and fallback
    // https://github.com/jonathantneal/svg4everybody
    // -------------------------------------------------------------------------------- 
    
    const svg4everybody = require('svg4everybody/dist/svg4everybody.js');
    svg4everybody({
        nosvg: false, // shiv <svg> and <use> elements and use image fallbacks
        polyfill: true, // polyfill <use> elements for External Content
    });
    
    
    // lazysizes
    require('lazysizes/lazysizes.js');
    require('lazysizes/plugins/unveilhooks/ls.unveilhooks.js');
    require('lazysizes/plugins/bgset/ls.bgset.js');
    
    
    // respimage
    require('respimage/respimage.js');
    
    
    // jQuery
    const $ = window.jQuery = window.$ = require('jquery');
    
    
    // Bootstrap
    require('bootstrap/dist/js/bootstrap.js');
    
    // Close (mobile) menu on item or logo click
    $(document).on('click', '.navbar-collapse.in', function(e) {
        if ($(e.target).is('a') && $(e.target).attr('class') !== 'dropdown-toggle' ) {
            $(this).collapse('hide');
        }
    });
    
    $(document).on('click', '.navbar-brand', function(e) {
        if ($('.navbar-collapse').hasClass('in')) {
            $('.navbar-collapse.in').collapse('hide');
        }
    });
};
