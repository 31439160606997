
import _ from 'lodash';
import Imm from 'immutable';
import moment from 'moment';

import Schema from '../util/models/schema.js';
import Collection from '../util/models/Collection.js';
import Entity from '../util/models/Entity.js';

import Image from './Image.js';


export default class ContentPage extends Entity {
    static key = { id: Schema.text };
    static schema = {
        id: Schema.text,
        created_at: moment,
        content_key: Schema.text,
        published: Boolean,
        show_in_menu: Boolean,
        title: Boolean,
        slug: Boolean,
        position: Schema.integer,
        text_summary: Schema.text,
        html: Schema.integer,
        image: Image,
    };
    
    constructor(...args) {
        super(ContentPage.schema, ...args);
    }
}
