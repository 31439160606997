
import $ from 'jquery';

import React from 'react';
import * as ReactRedux from 'react-redux';
import * as Router from 'react-router';
import { Helmet } from 'react-helmet';

import agent from '../../services/CpgAgent.js';
import EventsSliderElement from '../events/EventsSliderElement.js';
import Collection from '../../util/models/Collection.js';
import Event from '../../models/Event.js';
import {makeEntry} from '../../util/api/collection_util.js';
import Image from '../../models/Image.js';
import SitesService from '../../services/SitesService.js';


@Router.withRouter
export default class EventSearch extends React.PureComponent {
    state = {
        isSearching: true,
        errorMessage: null,
        query: null,
        events: null,
    };
    
    doSearch() {
        const { query } = this.props.match.params;
        this.setState({
            query,
            isSearching: true,
        });
        
        agent.get('/events/search?q=' + encodeURIComponent(query))
            .then(response => {
                this.showResults(response.body);
                $("#navbar").collapse('hide');
            })
            .catch(reason => {
                this.setState({ errorMessage: 'Something went wrong while searching.' });
            });
    }
    
    componentDidMount() {
        this.doSearch();
    }
    
    componentDidUpdate(prevProps) {
        if (this.props.match.params.query !== prevProps.match.params.query) {
            this.doSearch();
        }
    }
    
    showResults(results) {
        const events = new Collection(Event, { status: 'ready' }, results._embedded.items.map(event => {
            const images = new Collection(Image, { status: 'ready' },
                event.images.map(image => {
                    return makeEntry(Image, image);
                })
            );
            return makeEntry(Event, { ...event, images });
        }));
        
        this.setState({
            isSearching: false,
            events,
        });
    }
    
    render() {
        const site = new SitesService().getSite(window.location);

        return (
            <div>
                {this.state.isSearching
                    ?
                        <div className="container events-category-wrapper">
                            <div className="row">
                                <header className="col-md-12">
                                    <h2 className="events-category-title">
                                        Searching for <strong>{this.state.query}</strong> ...
                                    </h2>
                                </header>
                            </div>
                        </div>
                    :
                        <div className="container events-category-wrapper main-events-category">
                            <Helmet>
                                <body className="page-search-results"/>
                                <meta name="description" content={`Events and search results for query '${this.state.query}'. Here & Now in ${site.title}.`} />
                                <title>{`Events for '${this.state.query}'`}</title>
                            </Helmet>
                            
                            {this.state.errorMessage !== null &&
                                <div className="alert alert-danger" role="alert">
                                    <strong>Error:</strong>
                                    {' '}
                                    {this.state.errorMessage}
                                </div>
                            }
                            
                            <div className="row">
                                <header className="col-md-12">
                                    <h2 className="events-category-title">
                                        Search results
                                    </h2>                                    
                                    {this.state.events.entries.size === 0
                                        ?
                                            <p className="margin0B">
                                              Sorry, we could not find any events based on your search query '<strong>{this.state.query}</strong>'.
                                            </p>
                                        : 
                                          this.state.events.entries.size === 1                                          
                                        ? 
                                            <p className="margin0B">
                                                {this.state.events.entries.size} event found for '<strong>{this.state.query}</strong>':
                                            </p>   
                                        :
                                            <p className="margin0B">
                                                {this.state.events.entries.size} events found for '<strong>{this.state.query}</strong>':
                                            </p>
                                    }
                                </header>
                            </div>
                            
                            <div className="row">
                                {this.state.events && this.state.events.map((event) => (
                                    <div
                                        key={event.slug}
                                        className="col-xs-12 col-ms-6 col-sm-4 col-md-3 category-event-item"
                                        onClick={() => this.props.history.push(`/event/${event.slug}`)}
                                    >
                                        <EventsSliderElement
                                            key={event.id}
                                            event={event}
                                            onHover={() => {}} // TODO
                                            update={() => {}} // TODO
                                        />
                                    </div>
                                )).toArray()}
                            </div>
                        </div>
                }
            </div>
        );
    }
}
