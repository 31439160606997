
import * as _ from 'lodash';
import moment from 'moment';

import React from 'react';
import PropTypes from 'prop-types';
import * as ReactRedux from 'react-redux';
import * as Router from 'react-router';
import { Helmet } from 'react-helmet';

import User from '../../models/User.js';
import uuid from 'uuid';
import agent from '../../services/CpgAgent.js';
import countries from '../session/helper/countries';
import cx from 'classnames';

export const updateUser = (user) => dispatch => {
    const requestId = uuid();
    dispatch({ type: 'session.update', requestId, status: 'pending'});
    
    return agent.put(`/user`)
        .send({ ...user })
        .then(request => dispatch({
                type: 'session.update',
                requestId,
                status: 'ready',
                value: request.body,
        }))
        .catch(reason => {
            dispatch({ type: 'session.update', requestId, status: 'error', reason: reason.response.body.msg });
            throw reason;
        });
};


@ReactRedux.connect(
    state => ({}),
    dispatch => ({
        updateUser: (user) => dispatch(updateUser(user)),
    }),
)
@Router.withRouter
class ChangeEmailForm extends React.PureComponent {
    static propTypes = {
        user: PropTypes.instanceOf(User).isRequired,
    };
    
    state = {
        email: "",
        errorMessage: null,
    };
    
    componentDidMount () {
        const {email} = this.props;
        this.setState(() => {
            return {
                email
            }
        })
    }
    
    validate = ({ email }) => {
        if (!email) {
            const message = "Please fill in an email address";
            this.setState({ errorMessage: message });
            return false;
        } else {
            this.setState({ errorMessage: null });
            return true;
        }
    };
    
    handleSubmit = () => {
        const request = { email: this.state.email };
        if (!this.validate(request)) {
            return Promise.reject();
        }
        return this.props.updateUser(request)
            .then(() => { this.props.history.push('/'); })
            .catch(reason => {
                this.setState({ errorMessage: "Something went wrong while submitting the form." });
            });
    };
    
    render() {
        return (
            <form className="clearfix" onSubmit={evt => { evt.preventDefault(); this.handleSubmit(); }}>
                {this.state.errorMessage !== null &&
                    <div className="alert alert-danger" role="alert">
                        <strong>Error:</strong>
                        {' '}
                        {this.state.errorMessage}
                    </div>
                }
                
                <div className="form-group">
                    <label>Email</label>
                    <input type="text" className="form-control field-email" required
                        value={this.state.email || ""}
                        placeholder={this.props.user.email}
                        onChange={evt => { this.setState({ email: evt.target.value }); }}
                    />
                </div>
                
                <button className="btn btn-black pull-right"
                    // disabled={status === 'pending'} // TODO
                    onClick={() => { this.handleSubmit(); }}
                >
                    Update email
                </button>
            </form>
        );
    }
}

@ReactRedux.connect(
    state => ({}),
    dispatch => ({
        updateUser: (user) => dispatch(updateUser(user)),
    }),
)
@Router.withRouter
class ChangePasswordForm extends React.PureComponent {
    static propTypes = {
        user: PropTypes.instanceOf(User).isRequired,
    };
    
    state = {
        password: "",
        passwordRepeat: "",
        errorMessage: null,
    };
    
    validate = ({ password, passwordRepeat }) => {
        if (!password) {
            const message = "Please fill in a password";
            this.setState({ errorMessage: message });
            return false;
        } if (password !== passwordRepeat) {
            const message = "The passwords do not match";
            this.setState({ errorMessage: message });
            return false;
        } else {
            this.setState({ errorMessage: null });
            return true;
        }
    };
    
    handleSubmit = () => {
        const request = { password: this.state.password };
        if (!this.validate({ ...request, passwordRepeat: this.state.passwordRepeat })) {
            return Promise.reject();
        }
        console.log('updateUser', this.props);
        return this.props.updateUser(request)
            .then(() => { this.props.history.push('/'); })
            .catch(reason => {
                this.setState({ errorMessage: "Something went wrong while submitting the form." });
            });
    };
    
    render() {
        return (
            <form className="clearfix" onSubmit={evt => { evt.preventDefault(); this.handleSubmit(); }}>
                {this.state.errorMessage !== null &&
                    <div className="alert alert-danger" role="alert">
                        <strong>Error:</strong>
                        {' '}
                        {this.state.errorMessage}
                    </div>
                }
                
                <div className="form-group">
                    <label>Password</label>
                    <input type="password" className="form-control field-password" autoComplete="off" required
                        value={this.state.password}
                        onChange={evt => { this.setState({ password: evt.target.value }); }}
                    />
                </div>
                
                <div className="form-group">
                    <label>Confirm password</label>
                    <input type="password" className="form-control field-password-repeat" autoComplete="off" required
                        value={this.state.passwordRepeat}
                        onChange={evt => { this.setState({ passwordRepeat: evt.target.value }); }}
                    />
                </div>
                
                <button className="btn btn-black pull-right"
                    // disabled={status === 'pending'} // TODO
                    onClick={() => { this.handleSubmit(); }}
                >
                    Update password
                </button>
            </form>
        );
    }
}

@ReactRedux.connect(
    state => ({}),
    dispatch => ({
        updateUser: (user) => dispatch(updateUser(user)),
    }),
)
@Router.withRouter
class ChangePersonalInfoForm extends React.PureComponent {
    static propTypes = {
        user: PropTypes.instanceOf(User).isRequired,
    };
    
    constructor(props) {
        super();
        this.state = {
            country_of_origin: props.user.country_of_origin,
            countries: countries,
            birth_year: props.user.birth_year,
            firstname: props.user.firstname || '',
            lastname: props.user.lastname || '',
            birth_years: _.times(100, (x) => moment().year() - x),
            errorMessage: null,
        };
    }
    
    handleSubmit = () => {
        const personalInformation = _.omit(this.state, ['birth_years', 'errorMessage', 'countries']);
        personalInformation.birth_year = parseInt(personalInformation.birth_year);
        
        return this.props.updateUser(personalInformation)
            .then(() => { this.props.history.push('/'); })
            .catch(reason => {
                this.setState({ errorMessage: "Something went wrong while submitting the form." });
            });
    };
    
    handleSelect = (ref, e) => {
        e.preventDefault();
        this.setState({ [ref]: (Number.isInteger(e.target.value)) ? parseInt(e.target.value) : e.target.value });
    };
    
    render() {
        const { country_of_origin, countries, birth_year, birth_years, firstname, lastname } = this.state;
        return (
            <form className="clearfix" onSubmit={evt => { evt.preventDefault(); this.handleSubmit(); }}>
                {this.state.errorMessage !== null &&
                    <div className="alert alert-danger" role="alert">
                        <strong>Error:</strong>
                        {' '}
                        {this.state.errorMessage}
                    </div>
                }
                
                <div className="form-group">
                    <label>First name</label>
                    <input type="text" className="form-control" required
                           value={firstname || ''}
                           placeholder={this.props.user.firstname}
                           onChange={evt => { this.setState({ firstname: evt.target.value }); }}
                    />
                </div>
                <div className="form-group">
                    <label>Last name</label>
                    <input type="text" className="form-control" required
                       value={lastname || ''}
                       placeholder={this.props.user.lastname}
                       onChange={evt => { this.setState({ lastname: evt.target.value }); }}
                    />
                </div>
                
                <div className="form-group">
                    <label>Country of birth</label>
                    <select className={cx({"form-control": true, 'placeholder': _.isEmpty(country_of_origin)})} name="country_of_origin" id="country_of_origin"
                            placeholder='Country of origin'
                            value={country_of_origin || ''}
                            onChange={(e) => this.handleSelect('country_of_origin', e)}
                            required>
                        <option disabled hidden className='text-muted'>{ this.props.user.country_of_origin }</option>
                        <option value="">-- Select country --</option>
                        <option value="Belgium (België)">Belgium (België)</option>
                        <option value="China (中国)">China (中国)</option>
                        <option value="France">France</option>
                        <option value="Germany (Deutschland)">Germany (Deutschland)</option>
                        <option value="Italy (Italia)">Italy (Italia)</option>
                        <option value="Netherlands (Nederland)">Netherlands (Nederland)</option>
                        <option value="Spain (España)">Spain (España)</option>
                        <option value="United Kingdom">United Kingdom</option>
                        <option value="United States">United States</option>
                        <option value="">--------------------</option>
                        { countries.map((country, countryKey) => (
                            <option key={country.n} value={country.n}>
                                { country.n }
                            </option>
                        ))}
                    </select>
                </div>
                <div className="form-group">
                    <label >Birth year</label>
                    <select className={cx({"form-control": true})} name="birth_year" id="birth_year"
                        placeholder='Birth year'
                        onChange={(e) => this.handleSelect('birth_year', e)}
                        value={birth_year || ''}
                        required
                    >
                        { birth_years.map((birth_year) => (
                            <option key={birth_year} value={birth_year}>
                                { birth_year }
                            </option>
                        ))}
                    </select>
                </div>
                
                <button className="btn btn-black pull-right"
                    // disabled={status === 'pending'} // TODO
                    onClick={evt => { evt.preventDefault(); this.handleSubmit(); }}
                >
                    Update personal information
                </button>
            </form>
        );
    }
}

@Router.withRouter
@ReactRedux.connect(
    state => ({
        auth: state.getIn(['session', 'auth']),
    }),
)
export default class Preferences extends React.PureComponent {
    static propTypes = {
        history: PropTypes.object.isRequired,
    };
    
    render() {
        if (!this.props.auth) {
            return null;
        }
        
        const user = new User(this.props.auth.user);
        return (
            <div className="container vertical-align preferences-form-container">
                <Helmet>
                    <body className="page-preferences"/>
                    <meta name="description" content="User preferences"/>
                    <title>Preferences</title>
                </Helmet>
                
                <div className="row">
                    <br className="hidden-xs margin30T" />
                    <div className="well well-white col-sm-10 col-sm-offset-1 col-md-8 col-md-offset-2">
                        <div className="row">
                            <div className="col-sm-5">
                                <h1>Preferences</h1>
                                <p>Use these forms to change your password or email address.</p>
                            </div>
                            { user && user.get('facebook_id') === null &&
                                <div className="col-sm-7">
                                    <h3>Change email</h3>
                                    <ChangeEmailForm user={user}/>
                                    <h3 className="margin30T">Change password</h3>
                                    <ChangePasswordForm user={user}/>
                                    <h3 className="margin30T">Change personal information</h3>
                                    <ChangePersonalInfoForm user={user}/>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
