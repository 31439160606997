
import Imm from 'immutable';
import { matchType as match } from '@mkrause/match';
import agent from '../../services/CpgAgent.js';
import uuid from 'uuid';
import { updateRequest } from '../../util/api/request_util.js';
import * as _ from 'lodash';
import initState from '../../../app/state.js';


// Validate an existing session token
export const validateToken = token => dispatch => {
    return agent.get(`/user`)
        .set('Authorization', `Bearer ${token}`)
        .then(({ body }) => {
            // dispatch({ type: 'session.validate', user: body });
        })
        .catch(reason => {
            // Assume that the session is no longer valid
            dispatch({ type: 'session.log_out' });
            throw reason;
        });
};

export const authenticate = ({ auth }) => dispatch => {
    dispatch({ type: 'session.authenticate', auth });
    return Promise.resolve();
};
export const logIn = ({ username, password }) => dispatch => {
    const requestId = uuid();
    dispatch({ type: 'session.log_in', requestId, status: 'pending'});
    return agent.post(`/login_check`)
        .send({ '_username': username, '_password': password })
        .then(request => dispatch({
            type: 'session.log_in',
            requestId,
            status: 'ready',
            value: request.body,
        }))
        .catch(reason => {
            dispatch({ type: 'session.log_in', requestId, status: 'error', reason: reason.response.body.msg });
            throw reason;
        });
};

export const register = cred => dispatch => {
    const requestId = uuid();
    const credentials = _.omit(cred, ['countries', 'birth_years']);
    dispatch({ type: 'session.register', requestId, status: 'pending'});
    if (_.values(credentials).findIndex(credential => credential === '') !== -1) {
        dispatch({ type: 'session.register', requestId, status: 'error', reason: 'There are some empty values' });
        return Promise.reject();
    }
    if (credentials.plain_password !== credentials.repeat_password) {
        dispatch({
            type: 'session.register',
            requestId,
            status: 'error',
            reason: 'The passwords are not identical to each other',
        });
        return Promise.reject();
    }
    return agent.post(`/user/register`)
        .send(credentials)
        .then(request => {
            return dispatch({ type: 'session.register', requestId, status: 'ready', value: request.body });
        })
        .catch(reason => {
            dispatch({ type: 'session.register', requestId, status: 'error', reason: reason.response.body.msg });
            throw reason;
        });
};

export const initializeAuth = (value, status) => {
    if (status === 'ready') {
        if (value) {
            const auth = { user: value.user, token: value.token};
            localStorage.setItem('auth', JSON.stringify(auth));
            return Imm.Record(auth)();
        } else {
            localStorage.removeItem('auth');
            return null;
        }
    }
    return null;
};

export const saveAuth = (token, user) => {
    localStorage.setItem('auth', JSON.stringify({ user, token }));
};

export default (state, action) => match(action, {
    [match.default]: state,
    
    'session.register': ({ status, requestId, value, reason }) => {
        const loginState = state.setIn(['actions', 'register'], Imm.Map({ requestId }));
        return updateRequest({ state: loginState, requestId, status, reason });
    },
    
    'session.authenticate': ({ auth }) => {
        return state.setIn(['session', 'auth'], initializeAuth(auth, 'ready'));
    },
    
    'session.log_in': ({ status, requestId, value, reason }) => {
        const loginState = state.setIn(['actions', 'login'], Imm.Map({ requestId }));
        const updatedState = status === 'ready'
            ? loginState.setIn(['session', 'auth'], initializeAuth(value, status))
            : loginState;
        return updateRequest({ state: updatedState, requestId, status, reason });
    },
    
    'session.validate': ({ status, requestId, user, reason }) => {
        return state.setIn(['session', 'auth', 'user'], user);
        // return state;
    },
    
    'session.update': ({ status, requestId, value, reason }) => {
        const updatedState = status === 'ready'
            ? state.setIn(['session', 'auth', 'user'], value)
            : state;
        saveAuth(state.getIn(['session', 'auth', 'token']), value);
        return updateRequest({ state: updatedState, requestId, status, reason });
    },
    // Destroy the current session
    'session.log_out': () => {
        // Clear localStorage
        initializeAuth(undefined, 'ready');
        
        // Completely wipe the current state
        return initState;
    },
    
    'session.update_user': ({ user }) => {
        return state.setIn(['session', 'auth', 'user'], user);
    }
});
