
import config from './config.js';
import route from './routes.js';

import localizationApi from '../../src/util/localization.js';
import localizations from '../localizations/all.js';

// Service modules
import CpgApi from '../../src/services/CpgApi.js';
import ResponsiveService from '../../src/services/ResponsiveService.js';
import SitesService from '../../src/services/SitesService.js';


/*
const getAsset = urlPath => {
    if (process.env.APP_ENV === 'webpack') {
        // Note: webpack allows us to load assets (e.g. images) directly through `require()`. But
        // this does not work in a Node environment.
        return require(urlPath);
    } else {
        return urlPath;
    }
};
*/

export default {
    'app.mode': process.env.NODE_ENV === 'production' ? 'production' : 'development',
    'app.config': config,
    'app.lang': localizationApi(localizations, 'en-en'),
    //'app.asset': getAsset,
    'app.route': route,
    'cpg.api': CpgApi,
    'cpg.responsive': new ResponsiveService(),
    'cpg.sites': new SitesService(),
};
