
export default {
    app: {
        name: 'Here & Now',
    },
    layout: {
        home: 'Home',
        categories: 'Categories',
        calendar: 'Calendar',
        about: 'About',
    },
    categories: {
        favorites: 'Favorites',
    },
};
