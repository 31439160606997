import moment from 'moment';

import React from 'react';
import * as ReactRedux from 'react-redux';
import * as Router from 'react-router';
import { Helmet } from 'react-helmet';

import agent from '../../services/CpgAgent.js';

import SweetAlert from 'react-bootstrap-sweetalert';
import DateTime from '../../components/DateTimePicker.js';
import SitesService from '../../services/SitesService.js';

@Router.withRouter
@ReactRedux.connect(
    null,
    dispatch => ({
        dispatch,
    }),
    (stateProps, dispatchProps, otherProps) => ({
        ...stateProps,
        ...dispatchProps,
        ...otherProps,
        notify: ({ type, title, message, submitter }) => {
            const close = (type, submitter) => {
                dispatchProps.dispatch({ type: 'alert', alert: undefined });
                if(submitter && type !== "error"){
                    submitter.setState({
                        submitting: false,
                        email: "",
                        owner: true,
                        name: "",
                        summary: "",
                        description: "",
                        location: "",
                        start: null,
                        end: null,
                        website: "",
                        ticketlink: "",
                        facebooklink: "",
                        price: "",
                        errorMessage: null,
                        apiSubmitted: false,
                    });
                    document.getElementById("myEventForm").reset();
                    document.querySelectorAll("div.cpg-datepicker input").forEach(el => el.value="");
                }
            };
    
            dispatchProps.dispatch({
                type: 'alert',
                alert: dispatch => {
                    const closeHandle = setTimeout(() => close(type,submitter), 4000, type, submitter);
                    return (
                        <SweetAlert
                            title={title}
                            type={type}
                            onConfirm={() => {
                                dispatchProps.dispatch({ type: 'alert', alert: undefined });
                                clearTimeout(closeHandle);
                                close(type,submitter);
                            }}
                        >
                            {message}
                        </SweetAlert>
                    );
                }
            })
        }
    })
)

export default class EventSubmitter extends React.PureComponent {
    state = {
        submitting: false,
        email: "",
        owner: true,
        name: "",
        summary: "",
        description: "",
        location: "",
        start: null,
        end: null,
        website: "",
        ticketlink: "",
        facebooklink: "",
        price: "",
        errorMessage: null,
        apiSubmitted: false,
    };
    
    validate = req => {
        const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        
        if (req.owner && !emailRegex.test(req.organiser_email)) {
            const message = "Please fill in a valid email address";
            this.setState({ errorMessage: message });
            return false;
        }
        if (!req.name || req.name.length <= 3) {
            this.setState({ errorMessage: "Please fill in the event name" });
            return false;
        }
        if (!req.location || req.location.length <= 3 ){
            this.setState({ errorMessage: "Please fill in the event location" });
            return false;
        }
        this.setState({ errorMessage: null });
        return true;
    };
    
    handleSubmit = () => {
        const request = {
            organiser_email: this.state.email,
            owner: this.state.owner,
            name:  this.state.name,
            location: this.state.location,
            summary: this.state.summary,
            description: this.state.description,
            start_date: this.state.start ? this.state.start.format() : "",
            end_date: this.state.end ? this.state.end.format() : "",
            website: this.state.website,
            ticket_link: this.state.ticketlink,
            facebook_link: this.state.facebooklink,
            price: this.state.price,
        };
        
        if (!this.validate(request)) {
            return Promise.reject();
        }
        
        delete request.owner;
        this.setState({ submitting: true });
        return agent.post('/user/submit-event')
            .send(request)
            .then(() => {
                this.setState({ submitting: false, apiSubmitted: true });
                this.props.notify({
                    type: "success", 
                    title: "Great!",
                    message: "Your event has been submitted.",
                    submitter: this
                    });
            })
            .catch(reason => {
                // this.setState({ submitting: false, errorMessage: "Something went wrong while submitting the form." });
                this.setState({ submitting: false });
                this.props.notify({
                    type: "error", 
                    title: "Oops",
                    message: "Something went wrong...",
                    submitter: this
                    });
            });
    };

    changeRadio(event) {
        if (event) {
            this.setState({ owner: event.target.value });
        }
    }

    render() {
        const site = new SitesService().getSite(window.location);

        return (
            <div className="container vertical-align event-submit-form-container">
                <Helmet>
                    <body className="page-submit-event"/>
                    <meta name="description" content={`Use this form to suggest an event to Here & Now in ${site.title}!`}/>
                    <title>Submit an event</title>
                </Helmet>
                
                <div className="row">
                    <br className="hidden-xs margin30T" />
                    <div
                        className={`well well-white col-sm-10 col-sm-offset-1 col-md-8 col-md-offset-2
                            ${this.state.submitting ? 'well-loading' : ''}
                        `}
                    >
                        <div className="row">
                            <div className="col-sm-5">
                                <h1>Suggest an event</h1>
                                <p>Fill out this form to suggest and submit an event to Here & Now in {site.title}.</p>
                            </div>
                            <div className="col-sm-7">
                                <form id="myEventForm" className="clearfix"  autoComplete="off" noValidate
                                    onSubmit={evt => { evt.preventDefault(); this.handleSubmit(); }}
                                >
                                    {this.state.apiSubmitted &&
                                        <div className="alert alert-success" role="alert">
                                            Thank you for your suggestion! Your event has been submitted to our editors.
                                        </div>
                                    }
                                    
                                    {this.state.errorMessage !== null &&
                                        <div className="alert alert-danger" role="alert">
                                            <strong>Error:</strong>
                                            {' '}
                                            {this.state.errorMessage}
                                        </div>
                                    }
                                    
                                    <div className="radio">
                                        <label>
                                            <input type="radio" name="organiser" checked={this.state.owner}
                                                onChange={() => { this.setState({ owner: true }); }}
                                            />
                                            I am the organiser of the event
                                        </label>
                                    </div>
                                    
                                    <div className="radio">
                                        <label>
                                            <input type="radio" name="organiser" checked={!this.state.owner}
                                                onChange={() => { this.setState({ owner: false }); }}
                                            />
                                            I have found a great event!
                                        </label>
                                    </div>
                                    
                                    {this.state.owner &&
                                        <div className="form-group">
                                            <label htmlFor="event-submit-email">Contact person email</label>
                                            <input id="event-submit-email" type="text" className="form-control field-email" required
                                                value={this.state.email || ""}
                                                onChange={evt => { this.setState({ email: evt.target.value }); }}
                                            />
                                            <span className="help-block">Will not be published. Used to contact event organiser if needed.</span>
                                        </div>
                                    }
                                    
                                    <div className="form-group">
                                        <label>Event name</label>
                                        <input type="text" className="form-control" required pattern=".{4,}"
                                            title="The event name should be at least 4 characters long."
                                            onChange={evt => { this.setState({ name: evt.target.value }); }}
                                        />
                                        <span className="help-block">Name of the event. Max 72 characters.</span>
                                    </div>
                                    
                                    <div className="form-group">
                                        <label>Summary</label>
                                        <input type="text" className="form-control"
                                            onChange={evt => { this.setState({ summary: evt.target.value }); }}
                                        />
                                        <span className="help-block">Short summary of the event. Max 110 characters.</span>
                                    </div>
                                    
                                    <div className="form-group">
                                        <label>Description</label>
                                        <textarea rows="6" className="form-control" required onChange={evt => { this.setState({ description: evt.target.value }); }}></textarea>
                                        <span className="help-block">Long description of the event.</span>
                                    </div>
                                    
                                    <div className="form-group">
                                        <label>Location</label>
                                        <input type="text" className="form-control" required required pattern=".{4,}"
                                            title="The location name should be at least 4 characters long."
                                            onChange={evt => { this.setState({ location: evt.target.value }); }}
                                        />
                                        <span className="help-block">Name, address and city of the event location</span>
                                    </div>
                                    
                                    <div className="form-group has-feedback">
                                        <label>Start date &amp; time</label>                                        
                                        <DateTime className="cpg-datepicker" dateFormat="YYYY-MM-DD" timeFormat="HH:mm" closeOnSelect={true}
                                            onChange={moment => { this.setState({ start: moment }); }}
                                        />                                        
                                        <span className="icon icon-calendar form-control-feedback" aria-hidden="true" aria-label="Calendar icon"/>                                        
                                        <span className="help-block">Please select a date and time.</span>
                                    </div>
                                    
                                    <div className="form-group has-feedback">
                                        <label>End date &amp; time</label>
                                        <DateTime className="cpg-datepicker" dateFormat="YYYY-MM-DD" timeFormat="HH:mm" closeOnSelect={true}
                                            onChange={moment => { this.setState({ end: moment }); }}
                                        />
                                        <span className="icon icon-calendar form-control-feedback" aria-hidden="true" aria-label="Calendar icon"/>   
                                        <span className="help-block">Please select a date and time.</span>
                                    </div>
                                    
                                    <div className="form-group">
                                        <label>Price</label>
                                        <input type="text" className="form-control" required
                                            onChange={evt => { this.setState({ price: evt.target.value }); }}
                                        />
                                        <span className="help-block">Pricing information. E.g. "<em>Free</em>" or "<em>&euro; 16.50 (+ &euro; 2.50 service fee)</em>"</span>
                                    </div>
                                    
                                    <div className="form-group">
                                        <label>Website</label>
                                        <input type="text" className="form-control" required
                                            onChange={evt => { this.setState({ website: evt.target.value }); }}
                                        />
                                        <span className="help-block">Link for more information or event website. Please include http(s)://</span>
                                    </div>
                                    
                                    <div className="form-group">
                                        <label>Ticket link</label>
                                        <input type="text" className="form-control" required
                                            onChange={evt => { this.setState({ ticketlink: evt.target.value }); }}
                                        />
                                        <span className="help-block">Link where visitors can buy tickets. Please include http(s)://</span>
                                    </div>
                                    
                                    <div className="form-group">
                                        <label>Facebook link</label>
                                        <input type="text" className="form-control" required
                                            onChange={evt => { this.setState({ facebooklink: evt.target.value }); }}
                                        />
                                        <span className="help-block">Facebook event or event page link. Please include http(s)://</span>
                                    </div>
                                    
                                    <button type="submit" className="btn btn-black pull-right margin20T"
                                        disabled={this.state.submitting}
                                    >
                                        Submit event
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
