
import React from 'react';
import PropTypes from 'prop-types';
import * as ReactRedux from 'react-redux';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import api from '../../services/CpgApi.js';
import * as Router from 'react-router';
import EventsSliderElement from '../events/EventsSliderElement.js';
import * as AuthService from '../../services/model/AuthService';


@Router.withRouter
@ReactRedux.connect(
    state => ({
        events: state.getIn(['app', 'events']),
        eventGroups: state.getIn(['app', 'eventgroups']),
    }),
)
export default class CategoryOverview extends React.Component {
    retrieveData = () => {
        this.props.dispatch(api.eventGroups.list());
        this.props.dispatch(api.events.list({ categoryId: this.props.match.params.child, limit: 9999 })).catch(({reason}) => {
            (reason.status === 401) ? AuthService.notAuthenticated(this.props) : undefined;
        });
    };
    
    componentDidMount() {
        this.retrieveData();
    }
    
    componentDidUpdate(prevProps) {
        if (this.props.match.params.child !== prevProps.match.params.child) {
            this.retrieveData();
        }
    }
    
    render() {
        const { events, match, history, eventGroups } = this.props;
        const { child, parent } = match.params;
        
        const childCategory = eventGroups
            ?
                eventGroups
                    .flatMap((eventGroup) => eventGroup.get('children'))
                    .filter((eventGroup) => eventGroup.slug === child)
                    .first()
            : undefined;
        
        const parentCategory = eventGroups ? eventGroups.get(parent) : undefined;
        
        const eventsByCategory = events.filter((event) => event.get('categoryId') === child);
        
        return (
            <div className='container events-category-wrapper'>
                { (eventGroups.hasStatus('pending') || events.hasStatus('pending'))
                    ?
                        <div className="row">
                            <Helmet>
                                <body className="page-category-overview"/>
                                <meta name="description" content=""/>
                            </Helmet>
                            
                            <header className="col-md-12">
                                <h2 className="events-category-title">
                                    Loading...
                                </h2>
                                
                                {/*{eventsByCategory.size === 0 &&*/}
                                    {/*<h2 className="events-category-title">*/}
                                        {/*<span className="current-category">There are no events found</span>*/}
                                    {/*</h2>*/}
                                {/*}*/}
                            </header>
                        </div>
                    :
                    <div>
                        <div className='row'>
                            <header className='col-md-12'>
                                {!(parentCategory && childCategory)
                                    ?
                                        <div>
                                            <h2 className="events-category-title">
                                                <span className="current-category">Category '{parent}/{child}' not found</span>
                                            </h2>
                                        </div>
                                    :
                                        <h2 className="events-category-title">
                                            <Link to={`/category/${parent}`} className="parent-category">
                                                {parentCategory.name}
                                            </Link>
                                            &nbsp;/&nbsp;
                                            <span className="current-category">{childCategory.name}</span>
                                        </h2>
                                }
                            </header>
                        </div>
                        {(parentCategory && childCategory) &&
                            <div className="row">
                                <Helmet>
                                    <body className="page-category-overview"/>
                                    <meta name="description" content={childCategory.name}/>
                                    <title>{childCategory.name}</title>
                                </Helmet>
                                
                                {eventsByCategory.size === 0 &&
                                    <h2 className="events-category-title">
                                        <span className="current-category">There are no events found</span>
                                    </h2>
                                }
                                {eventsByCategory.map((event, eventId) => (
                                    <div
                                        key={eventId}
                                        className="col-xs-12 col-ms-6 col-sm-4 col-md-3 category-event-item"
                                         onClick={() => history.push(`/event/${event.get('slug')}`)}
                                    >
                                        <EventsSliderElement
                                            key={eventId}
                                            event={event}
                                            onHover={() => {}} // TODO
                                            update={() => {}} // TODO
                                        />
                                    </div>
                                )).toArray()}
                            </div>
                        }
                    </div>
                }
            </div>
        );
    }
}
