
import agent from '../CpgAgent.js';
import Collection from '../../util/models/Collection.js';
import Event from '../../models/Event.js';
import { makeEntry } from '../../util/api/collection_util.js';
import Image from '../../models/Image.js';


export const getEventsFromCategory = (uri, spec, categoryId, options) => {
    const endpoint = `/categories/${categoryId}${spec.uri}`;
    return agent.get(endpoint, { limit: options.limit })
        .then((response) => {
            const body = new Collection(Event, { status: 'ready'}, response.body._embedded.items.map(event => {
                const entries = event.images.map(item => {
                    return makeEntry(Image, item);
                });
                
                const images = new Collection(Image, { status: 'ready' }, entries);
                return makeEntry(Event, {...event, images: images, categoryId });
            }));
            return {body};
        });
};

export const addFavoriteEvent = (uri, spec, index, event) => {
    const endpoint = `/favorites${spec.uri}/${index}`;
    return agent.post(endpoint)
        .then(response => {
            const event = response.body;
            return { ...response, body: { ...event, id: event.slug, favorite: true} };
        });
};
