
import _ from 'lodash';
import Imm from 'immutable';
import { immutableReducer } from 'react-redux-sweetalert';
import Collection from '../src/util/models/Collection.js';

// Entity models
import Event from '../src/models/Event.js';
import Location from '../src/models/Location.js';
import Category from '../src/models/Category.js';
import EventGroup from '../src/models/EventGroup.js';
import Menu from '../src/models/Menu.js';
import ContentPage from '../src/models/ContentPage.js';


// Turn an object into a record (where the record type is just itself)
const recordFromObject = obj => Imm.Record(obj)();

// Custom reviver: turn objects into records instead of maps
const reviver = (key, value) => {
    const isIndexed = Imm.Iterable.isIndexed(value);
    return isIndexed ? value.toList() : recordFromObject(value.toObject());
};

const stateFromJS = json => Imm.fromJS(json, reviver);

const initialState = {
    requests: Imm.Map(),
    
    // User session
    session: {
        locale: 'en-en',
        
        // Auth: either `null` (not authenticated), or `{ token : string }`
        auth: null,
    },
    
    // Application instance
    app: {
        site: {
            id: 0,
        },
        
        authUser: {
            eventFavorites: new Collection(Event),
        },
        
        locations: new Collection(Location),
        categories: new Collection(Category),
        events: new Collection(Event),
        eventgroups: new Collection(EventGroup),
        menu: new Collection(Menu),
        contentPages: new Collection(ContentPage),
    },
    
    // Actions instances
    actions: {
        login: undefined,
        register: undefined,
    },
    
    // Component-specific
    components: {
        onboarding: {
            phase: 0,
        },
        alert: null,
    },
};

const initState = (customProps = {}) => stateFromJS(_.merge(initialState, customProps));
export default initState;
