
import match from '@mkrause/match';
import { indexOf } from 'lodash';


export const getLocation = () => {
    if (typeof window === 'object') {
        return window.location; // Client-side
    } else if (typeof global === 'object') {
        return global.ssr.location; // Server-side
    } else {
        throw new Error(`Cannot find location`);
    }
};

export const redirectTo = uri => {
    if (typeof window === 'object') {
        window.location.href = uri;
    } else if (typeof global === 'object') {
        // Ignore
    } else {
        // Ignore
    }
};

/**
 * TODO: replace static object below with a call to /api-v1/sites/
 */
export default class SitesService {
    // TODO: pull site specific data from either database or .env
    static sites = {
        portal: { 
            id: 0, 
            name: 'portal', 
            title: 'Portal',
            facebook: null,
            google_analytics_id: null,
        },
        groningen: { 
            id: 1, 
            name: 'groningen', 
            title: 'Groningen',
            facebook: 'https://www.facebook.com/hereandnowingroningen',
            instagram: 'https://www.instagram.com/hereandnowingroningen/',
            google_analytics_id: 'UA-106766380-1',
        },
        friesland: { 
            id: 2, 
            name: 'friesland', 
            title: 'Leeuwarden–Friesland',
            facebook: 'https://www.facebook.com/hereandnowinleeuwardenfriesland',
            google_analytics_id: 'UA-133447113-1',
        },
        drenthe: { 
            id: 3, 
            name: 'drenthe', 
            title: 'Drenthe',
            facebook: 'https://www.facebook.com/hereandnowindrenthe',
            google_analytics_id: 'UA-106766380-2',
        },
    };
    
    switch(site) {
        const location = getLocation();
        
        let uri;
        if (process.env.SUPPORT_SUBDOMAINS === 'true') {
            const host = location.host.replace(/(^|[^.]+\.)hereandnow/, `${site.name}.hereandnow`);
            
            uri = `${location.protocol}//${host}`;
        } else {
            uri = `/site-${site.name}`;
        }
        
        redirectTo(uri);
    }
    
    getBaseUrl() {
        return new URL(getLocation()).origin;
    }
    
    // Get the geocoordinates for the "center" of the site, as well as a standard zoom level
    getViewport(site) {
        return match(site, {
            groningen: { center: [53.21, 6.55], zoom: 13 },
            friesland: { center: [53.20, 5.80], zoom: 13 },
            drenthe: { center: [52.98, 6.47], zoom: 11 },
            [match.default]: () => { throw new Error(`Unrecognized site ${site}`); },
        });
    }
    
    getSite(_location) {
        const location = _location || getLocation();
        
        if (process.env.SUPPORT_SUBDOMAINS === 'true') {
            const host = location.host;
            
            let siteName;
            const expectedSites = ['groningen', 'friesland', 'drenthe'];
            const matches = host.match(/([^.]+)\.hereandnow/);
            if (matches && indexOf(expectedSites, matches[1]) !== -1) {
                siteName = matches[1];
            } else {
                siteName = 'portal';
            }
            
            if (!SitesService.sites.hasOwnProperty(siteName)) {
                throw new Error(`Unknown site '${siteName}'`);
            }
            
            const site = SitesService.sites[siteName];
            return { ...site, basename: undefined };
        } else {
            const matches = location.pathname.match(/^[/]site-([^/]+)([/]|$)/);
            if (matches) {
                const siteName = matches[1];
                
                if (!SitesService.sites.hasOwnProperty(siteName)) {
                    throw new Error(`Unknown site '${siteName}'`);
                }
                
                const site = SitesService.sites[siteName];
                return { ...site, basename: `/site-${siteName}` };
            } else {
                return SitesService.sites['portal'];
            }
        }
    }
}
