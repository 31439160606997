
import PropTypes from 'prop-types';
import React from 'react';


export default class Text extends React.PureComponent {
    static propTypes = {
        children: PropTypes.string.isRequired,
        newlines: PropTypes.oneOf(['display', 'merge']),
        maxLength: PropTypes.number,
        moreIndicator: PropTypes.string,
        moreAction: PropTypes.element,
    };
    
    static defaultProps = {
        newlines: 'display',
        maxLength: null,
        moreIndicator: '…',
        moreAction: null,
    };
    
    render() {
        const { children, newlines, maxLength, moreIndicator, moreAction } = this.props;
        
        let text = children;
        
        if(!text) return (<span/>);
        
        if (newlines === 'display') {
            text = text.split('\n').filter(str => str.trim() !== '')
                .map((str, index) =>
                    <p key={index}>{str}</p>
                );
        }
        
        let overflow = false;
        if (maxLength !== null) {
            if (text.length > maxLength) {
                overflow = true;
                text = text.substring(0, maxLength) + moreIndicator;
            }
        }
        
        return (
            <span>
                {text}
                {' '}
                {overflow && moreAction}
            </span>
        );
    }
}
